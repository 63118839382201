import React from "react";
import { Link } from "react-router-dom";
import styled from "styles/theme";

const TagComponent: React.FC<ITagProps> = ({ to, children, ...props }) => {
  return (
    <Tag to={to} {...props}>
      {props.mention ? "@" : "#"}
      {children}
    </Tag>
  );
};

TagComponent.defaultProps = {
  mention: undefined
};

export default TagComponent;

const Tag = styled(Link)<ITagStyle>`
  ${p => p.theme.TEXTS.TXT_TAG};
  color: ${p => (p.mention ? p.theme.COLORS.TEXT_BRAND : p.theme.COLORS.TEXT_POINT)};

  :link {
    color: inherit;
  }
`;
