import React, { useEffect, useRef } from "react";
import { css } from "@emotion/core";
import styled from "styles/theme";

const TabComponent: React.FC<ITabProps> = ({ scroll, justify, center, children }) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const active = (event: MouseEvent) => {
    const scroll = scrollRef.current;
    if (event && event.target) {
      event.target.addEventListener("click", e => {
        const target = e.target as HTMLElement;
        if (target.nodeName === "A" || target.nodeName === "BUTTON" || target.nodeName === "SPAN") {
          const beforeActive = scroll && scroll.querySelector(".active");
          beforeActive && beforeActive.classList.remove("active");

          if (target.nodeName === "SPAN") {
            target.parentElement && target.parentElement.classList.add("active");
          } else {
            target.classList.add("active");
          }
        }
      });
    }
  };

  useEffect(() => {
    const scroll = scrollRef.current;

    if (scroll) {
      scroll.addEventListener("click", active, true);
    }
  }, []);

  let scrollStart = 0;

  const move = (event: MouseEvent) => {
    event.preventDefault();
    const scroll = scrollRef.current;
    if (scroll) {
      scroll.scrollLeft += (scrollStart - event.clientX) / 25;
    }
  };

  const up = () => {
    window.removeEventListener("mouseup", up, true);
    window.removeEventListener("mousemove", move, true);
  };

  const handleDown = (event: React.MouseEvent) => {
    scrollStart = event.clientX;
    window.addEventListener("mouseup", up, true);
    window.addEventListener("mousemove", move, true);
  };

  return (
    <Tab ref={scrollRef} scroll={scroll} justify={justify} center={center} onMouseDown={(event: React.MouseEvent) => handleDown(event)}>
      <Scroll>{children}</Scroll>
    </Tab>
  );
};

TabComponent.defaultProps = {
  scroll: false,
  justify: false
};

export default TabComponent;

const SCROLL = css`
  display: block;
  overflow-x: auto;
`;

const Tab = styled.div<ITabProps>`
  position: relative;
  width: 100%;
  font-size: 0;
  overflow: hidden;

  a,
  button {
    display: flex;
    flex: ${p => (p.justify ? "1" : "none")};
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0;
    padding: 12px 10px 10px;
    min-height: 50px;
    color: ${p => p.theme.COLORS.TEXT_SECONDARY};
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 2px solid ${p => p.theme.COLORS.BORDER_REGULAR};
    }

    &.active {
      color: ${p => p.theme.COLORS.TEXT_BRAND};

      &::after {
        border-bottom-color: ${p => p.theme.COLORS.TEXT_BRAND};
      }
    }
  }

  & > div {
    ${p => (p.center ? "justify-content: center;" : null)}
  }

  ${p => p.scroll && SCROLL};
`;

const Scroll = styled.div`
  display: flex;
`;
