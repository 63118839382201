import React, { useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styles/theme";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import Photo from "components/User/Photo";
import { Modal } from "styles";
import { IconEmptyTrash, IconEye, IconLike, IconLock, IconMore, IconNew, IconPick } from "assets/icon/svg";
import { appNative, dateDisplay } from "utils/index";
import { useMutation } from "@apollo/react-hooks";
import { GET_CATEGORY_POST, LIST_COMMENTS, REMOVE_COMMENT, REMOVE_POST, TOGGLE_LIKE_COMMENT } from "api/Board";
import { getCookieState } from "apollo/LocalState";
import { MenuButtons } from "../../Common/MenuButtons";
import { ReportModal } from "../../Common/ReportModal";
import { decodeEntity } from "../../../utils/string";

const AuthorComponent: React.FC<IAuthorPost> = ({
  id,
  picked,
  post,
  feed,
  secreted,
  author,
  createdAt,
  hitCount,
  likeCount,
  me,
  view,
  isPost,
  isProfile,
  handleMoveToProfile,
  commentSort,
  children,
  scrollSave,
  moveLink,
  hitCallback,
  isComment,
  setWrite,
  match,
  location,
  history,
  reported,
  blockedBy
}) => {
  const { t } = useTranslation("Write");
  const { cpId, groupName } = getCookieState;

  const [more, setMore] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [cancelReportModal, setCancelReportModal] = useState<boolean>(false);
  const [blockModal, setBlockModal] = useState<boolean>(false);
  const [cancelBlockModal, setCancelBlockModal] = useState<boolean>(false);

  const moreRef = useRef<HTMLDivElement>(null);
  const moreView = me?.posted ? true : me?.commented ? true : false;

  const nowDate = new Date();
  const compareDate = new Date(createdAt);
  const hourGap = nowDate.getTime() - compareDate.getTime();

  createdAt = createdAt && dateDisplay(createdAt);
  const REMOVE_REFETCH_QUERY = view ? [{ query: LIST_COMMENTS, variables: { postId: post?.id, pageInfo: { limit: 20, nextToken: null }, options: { sortBy: commentSort } } }] : [];

  const [toggleLikePostMutation] = useMutation(TOGGLE_LIKE_COMMENT, {
    refetchQueries: [{ query: LIST_COMMENTS, variables: { postId: post?.id } }]
  });

  const [RemoveMutation] = useMutation(view ? REMOVE_COMMENT : REMOVE_POST, {
    refetchQueries: REMOVE_REFETCH_QUERY,
    update: (store, { data }) => {
      if (view) {
        const { categoryId, postId } = match.params;

        const { search } = location;
        const queryPath = queryString.parse(search);
        const sortBy = queryPath.sortBy as SortType;

        try {
          const postListData: any = store.readQuery({
            query: GET_CATEGORY_POST,
            variables: { id: categoryId, limit: 20, nextToken: null, options: { sortBy } }
          });

          if (postListData) {
            const currentData = postListData.getCategory.posts.items.filter((item: IPost) => item.id === postId)[0];

            currentData.comments.items.forEach((item: IComment, index: number) => {
              if (item.id === data.removeComment.id) {
                currentData.comments.items.splice(index, 1);
              }
            });

            currentData.comments.totalCount = currentData.comments.totalCount - 1;

            const sumPostCommentData: IPost[] = postListData.getCategory.posts.items.reduce((acc: IPost[], cur: IPost) => {
              if (cur.id === postId) {
                cur = currentData;
              }
              acc.push(cur);

              return acc;
            }, []);

            store.writeQuery({
              query: GET_CATEGORY_POST,
              variables: { id: categoryId, limit: 20, nextToken: null, options: { sortBy } },
              data: {
                getCategory: {
                  ...postListData.getCategory,
                  posts: {
                    ...postListData.getCategory.posts,
                    items: sumPostCommentData
                  }
                }
              }
            });
          }
        } catch (err) {
          // console.log(err, "err");
        }
      }
    },
    onCompleted: data => {
      if (!view) {
        const currentElm = document.getElementById(`d${id}`);

        currentElm?.remove();

        const countElm = document.getElementById("post-count");

        if (countElm) {
          Number(countElm.textContent) === 1 && window.location.reload();
          countElm.textContent = (Number(countElm.textContent) - 1).toString();
        }
      }

      setRemoveModal(!removeModal);

      const { userAgent } = window.navigator;

      if (view && data && userAgent.includes("Touchclass")) {
        const appData = {
          type: "comment",
          method: "delete",
          id: data.removeComment.id
        };

        appNative.closeData(appData);
      }
    }
  });

  const eventMore = (event: MouseEvent) => {
    event.stopPropagation();

    const dom = moreRef.current;
    if (dom) {
      const rect = dom.getBoundingClientRect();
      if (!(rect.top - 84 < event.pageY && rect.bottom > event.pageY && rect.left < event.pageX && rect.right > event.pageX)) {
        setMore(false);
        window.removeEventListener("click", eventMore);
      }
    }
  };

  const handleMoveLink = () => {
    scrollSave && scrollSave("board");

    if (!me.posted) {
      hitCallback && hitCallback();
    }
  };

  const handleLike = () => {
    view && toggleLikePostMutation({ variables: { id } });
  };

  const handleMore = () => {
    window.removeEventListener("click", eventMore);
    setMore(!more);
  };

  const handleWrite = () => {
    setWrite(
      !view
        ? {
            postId: id,
            writeMode: true
          }
        : id
    );

    setMore(false);
    window.removeEventListener("click", eventMore);
  };
  const handleRemove = () => {
    RemoveMutation({
      variables: {
        id
      }
    });
  };

  const moveToProfile = () => {
    if (!isProfile && !blockedBy) {
      if (match.path === `/${cpId ? `${cpId}/` : ""}board/profile/:userId`) {
        history.replace(`/${cpId ? `${cpId}/` : ""}board/profile/${author.id}?type=writtenPosts`);
      } else {
        history.push(`/${cpId ? `${cpId}/` : ""}board/profile/${author.id}?type=writtenPosts`);
      }
      if (handleMoveToProfile) {
        handleMoveToProfile();
      }
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    more ? window.addEventListener("click", eventMore) : window.removeEventListener("click", eventMore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [more]);

  const eventDisable = (reported ?? false) || !!blockedBy;

  return (
    <>
      <Author isComment={isComment} blocked={!!blockedBy}>
        {!isProfile ? <Photo onClick={moveToProfile} size={feed ? "lm" : "sm"} src={author?.picture} /> : ""}
        <Info className={isProfile ? "row" : ""}>
          {picked && <IconPick />}
          <NameWrap isProfile={isProfile} isPost={isPost}>
            <Name className={"opacity"} onClick={moveToProfile} isProfile={isProfile} isPost={isPost} blocked={!!blockedBy}>
              {decodeEntity(author?.name)}
            </Name>
            {secreted && (
              <span className={"icon-middle"}>
                <IconLock lock size={"s"} />
              </span>
            )}
          </NameWrap>
          {!isProfile && author?.representativeGroupName && groupName && <GroupName>{author.representativeGroupName}</GroupName>}
          {children && children}
          <CreatedAtWrap className={children ? "comment opacity" : "opacity"}>
            <CreatedAt onClick={() => !children && moveToProfile()} isProfile={isProfile} hasChildren={!!children} blocked={!!blockedBy}>
              {createdAt}
            </CreatedAt>
            {hourGap < 86400000 && <IconNew />}
            {children && view && (
              <ElNum
                disable={eventDisable}
                onClick={() => {
                  !eventDisable && handleLike();
                }}
              >
                <IconLike active={me.liked} />
                {likeCount}
              </ElNum>
            )}
            {!children && (
              <Hits>
                <IconEye />
                {hitCount}
              </Hits>
            )}
          </CreatedAtWrap>
          {!isProfile && (moveLink || view) && (
            <More onClick={handleMore}>
              <IconMore />
            </More>
          )}
          {more && (
            <MoreOption ref={moreRef}>
              <MenuButtons
                me={moreView}
                type={isComment ? "comment" : "post"}
                reported={reported ?? false}
                blockedBy={blockedBy}
                onWrite={handleWrite}
                onRemove={() => setRemoveModal(true)}
                onReport={() => {
                  history.push(`/${cpId ? `${cpId}/` : ""}board/report/${isComment ? "comment" : "post"}/${id}`);
                }}
                onBlock={() => {
                  setBlockModal(true);
                }}
                onCancelBock={() => {
                  setCancelBlockModal(true);
                }}
                onCancelReport={() => {
                  setCancelReportModal(true);
                }}
              />
            </MoreOption>
          )}
        </Info>
        {!isProfile && moveLink && !blockedBy && <MoveView to={moveLink} onClick={handleMoveLink} />}
      </Author>
      <Modal
        show={removeModal}
        view={"alert"}
        title={t("delete")}
        text={t("deleteText")}
        confirmText={t("yes")}
        confirm={handleRemove}
        cancelText={t("cancel")}
        cancel={() => setRemoveModal(!removeModal)}
        dimClose={() => setRemoveModal(!removeModal)}
      >
        <IconEmptyTrash />
      </Modal>
      <ReportModal
        id={id}
        isComment={isComment}
        userId={author?.id}
        userName={author?.name}
        cancelReportModal={cancelReportModal}
        blockModal={blockModal}
        cancelBlockModal={cancelBlockModal}
        onCancelReportModal={setCancelReportModal}
        onBlockModal={setBlockModal}
        onCancelBlockModal={setCancelBlockModal}
      />
    </>
  );
};

AuthorComponent.defaultProps = {};

export default withRouter(AuthorComponent);

interface IBlockReport {
  blocked: boolean;
}

const Author = styled.div<IAuthorStyle & IBlockReport>`
  display: flex;
  align-items: ${p => (p.isComment ? "flex-start" : "center")};
  position: relative;
  padding: 0 20px;

  .opacity {
    opacity: ${p => (p.blocked ? 0.5 : 1)};
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  position: relative;
  margin-left: 12px;

  &.row {
    margin-left: 0;
    flex-direction: row;
    justify-content: normal;

    > div span {
      color: ${p => p.theme.COLORS.BG_LIGHT01};
    }

    > span:first-of-type {
      margin-left: 10px;
      flex: auto;

      svg {
        margin-left: 4px;
      }
    }
  }
`;

const NameWrap = styled.div<IAuthorStyle>`
  display: flex;
  word-break: normal;
  align-items: center;
  position: relative;
  z-index: 1;
  ${p => !p.isProfile && !p.isPost && "padding-right: 30px"};

  & .icon-middle {
    display: flex;
    align-items: center;
  }
`;

const Name = styled.span<IAuthorStyle & IBlockReport>`
  ${p => p.theme.TEXTS.PROFILE_NAME}
  cursor: ${p => (!p.isProfile && !p.blocked ? "pointer" : "")};

  &:first-of-type:last-of-type {
    flex-basis: 100%;
  }
`;

const GroupName = styled.div`
  ${p => p.theme.TEXTS.PROFILE_GROUP}
  margin: 1.5px 0 8.5px;
`;

const CreatedAtWrap = styled.span`
  ${p => p.theme.TEXTS.LIST_PROFILE_TIME}
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  &.comment {
    margin-top: 5px;
  }

  & svg {
    margin-left: 2px;
  }
`;

const PROFILE_CREATED_AT_CSS = css`
  white-space: nowrap;
`;

const CreatedAt = styled.span<IAuthorStyle & IBlockReport>`
  cursor: ${p => (!p.isProfile && !p.hasChildren && !p.blocked ? "pointer" : "")};
  ${p => p.isProfile && PROFILE_CREATED_AT_CSS};
`;

const Hits = styled.span`
  ${p => p.theme.TEXTS.LIST_PROFILE_EL_NUM}
  display: flex;
  align-items: center;
  margin-left: auto;

  & svg {
    margin-right: 5px;
  }
`;

const More = styled.button`
  position: absolute;
  top: -8px;
  right: -5px;
  padding: 0;
  z-index: 1;
`;

const MoreOption = styled.div`
  position: absolute;
  top: 20px;
  right: 0;
  padding: 10px 0;
  min-width: 66px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  background: ${p => p.theme.COLORS.BG_REGULAR};
  z-index: 2;

  & button,
  & a {
    ${p => p.theme.TEXTS.CONTROL_TXT}
    display: flex;
    justify-content: center;
    padding: 1px 7px 2px;
    width: 100%;
    min-height: 32px;
    font-weight: 400;
    word-break: keep-all;
    text-align: center;
  }
`;

interface ElNumProps {
  disable: boolean;
}

const ElNum = styled.span<ElNumProps>`
  ${p => p.theme.TEXTS.LIST_EL_NUM};
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: ${p => (p.disable ? "auto" : "pointer")};
  opacity: ${p => (p.disable ? 0.4 : 1)};

  & svg {
    margin-right: 2px;
  }

  & + span {
    margin-left: 15px;
  }
`;

const MoveView = styled(Link)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
