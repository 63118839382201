export default {
  all: "SEMUA",
  search: "Cari",
  searchPlaceholder: "Cari",
  searchInfoText: "Cari judul, konten atau nama.",
  popularPosts: "Postingan populer",
  titleContent: "Judul + Konten",
  title: "Judul",
  author: "Penulis",
  tag: "Tag",
  resultCount: `<strong id="result-count"></strong> hasil`,
  noResultsFound: "Tidak ditemukan hasil."
};
