export default {
  all: "전체글",
  posts: "개의 게시물",
  newest: "최신순",
  mostLiked: "좋아요순",
  emptyPostText: "등록된 게시글이 없습니다.",
  emptyPostText2: "지금 바로 새로운 게시글을 등록해 보세요!",
  createPost: "글쓰기",
  privatePost: "비밀글 입니다.",
  seeMore: "더보기",
  writeNewPost: "새로운 글을 작성해 보세요.",
  touchTogether: "터치투게더",
  scrape: "스크랩 완료",
  scrapeOff: "스크랩 해제"
};
