import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import { Button } from "styles";
import { IconEmptyZero, IconEmptyPencil, IconEmptyComment, IconEmptySearch } from "assets/icon/svg";

const EmptyComponent: React.FC<IEmpty> = ({ text, comment, setWrite, canWritePosts, useIcon, ...props }) => {
  const { t } = useTranslation("Board");

  const handleWrite = () => {
    setWrite({
      postId: undefined,
      writeMode: true
    });
  };

  let iconSet: React.FunctionComponent | undefined;

  switch (useIcon) {
    case "emptySearch":
      iconSet = IconEmptySearch;
      break;
  }

  const IconComponent: React.FunctionComponent = iconSet ? iconSet : comment ? IconEmptyComment : canWritePosts ? IconEmptyPencil : IconEmptyZero;

  return (
    <Wrap className={comment ? "" : "full"}>
      <IconComponent />
      <Explain dangerouslySetInnerHTML={{ __html: text }} />
      {!comment && canWritePosts && (
        <Button round onClick={handleWrite}>
          {t("createPost")}
        </Button>
      )}
    </Wrap>
  );
};

EmptyComponent.defaultProps = {};

export default withRouter(EmptyComponent);

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 100px 0;
  width: 100%;

  &.full {
    height: calc(100vh - 100px);
  }
`;

const Explain = styled.p`
  ${p => p.theme.TEXTS.SYSTEM_EMPTY};
  margin: 20px 0;
  text-align: center;
`;
