import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconClose = ({ width, height, color, size }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  switch (size) {
    case "xs":
      width = 10;
      height = 10;
      break;
    case "s":
      width = 20;
      height = 20;
      break;
    case "l":
      width = 40;
      height = 40;
      break;
    case "xl":
      width = 50;
      height = 50;
      break;
    default:
      width = 30;
      height = 30;
      break;
  }

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.8178 26.9604C28.0491 27.1958 28.0491 27.5731 27.8178 27.8084C27.707 27.921 27.5556 27.9844 27.3977 27.9844C27.2397 27.9844 27.0884 27.921 26.9776 27.8084L20.249 21.6832L13.5225 27.8084C13.4118 27.921 13.2604 27.9844 13.1024 27.9844C12.9445 27.9844 12.7931 27.921 12.6823 27.8084C12.4491 27.5739 12.4491 27.195 12.6823 26.9604L19.4103 20.8352L12.6823 14.0272C12.4491 13.7927 12.4491 13.4138 12.6823 13.1792C12.7932 13.0668 12.9445 13.0034 13.1024 13.0034C13.2604 13.0034 13.4117 13.0668 13.5225 13.1792L20.249 19.9873L26.9776 13.1792C27.0884 13.0668 27.2398 13.0034 27.3977 13.0034C27.5556 13.0034 27.7069 13.0668 27.8178 13.1792C28.0491 13.4146 28.0491 13.7919 27.8178 14.0272L21.0893 20.8352L27.8178 26.9604Z"
        fill={color ? color : ThemeUse.COLORS.BG_BASE}
      />
    </svg>
  );
};

IconClose.defaultProps = {
  width: 30,
  height: 30
};

export default IconClose;
