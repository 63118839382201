import React from "react";

const IconCheck = () => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.2 4.19995L5.59995 9.79995L2.79995 6.99995" stroke="#00A1FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IconCheck;
