import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconScrape = ({ width, height, color, active }: IconScrape) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.72265 13.584L4.5 17.0657V4.5H15.5V17.0657L10.2773 13.584L10 13.3991L9.72265 13.584Z"
        stroke={active ? (color ? color : ThemeUse.COLORS.BG_BRAND) : ThemeUse.ICONS.SCRAPE.ONE}
        fill={active ? (color ? color : ThemeUse.COLORS.BG_BRAND) : "none"}
      />
    </svg>
  );
};

IconScrape.defaultProps = {
  width: 20,
  height: 20,
  active: false
};

export default IconScrape;
