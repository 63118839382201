import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import cookie from "js-cookie";
import ko from "./ko-KR";
import en from "./en-US";
import ja from "./ja-JP";
import id from "./id-ID";
import vi from "./vi-VN";

export const locales: string[] = ["ko-KR", "en-US", "ja-JP", "id-ID", "vi-VN"];

const { language } = navigator;

const systemLocale = locales.filter(item => item.toLowerCase().includes(language.toLowerCase())).shift() || "en-US";

export const getDefaultLocale = () => {
  return cookie.get("locale") || systemLocale;
};

i18n.use(initReactI18next).init({
  lng: getDefaultLocale(),
  resources: { ...ko, ...en, ...ja, ...id, ...vi },
  debug: false,
  interpolation: {
    escapeValue: false
  }
});

export const changeLang = (lang: string) => {
  const oldLang = getDefaultLocale();
  const newLang = lang;
  if (newLang !== null && oldLang !== newLang) {
    cookie.set("locale", newLang);
    i18n.changeLanguage(getDefaultLocale());
  }
};

export default i18n;
