import cookie from "js-cookie";
import queryString from "query-string";

const { userAgent } = window.navigator;

const { host, search, origin, pathname } = window.location;
const querySearch = queryString.parse(search);

if (querySearch.view) {
  cookie.set("view", querySearch.view as string)
}

if (querySearch.token) {
  cookie.remove("cpId");
  cookie.set("access_token", querySearch.token as string)

  const { touchhome, appDetail } = querySearch
  const urlChange = `${origin}${pathname}${touchhome ? "?touchhome=true" : appDetail ? "?appDetail=true" : "" }`
  window.location.replace(urlChange);
}

const siteId = host.split(".")[0];
const cpId = pathname.split("/")[1];


if(siteId === "local-user") {
  cookie.set("cpId", "spring");
  cookie.set("siteId", "dev");
} else {
  if (cpId && cpId !== "board" && cpId !== "login") {
    cookie.set("cpId", cpId);
  }
  cookie.set("siteId", siteId);
}

export const getCookieState = {
  cpId: cookie.get("cpId"),
  siteId: cookie.get("siteId"),
  token: cookie.get("access_token"),
  groupName: cookie.get("groupName"),
  view: cookie.get("view"),
  timeDisplay: cookie.get("time_display"),
}

export const defaults = {
  appView: userAgent.includes("Touchclass"),
  isLoggedIn: Boolean(cookie.get("access_token")) || false,
  theme: cookie.get("theme") || "light"
};

export const resolvers = {
  Mutation: {
    userLogin: (_: any, { token }: { token: string }, { cache }: { cache: any }) => {
      cookie.set("access_token", token);
      cache.writeData({ data: { isLoggedIn: true } });
      window.location.replace(window.location.origin);
      return null;
    },
    userLogout: () => {
      cookie.remove("access_token");
      window.location.replace(window.location.origin);
      return null;
    },
    setTheme: (_: any, { theme }: { theme: string }, { cache }: { cache: any }) => {
      cookie.set("theme", theme);
      cache.writeData({ data: { theme } });
      return null;
    }
  }
};
