import React from "react";
import { useTranslation } from "react-i18next";
import styled from "../../styles/theme";
import IconInfo from "../../assets/icon/svg/IconInfo";

interface Props {
  isComment: boolean;
  blockedBy?: string;
  reported?: boolean;
}
export const ReportTypography = ({ isComment, blockedBy, reported }: Props) => {
  const { t } = useTranslation("Report");

  const blockTitle = () => {
    return isComment ? "comment blocked" : "post blocked";
  };

  const reportTitle = () => {
    return isComment ? "comment reported" : "article reported";
  };

  return blockedBy ? (
    <Box isComment={isComment}>
      <ReportText>{t(blockTitle())} </ReportText>
    </Box>
  ) : (
    <Box isComment={isComment}>
      <IconInfo />
      <ReportText>{t(reportTitle())} </ReportText>
    </Box>
  );
};

const Box = styled.div<Props>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: ${p => `10px ${p.isComment ? 0 : 20}px`};
  height: 24px;
  width: 100%;
  flex: none;
`;

const ReportText = styled.div`
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #c2c2c2;
`;
