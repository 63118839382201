import React, { useState } from "react";
// import { gql } from "apollo-boost";
// import { useMutation } from "@apollo/react-hooks";
import cookie from "js-cookie";
import withLayout from "layouts/DefaultLayout";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import { changeLang } from "locales";
import { theme as themeMap, themeTypes } from "styles/theme";
import { Title, Button, Tab, Tag, Modal } from "styles";
import { TEXT } from "styles/Title";
import IconSvg, {
  IconEmptyZero,
  IconEmptyCheck,
  IconEmptyPencil,
  IconEmptyTrash,
  IconThum,
  IconFile,
  IconArrow01,
  IconArrow02,
  IconClose,
  IconLock,
  IconClip,
  IconEye,
  IconNew,
  IconPick,
  IconWriteEl,
  IconReport,
  IconLike,
  IconScrape,
  IconComment,
  IconCircle,
  IconMenu,
  IconPost,
  IconSearch,
  IconBack,
  IconMore,
  IconView,
  IconSend,
  IconVideo
} from "assets/icon/svg";

// const CHANGE_THEME = gql`
//   mutation setTheme($theme: String!) {
//     setTheme(theme: $theme) @client
//   }
// `;

const GuideView: React.FC<RouteComponentProps> = () => {
  const [testShow, setTestShow] = useState<boolean>(false);
  const [testShow2, setTestShow2] = useState<boolean>(false);
  const [testShow3, setTestShow3] = useState<boolean>(false);
  // const [ChangeThemeMutation] = useMutation(CHANGE_THEME);
  const { t } = useTranslation("Test");

  const handleThemeChange = (theme: string) => {
    cookie.set("theme", theme);
    window.location.reload();
  };

  const getLocale = cookie.get("locale");

  const langData = [
    {
      lang: "ko-KR",
      name: "한국어"
    },
    {
      lang: "en-US",
      name: "영어"
    },
    {
      lang: "ja-JP",
      name: "일본어"
    },
    {
      lang: "id-ID",
      name: "인도네시아어"
    },
    {
      lang: "vi-VN",
      name: "베트남어"
    }
  ];

  const ThemeUse = themeMap[document.body.id as themeTypes];

  const connection = (window.navigator as any).connection;
  return (
    <Wrap>
      <Section>
        {`effectiveType: ${connection?.effectiveType}`}
        <br />
        {`downlink: ${connection?.downlink}`}
        <br />
        {`downlinkMax: ${connection?.downlinkMax}`}
        <br />
        {`rtt: ${connection?.rtt}`}
        <br />
        {`saveData: ${connection?.saveData}`}
        <br />
        {`type: ${connection?.type}`}
        <br />
        {`onchange: ${connection?.onchange}`}
      </Section>
      <Section>
        <Button className="pressd" round onClick={() => handleThemeChange("light")}>
          light 테마(기본)
        </Button>
        <Button variant="CANCEL" onClick={() => handleThemeChange("darken")}>
          darken 테마
        </Button>
      </Section>
      <Section>
        <Title shape="H3">제목</Title>
        <div>
          <Title shape="H1">noto/medium/30 팝업/제목 팝업타이틀</Title>
          <Text01>noto/Regular/18 리스트/제목 글 제목 입니다.</Text01>
          <Title shape="H2">noto/medium/14 리스트/제목 글 제목 입니다.</Title>
          <Title shape="H3">noto/medium/18 피드/제목 글 제목 입니다.</Title>
          <Text02>noto/Regular/16 내용 내용입니다.</Text02>
          <Text03>noto/Regular/14 댓글내용 댓글내용입니다.</Text03>
          <Title shape="H4">noto/medium/6 피드/제목 상단 타이틀</Title>
          <Title shape="H5">noto/medium/6 피드/제목 상단 게시판명</Title>
        </div>
      </Section>
      <Section>
        <Title shape="H3">다국어 테스트</Title>
        {t("test")}
        <div>
          <Tab>
            {langData.map((item, index) => (
              <button type="button" className={getLocale === item.lang ? "active" : ""} onClick={() => changeLang(item.lang)} key={index}>
                {item.name}
              </button>
            ))}
          </Tab>
        </div>
      </Section>
      <Section>
        <Title shape="H3">버튼</Title>
        <div>
          <Button type="button">기본</Button>
          <Button type="button" round>
            라운드
          </Button>
          <Button type="button" disabled>
            사용금지
          </Button>
          <Button to="test" round>
            링크
          </Button>
          <Button to="https://blog.touchclass.com/" target="_blank">
            새창 링크
          </Button>
        </div>
      </Section>
      <Section>
        <Title shape="H3">탭</Title>
        <div>
          <Tab>
            <button className="active">Tab1</button>
            <a href="#none">Tab2</a>
            <button>Tab3</button>
            <button>Tab4</button>
            <button>Tab5</button>
            <button>Tab6</button>
          </Tab>
          <Tab justify>
            <button className="active">Tab1</button>
            <a href="#none">Tab2</a>
            <button>Tab3</button>
            <button>Tab4</button>
            <button>Tab5</button>
            <button>Tab6</button>
          </Tab>
          <Tab scroll>
            <button className="active">Tab1</button>
            <a href="#none">Tab2</a>
            <button>Tab3</button>
            <button>Tab4</button>
            <button>Tab5</button>
            <button>Tab6</button>
            <button>Tab7</button>
            <button>Tab8</button>
            <button>Tab9</button>
            <button>Tab10</button>
            <button>Tab11</button>
            <button>Tab12</button>
            <button>Tab13</button>
            <button>Tab14</button>
            <button>Tab15</button>
            <button>Tab16</button>
            <button>Tab17</button>
            <button>Tab18</button>
            <button>Tab19</button>
            <button>Tab20</button>
          </Tab>
        </div>
      </Section>
      <Section>
        <Title shape="H3">태그, 멘션</Title>
        <div>
          <Tag to={"guide"}>태그</Tag>
          <Tag to={"guide"} mention>
            멘션
          </Tag>
        </div>
      </Section>
      <Section>
        <Title shape="H3">모달, 팝업</Title>
        <div>
          <Button onClick={() => setTestShow2(true)}>완료 확인 열기</Button>
          <Modal show={testShow2} view={"alert"} title={"완료"} text={"글쓰기가 완료되었습니다."} confirm={() => setTestShow2(!testShow2)}>
            <IconEmptyCheck />
          </Modal>

          <Button onClick={() => setTestShow3(true)}>삭제 확인 열기</Button>
          <Modal
            show={testShow3}
            view={"alert"}
            title={"삭제"}
            text={"삭제 하시겠습니까?"}
            confirmText={"오케이"}
            confirm={() => setTestShow3(!testShow3)}
            cancelText={"취소하자"}
            cancel={() => setTestShow3(!testShow3)}
          >
            <IconEmptyTrash />
          </Modal>
          <Button onClick={() => setTestShow(true)}>모달 열기</Button>
          <Modal show={testShow} dimClose={() => setTestShow(false)}>
            <Section>
              <Title shape="H3">태그, 멘션</Title>
              <div>
                <Tag to={"guide"}>태그</Tag>
                <Tag to={"guide"} mention>
                  멘션
                </Tag>
                <Button onClick={() => setTestShow(false)}>모달 닫기</Button>
              </div>
            </Section>
          </Modal>
        </div>
      </Section>
      <Section>
        <Title shape="H3">아이콘</Title>
        <div>
          <IconSvg.EmptyZero />
          <IconSvg.EmptyCheck />
          <IconSvg.EmptyTrash />
          <IconSvg.EmptyPencil />
          <IconEmptyZero />
          <IconEmptyCheck />
          <IconEmptyTrash />
          <IconEmptyPencil />
        </div>
        <div>
          <IconThum />
          <IconThum video />
        </div>
        <div>
          <IconFile type="audio" />
          <IconFile type="doc" />
          <IconFile type="video" />
          <IconFile type="down" />
        </div>
        <div>
          <IconArrow01 direction="top" />
          <IconArrow01 direction="right" />
          <IconArrow01 direction="bottom" />
          <IconArrow01 direction="left" />
        </div>
        <div>
          <IconArrow02 direction="top" />
          <IconArrow02 direction="right" />
          <IconArrow02 direction="bottom" />
          <IconArrow02 direction="left" />
        </div>
        <div>
          <IconClose size="xs" />
          <IconClose size="s" />
          <IconClose />
          <IconClose size="l" />
          <IconClose size="xl" />
        </div>
        <div>
          <IconLock size="xs" />
          <IconLock size="s" />
          <IconLock />
          <IconLock size="l" />
          <IconLock size="xl" />
          <IconLock size="xl" color={ThemeUse.COLORS.BG_BRAND} full lock />
        </div>
        <div>
          <IconClip />
          <IconEye />
        </div>
        <div>
          <IconPick />
          <IconNew />
        </div>
        <div>
          <IconWriteEl type="plus" />
          <IconWriteEl type="close" />
          <IconWriteEl type="img" />
          <IconWriteEl type="sticker" />
          <IconWriteEl type="underline" />
          <IconWriteEl type="video" />
          <IconWriteEl type="file" />
          <IconWriteEl type="bold" />
          <IconWriteEl type="text" />
          <IconWriteEl type="overline" />
          <IconWriteEl type="italic" />
        </div>
        <div>
          <IconReport />
        </div>
        <div>
          <IconLike />
          <IconLike active />
        </div>
        <div>
          <IconScrape />
          <IconScrape active />
        </div>
        <div>
          <IconComment />
        </div>
        <div>
          <IconCircle />
          <IconCircle type="minus" />
        </div>
        <div>
          <IconMenu />
          <IconPost />
          <IconSearch />
          <IconBack />
        </div>
        <div>
          <IconMore size="xs" />
          <IconMore size="s" />
          <IconMore />
          <IconMore size="l" />
          <IconMore size="xl" />
        </div>
        <div>
          <IconView />
          <IconView type="list" />
        </div>
        <div>
          <IconSend />
        </div>
        <div>
          <IconVideo />
          <IconVideo status="replay" />
        </div>
      </Section>
    </Wrap>
  );
};

export default withLayout(GuideView);

const Wrap = styled.div``;

const Section = styled.div`
  margin-top: 40px;

  :first-of-type {
    margin-top: 0;
  }
`;

const Text01 = styled.p`
  ${TEXT.TYPE01};
`;

const Text02 = styled.p`
  ${TEXT.TYPE02};
`;

const Text03 = styled.p`
  ${TEXT.TYPE03};
`;
