export default {
  ok: "OK",
  cancel: "Cancel",
  confirm: "Post",
  "Report a post": "Report a post",
  "block author": "block author",
  report: "report",
  "Processing policy": "After confirmation by the manager: it is processed according to internal regulations.",
  Reason: "Please enter the reason for the report. (up to 150 characters)",
  "article reported": "This is the article I reported.",
  "Report a comment": "Report a comment",
  "comment reported": "This is the comment I reported.",
  "block description": "You will not be notified of posts and comments by this author and will not be able to see what they wrote.",
  "to block": "to block",
  "post blocked": "This is a post from a member I blocked.",
  "comment blocked": "This is a comment from a member you blocked.",
  "Would you like to unblock?": "Would you like to unblock?",
  "Members I Blocked": "Members I Blocked",
  ReportCompleted: "Report completed.",
  success: "Berhasil!",
  unblock: "unblock"
};
