import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import Truncate from "react-text-truncate";

import { Modal } from "styles";

import Author from "./Author";
import EllipsisMore from "./EllipsisMore";
import CommentWrite from "components/Board/CommentWrite";

import { IconVideo, IconClose, IconDownload } from "assets/icon/svg";
import thumLoad from "assets/icon/thum_load.png";
import ImageSlider from "components/Common/ImageSlider";
import { ReportTypography } from "../../Common/ReportTypography";
import { getCookieState } from "../../../apollo/LocalState";

const CommentComponent: React.FC<IComment> = ({ id, post, author, content, createdAt, picked, likeCount, files, me, view, commentSort, moveLink, blockedBy, preventDownload }) => {
  const { t } = useTranslation("Board");
  const { hash } = window.location;

  const [modify, setModify] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const [dim, setDim] = useState<boolean>(true);

  const handleModalClose = () => {
    window.history.go(-1);
    setModal(false);
  };

  const toggleModalHeader = () => {
    setDim(!dim);
  };

  const closeModal = () => {
    setModal(false);
  };

  const handleImgSrc = (prevProps: any, nextProps: any) => {
    return prevProps.src === nextProps.src;
  };

  const ImgMemo = memo(ImgEl, handleImgSrc);

  useEffect(() => {
    if (modal) {
      const videoEl = document.getElementById("video-play") as HTMLVideoElement;
      videoEl && videoEl.play();
      window.addEventListener("popstate", closeModal);
    }

    return () => {
      window.removeEventListener("popstate", closeModal);
    };
  }, [modal]);

  const { cpId } = getCookieState;

  return (
    <Comment id={`c${id}`} className={view ? "view" : ""}>
      {!modify && (
        <Author
          id={id}
          post={post}
          author={author}
          createdAt={createdAt}
          picked={picked}
          likeCount={likeCount}
          me={me}
          view={view}
          commentSort={commentSort}
          isComment={true}
          setWrite={setModify}
          reported={me.reported}
          blockedBy={blockedBy}
        >
          {!me.reported && !blockedBy ? (
            <Wrap className={"feed"}>
              {content.length > 0 && (
                <Content>
                  {view ? (
                    content
                  ) : (
                    <Truncate
                      line={files && files.length > 0 ? 1 : 3}
                      truncateText={""}
                      textTruncateChild={<EllipsisMore ellpsisText={".."} moreText={t("seeMore")} />}
                      text={content}
                    />
                  )
                  // <HTMLEllipsis unsafeHTML={content} maxLine={files && files.length > 0 ? 1 : 3} ellipsis={`..${t("seeMore")}`} basedOn="letters" />
                  }
                </Content>
              )}
              {files && files.length > 0 && (
                <ThumWrap className={"feed-thum"}>
                  <Link to={`#${id}`} onClick={() => setModal(true)}>
                    <Thumnail>
                      <ImgMemo src={`${files[0].url}?size=display`} alt={files[0].name} />
                      {files[0].type?.includes("video") && <IconVideo />}
                    </Thumnail>
                  </Link>
                </ThumWrap>
              )}
            </Wrap>
          ) : (
            <ReportTypography blockedBy={blockedBy} reported={me.reported} isComment={true} />
          )}
        </Author>
      )}
      {moveLink && !modify && <MoveView to={moveLink} />}
      {me.commented && modify && <CommentWrite id={id} author={author} createdAt={createdAt} commentSort={commentSort} setWrite={setModify} />}
      {hash === `#${id}` && view && files && files.length > 0 && (
        <Modal deep show={modal} dimClose={toggleModalHeader}>
          {dim && (
            <ModalHeader>
              <CloseButton onClick={handleModalClose}>
                <IconClose size={"l"} color={"#FFF"} />
              </CloseButton>
              {!files[0].type?.includes("video") && cpId !== "everland" && !preventDownload && (
                <DownloadButton>
                  <Download href={`${files[0].url}?disposition=attachment`} download={files[0].name}>
                    <IconDownload size={"l"} color={"#FFF"} />
                  </Download>
                </DownloadButton>
              )}
            </ModalHeader>
          )}
          <FullScreen
            onContextMenu={e => {
              e.preventDefault();
            }}
          >
            {files[0].type?.includes("video") && (
              <video id={"video-play"} poster={`${files[0].url}?size=display`} controls>
                <source src={files[0].url} type={files[0].type} />
              </video>
            )}
            {!files[0].type?.includes("video") && <ImageSlider files={files} onClick={toggleModalHeader} />}
          </FullScreen>
        </Modal>
      )}
    </Comment>
  );
};

CommentComponent.defaultProps = {};

export default CommentComponent;

const Comment = styled.div`
  position: relative;
  padding: 30px 0 10px;

  &:first-of-type {
    padding-top: 20px;
    border-top: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  }

  &.view {
    padding-top: 20px;
    border-top: 0 none;

    &:first-of-type {
      padding-top: 10px;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Content = styled.div`
  ${p => p.theme.TEXTS.COMMENT_CONT};
  order: 0;
  word-break: break-all;
  white-space: pre-wrap;

  & svg {
    vertical-align: -2px;
  }
`;

const ThumWrap = styled.div`
  margin-top: 5px;
`;

const Thumnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 6px;
  background: url(${thumLoad}) center center no-repeat;
  background-size: 100%;
  overflow: hidden;

  & img {
    height: 200%;
    vertical-align: top;
    cursor: pointer;
  }

  & svg {
    position: absolute;
    bottom: 50%;
    left: 50%;
    margin: 0 0 -25px -25px;
    cursor: pointer;
  }
`;

const ImgEl = styled.img``;

const MoveView = styled(Link)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 10px;
  width: 100%;
  height: 46px;
  background: ${p => p.theme.COLORS.BG_OVERLAY4};
  z-index: 10;

  & button {
    padding: 0;
    height: 40px;
  }

  & svg {
    position: relative;
    z-index: 5;
  }
`;
const CloseButton = styled.button``;

const DownloadButton = styled.button``;

const Download = styled.a``;

const FullScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & img,
  & video {
    max-width: 100%;
    max-height: 80vh;
  }
`;
