export default {
  all: "SEMUA",
  posts: "&nbsp;postingan",
  newest: "Terbaru",
  mostLiked: "Paling banyak disukai",
  emptyPostText: "Belum ada postingan.",
  emptyPostText2: "Jadilah yang pertama membuat postingan.",
  createPost: "Buat postingan",
  privatePost: "Postingan Privat",
  seeMore: "Lihat selengkapnya",
  writeNewPost: "Tulis postingan baru.",
  touchTogether: "Touch Together",
  scrape: "Diarsipkan",
  scrapeOff: "Tidak diarsipkan"
};
