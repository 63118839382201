import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";
const IconDownload = ({ size, width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  switch (size) {
    case "xs":
      width = 10;
      height = 10;
      break;
    case "s":
      width = 20;
      height = 20;
      break;
    case "l":
      width = 40;
      height = 40;
      break;
    case "xl":
      width = 50;
      height = 50;
      break;
    default:
      width = 30;
      height = 30;
      break;
  }

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.448 24.1746L20.448 11.5357C20.4614 11.349 20.3692 11.1705 20.2091 11.073C20.0489 10.9756 19.8476 10.9756 19.6874 11.073C19.5273 11.1705 19.4351 11.349 19.4485 11.5357L19.4485 24.1649L13.3526 17.6739C13.2621 17.5738 13.1331 17.5168 12.9979 17.5174C12.8627 17.518 12.7342 17.5761 12.6446 17.677C12.45 17.894 12.452 18.2228 12.6493 18.4373L19.6018 25.841C19.6912 25.941 19.8191 25.9982 19.9534 25.9982C20.0877 25.9982 20.2157 25.941 20.3051 25.841L27.351 18.4373C27.5481 18.2226 27.5499 17.8938 27.3551 17.677C27.2654 17.5764 27.1371 17.5186 27.0021 17.518C26.8671 17.5174 26.7383 17.5741 26.6477 17.6739L20.448 24.1746Z"
        fill={color ? color : ThemeUse.COLORS.BG_BASE}
      />
      <rect x="12" y="28" width="16" height="1" rx="0.5" fill={color ? color : ThemeUse.COLORS.BG_BASE} />
      <rect x="12" y="28" width="16" height="1" rx="0.5" fill={color ? color : ThemeUse.COLORS.BG_BASE} />
      <defs>
        <linearGradient id="paint0_linear" x1="20" y1="28" x2="20" y2="29" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

IconDownload.defaultProps = {
  width: 40,
  height: 40
};

export default IconDownload;
