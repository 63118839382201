import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";
const IconMore = ({ width, height, color, size }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  switch (size) {
    case "xs":
      width = 10;
      height = 10;
      break;
    case "s":
      width = 20;
      height = 20;
      break;
    case "l":
      width = 40;
      height = 40;
      break;
    case "xl":
      width = 50;
      height = 50;
      break;
    default:
      width = 30;
      height = 30;
      break;
  }

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 22.5C14.3284 22.5 15 21.8284 15 21C15 20.1716 14.3284 19.5 13.5 19.5C12.6716 19.5 12 20.1716 12 21C12 21.8284 12.6716 22.5 13.5 22.5ZM20.5 22.5C21.3284 22.5 22 21.8284 22 21C22 20.1716 21.3284 19.5 20.5 19.5C19.6716 19.5 19 20.1716 19 21C19 21.8284 19.6716 22.5 20.5 22.5ZM29 21C29 21.8284 28.3284 22.5 27.5 22.5C26.6716 22.5 26 21.8284 26 21C26 20.1716 26.6716 19.5 27.5 19.5C28.3284 19.5 29 20.1716 29 21Z"
        fill={color ? color : ThemeUse.ICONS.MORE.ONE}
      />
    </svg>
  );
};

IconMore.defaultProps = {
  width: 40,
  height: 40,
  size: 30
};

export default IconMore;
