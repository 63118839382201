export default {
  ok: "확인",
  cancel: "취소",
  confirm: "완료",
  "Report a post": "글 신고",
  "block author": "작성자 차단",
  report: "신고하기",
  "Processing policy": "관리자가 확인 후 내부 규정에 따라 처리됩니다.",
  Reason: "신고 사유를 입력하세요. (150자 이내)",
  "article reported": "내가 신고한 글입니다.",
  "Report a comment": "댓글 신고",
  "comment reported": "내가 신고한 댓글입니다.",
  "block description": "이 작성자가 쓴 글과 댓글에 대해 알림이 오지 않고 작성한 내용도  보이지 않게 됩니다.",
  "to block": "차단하기",
  "post blocked": "내가 차단한 회원의 글입니다.",
  "comment blocked": "내가 차단한 회원의 댓글입니다.",
  "Would you like to unblock?": "차단을 해제하시겠어요?",
  "Members I Blocked": "내가 차단한 회원",
  ReportCompleted: "신고를 완료했습니다.",
  success: "완료",
  unblock: "차단 해제"
};
