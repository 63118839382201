import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import { withRouter } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import Photo from "components/User/Photo";
import { Toolbar, File } from "components/Board";

import { dateDisplay } from "utils";

import { Button } from "styles";

import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { GET_CATEGORY_POST, LIST_COMMENTS, GET_COMMENT, CREATE_COMMENT, UPDATE_COMMENT } from "api/Board";

const CommentWriteComponent: React.FC<ICommentWrite> = ({ location, match, id, author, createdAt, commentSort, setWrite, ...props }) => {
  const { t } = useTranslation("Detail");

  const { search } = location;
  const { categoryId, postId } = match.params;

  const queryPath = queryString.parse(search);
  const sortBy = queryPath.sortBy as SortType;

  const writeRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [valueCheck, setValueCheck] = useState<boolean>(!!id);
  const [fileList, setFileList] = useState<IFile[]>([]);
  const [fileUrlList, setFileUrlList] = useState<string[]>([]);

  const [getComment] = useLazyQuery(GET_COMMENT, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data) {
        const textArea = textAreaRef.current;

        if (textArea) {
          textArea.value = data.getComment.content;
          textArea.focus();
        }

        if (data.getComment.files.length > 0) {
          setFileList(data.getComment.files);
          setFileUrlList([data.getComment.files[0].url]);
        }
      }
    }
  });

  const [commentMutation] = useMutation(id ? UPDATE_COMMENT : CREATE_COMMENT, {
    refetchQueries: [{ query: LIST_COMMENTS, variables: { postId, pageInfo: { limit: 20, nextToken: null }, options: { sortBy: commentSort } } }],
    awaitRefetchQueries: true,
    update: (store, { data }) => {
      try {
        const postListData: any = store.readQuery({
          query: GET_CATEGORY_POST,
          variables: { id: categoryId, limit: 20, nextToken: null, options: { sortBy } }
        });

        if (postListData && data.createComment) {
          const currentData = postListData.getCategory.posts.items.filter((item: IPost) => item.id === postId)[0];
          currentData.comments.items.unshift(data.createComment);
          currentData.comments.totalCount = currentData.comments.totalCount + 1;

          const sumPostCommentData: IPost[] = postListData.getCategory.posts.items.reduce((acc: IPost[], cur: IPost) => {
            if (cur.id === postId) {
              cur = currentData;
            }
            acc.push(cur);

            return acc;
          }, []);

          store.writeQuery({
            query: GET_CATEGORY_POST,
            variables: { id: categoryId, limit: 20, nextToken: null, options: { sortBy } },
            data: {
              getCategory: {
                ...postListData.getCategory,
                posts: {
                  ...postListData.getCategory.posts,
                  items: sumPostCommentData
                }
              }
            }
          });
        }
      } catch (err) {
        // console.log(err, "err");
      }
    },
    onCompleted: data => {
      if (data) {
        const textArea = textAreaRef.current;

        if (textArea) {
          textArea.value = "";
          !id && handleCommentTop();
          setWrite(false);
        }
      }
    }
  });

  const focusEvent = (event: MouseEvent) => {
    event.stopPropagation();
    const dom = writeRef.current;
    if (dom) {
      const rect = dom.getBoundingClientRect();
      if (!(rect.top - 84 < event.clientY && rect.bottom > event.clientY && rect.left < event.clientX && rect.right > event.clientX)) {
        setWrite(false);
      }
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { currentTarget } = e;

    if (currentTarget) {
      //currentTarget.value = currentTarget.value.replace(/(<([^>]+)>)/gi, " ");
      setValueCheck(currentTarget.value.length > 0 ? true : false);
    }
  };

  const handleComment = () => {
    getComment({
      variables: { id }
    });
  };

  const handleCommentMutation = () => {
    const textArea = textAreaRef.current;

    if (textArea) {
      const variables = {
        postId,
        id,
        input: {
          content: textArea.value,
          files: fileUrlList
        }
      };

      if (textArea.value.length > 0 || fileUrlList.length > 0) {
        commentMutation({ variables });
      }
    }
  };

  const handleCommentTop = () => {
    const detailtWrap = document.getElementById("detail-wrap");
    if (detailtWrap) {
      const rect = detailtWrap.getBoundingClientRect();

      window.scrollTo(0, Number(rect.height));
    }
  };

  const handleFileDelete = (url: string) => {
    const tempFileList = fileList.filter(item => item.url !== url);
    const tempUrlList = fileUrlList.filter(item => item !== url);

    setFileList(tempFileList);
    setFileUrlList(tempUrlList);

    setValueCheck(false);
  };

  if (fileUrlList.length > 1) {
    setFileList([fileList[1]]);
    setFileUrlList([fileUrlList[1]]);
  }

  useEffect(() => {
    id && handleComment();

    const textArea = textAreaRef.current;

    if (textArea) {
      textArea.focus();
    }

    window.addEventListener("click", focusEvent);

    return () => {
      window.removeEventListener("click", focusEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommentWrite ref={writeRef} {...props}>
      <User>
        <Photo size={"md"} src={author.picture} />
        <Name>
          {author.name}
          {createdAt && <CommentDate>{dateDisplay(createdAt as string)}</CommentDate>}
        </Name>
        <CommentConfirm onClick={handleCommentMutation} variant={valueCheck || fileList.length > 0 ? "PRIMARY" : "CANCEL"} round>
          {t(id ? "save" : "commentPost")}
        </CommentConfirm>
      </User>
      <CommentInputWrap>
        <CommentInputText ref={textAreaRef} placeholder={t("leaveComment")} onChange={handleChange}></CommentInputText>
        {fileList.length < 2 && fileList.length > 0 && (
          <FileWrap>
            {fileList.map((item, index) => (
              <File {...item} status={"commentWrite"} callDelete={handleFileDelete} key={index} />
            ))}
          </FileWrap>
        )}
        <Toolbar align={"RIGHT"} image video fileList={fileList} fileUrlList={fileUrlList} setFileList={setFileList} setFileUrlList={setFileUrlList} />
      </CommentInputWrap>
      {/* <Modal show={completeValue} view={"alert"} title={"완료"} text={"댓글쓰기가 완료되었습니다."} confirm={() => setCompleteValue(false)}>
        <IconEmptyCheck />
      </Modal> */}
    </CommentWrite>
  );
};

CommentWriteComponent.defaultProps = {};

export default withRouter(CommentWriteComponent);

const CommentWrite = styled.div`
  padding: 20px;
  background: ${p => p.theme.COLORS.BG_BODY};
`;

const CommentInputWrap = styled.div`
  margin-top: 10px;
  padding: 10px;
  min-height: 42px;
  border: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  height: auto;
  overflow: auto;
`;

const CommentInputText = styled.textarea`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 110px;
  line-height: 22px;
  border: 0 none;
`;

const User = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const Name = styled.div`
  ${p => p.theme.TEXTS.PROFILE_NAME}
  margin-left: 10px;
`;

const CommentDate = styled.span`
  ${p => p.theme.TEXTS.LIST_PROFILE_TIME}
  display: block;
`;

const CommentConfirm = styled(Button)`
  margin-left: auto;
  padding: 0 10px;
  min-width: 60px;
  height: 26px;
  line-height: 22px;
  font-size: 14px;
`;

const FileWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 10px;
  padding: 7px;
  background: ${p => p.theme.COLORS.BG_LIGHT04};
`;
