import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconLoading = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5874 13.8175C28.1949 14.56 28.7349 15.4375 29.0724 16.315C29.5449 17.53 29.8149 18.745 29.8149 19.96C29.8149 21.2425 29.6124 22.4575 29.1399 23.6725C28.8024 24.6175 28.2624 25.4275 27.6549 26.2375L30.0174 28.6C30.9624 27.52 31.7049 26.305 32.2449 25.0225C32.8524 23.4025 33.1899 21.7825 33.1899 20.095C33.1899 18.4075 32.8524 16.7875 32.1774 15.1675C31.6374 13.8175 30.8949 12.6025 29.9499 11.5225L27.5874 13.8175Z"
        fill={color ? color : ThemeUse.COLORS.BG_BRAND}
      />
      <path
        d="M13.8176 12.4675C14.5601 11.7925 15.4376 11.32 16.3826 10.9825C17.5976 10.51 18.8126 10.3075 20.0951 10.3075C21.3776 10.3075 22.5926 10.5775 23.7401 11.05C24.6176 11.3875 25.4951 11.9275 26.2376 12.535L28.5326 10.24C27.4526 9.295 26.2376 8.5525 24.8876 8.0125C23.2676 7.405 21.6476 7.0675 19.9601 7C18.2726 7 16.6526 7.27 15.0326 7.945C13.6826 8.485 12.5351 9.2275 11.4551 10.1725L13.8176 12.4675Z"
        fill={color ? color : ThemeUse.COLORS.BG_BRAND}
      />
      <path
        d="M26.3048 27.5875C25.4948 28.2625 24.6848 28.735 23.7398 29.0725C22.5248 29.545 21.3098 29.7475 20.0273 29.7475C18.7448 29.7475 17.5298 29.4775 16.3823 29.005C15.4373 28.6675 14.6273 28.1275 13.8848 27.52L11.5898 29.815C12.6698 30.76 13.8848 31.5025 15.1673 32.0425C16.7873 32.7175 18.4073 32.9875 20.0948 33.055C21.7823 33.055 23.4023 32.785 25.0223 32.11C26.3723 31.57 27.5198 30.8275 28.6673 29.8825L26.3048 27.5875Z"
        fill={color ? color : ThemeUse.COLORS.BG_BRAND}
      />
      <path
        d="M12.535 26.2375C11.9275 25.495 11.3875 24.6175 11.05 23.74C10.5775 22.525 10.3075 21.31 10.3075 20.0275C10.3075 18.745 10.51 17.53 10.9825 16.315C11.32 15.37 11.86 14.56 12.4675 13.75L10.1725 11.455C9.2275 12.535 8.485 13.75 7.945 15.1C7.3375 16.72 7 18.34 7 20.0275C7 21.715 7.3375 23.335 8.0125 24.955C8.5525 26.2375 9.295 27.4525 10.24 28.5325L12.535 26.2375Z"
        fill={color ? color : ThemeUse.COLORS.BG_BRAND}
      />
    </svg>
  );
};

IconLoading.defaultProps = {
  width: 40,
  height: 40
};

export default IconLoading;
