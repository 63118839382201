const { userAgent } = window.navigator;

const appNative = {
  close: (data?: string) => {
    if (userAgent.includes("Touchclass iOS")) {
      window.webkit?.messageHandlers.onTogether.postMessage({ action: "close", value: data });
    } else if (userAgent.includes("Touchclass Android")) {
      window.onTogether?.close(data);
    }
  },
  closeData: (data: any) => {
    if (userAgent.includes("Touchclass iOS")) {
      window.webkit?.messageHandlers.onTogether.postMessage({ action: "closeData", value: data ? data : "" });
    } else if (userAgent.includes("Touchclass Android")) {
      if (window.onTogether?.closeData) {
        window.onTogether?.closeData(data ? JSON.stringify(data) : "");
      }
    }
  },
  cellularCheck: () => {
    let cellularCheck = false;

    if (userAgent.includes("Touchclass iOS")) {
      cellularCheck = window.networkStatusApp === "cellular" ? true : false;
    } else {
      try {
        const connection = (window.navigator as any).connection;
        if (connection) {
          cellularCheck = connection.type === "cellular" ? true : false;
        }
      } catch {
        cellularCheck = false;
      }
    }

    return cellularCheck;
  },
  handleVideo: () => {
    if (userAgent.includes("Touchclass iOS")) {
      window.webkit?.messageHandlers.onTogether.postMessage({ action: "video" });
    } else if (userAgent.includes("Touchclass Android")) {
      if (window.onTogether?.video) {
        window.onTogether?.video();
      }
    }
  },
  checkPage: (type: string) => {
    const data = { method: "present", type };
    if (userAgent.includes("Touchclass iOS")) {
      window.webkit?.messageHandlers.onTogether.postMessage({ action: "view", value: data });
    } else if (userAgent.includes("Touchclass Android")) {
      if (window.onTogether?.view) {
        window.onTogether?.view(data ? JSON.stringify(data) : "");
      }
    }
  },
  isNative: () => {
    return (userAgent.includes("Touchclass iOS")) || (userAgent.includes("Touchclass Android"));
  }
};

export default appNative;
