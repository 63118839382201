import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconReport = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5333 9.54167H13.4667V7.5H14.5333V9.54167ZM9.475 11.5435L7.96667 10.0999L8.71667 9.38216L10.225 10.8257L9.475 11.5435ZM6 14.5H8.13333V15.5H6V14.5ZM22 14.5V15.5H19.8667V14.5H22ZM18.525 11.5435L17.775 10.8257L19.2833 9.38216L20.0333 10.0999L18.525 11.5435ZM14 11.5833C14.5167 11.5833 15 11.679 15.45 11.8704C15.9056 12.0565 16.3 12.3117 16.6333 12.6361C16.9722 12.9551 17.2389 13.3326 17.4333 13.7686C17.6333 14.1992 17.7333 14.6618 17.7333 15.1562V20.5H18.8V21.5H16.6667H15.6H12.4H11.3333H9.2V20.5H10.2667V15.1562C10.2667 14.6618 10.3639 14.1992 10.5583 13.7686C10.7583 13.3326 11.025 12.9551 11.3583 12.6361C11.6972 12.3117 12.0917 12.0565 12.5417 11.8704C12.9972 11.679 13.4833 11.5833 14 11.5833ZM16.6667 20.5V15.1562C16.6667 14.8053 16.5944 14.4757 16.45 14.1673C16.3111 13.8589 16.1194 13.5904 15.875 13.3618C15.6361 13.1279 15.3556 12.9444 15.0333 12.8115C14.7111 12.6733 14.3667 12.6042 14 12.6042C13.6333 12.6042 13.2889 12.6733 12.9667 12.8115C12.6444 12.9444 12.3611 13.1279 12.1167 13.3618C11.8778 13.5904 11.6861 13.8589 11.5417 14.1673C11.4028 14.4757 11.3333 14.8053 11.3333 15.1562V20.5H16.6667Z"
        fill={color ? color : ThemeUse.COLORS.BG_POINT}
      />
    </svg>
  );
};

IconReport.defaultProps = {
  width: 28,
  height: 28
};

export default IconReport;
