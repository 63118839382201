import React, { ChangeEvent, useState } from "react";
import styled from "styles/theme";
import { IconClose, IconEmptyCheck } from "assets/icon/svg";
import { useMutation } from "@apollo/react-hooks";
import { REPORT_COMMENT, REPORT_POST } from "api/Board";
import withLayout from "layouts/ReportLayout";
import { useTranslation } from "react-i18next";
import { REPORT_USER } from "../../api/User";
import { Modal } from "../../styles";

const ReportComponent: React.FC<RouteBaseComponentProps> = ({ match }) => {
  const { t } = useTranslation("Report");
  const { postId, commentId, userId } = match.params;
  const [text, setText] = useState<string | undefined>();
  const [confirmValue, setConfirmValue] = useState<boolean>(false);
  const [modal, setModal] = useState(false);

  const gql = () => {
    if (postId) return REPORT_POST;
    if (commentId) return REPORT_COMMENT;
    if (userId) return REPORT_USER;
  };

  const [reportPostMutation] = useMutation(gql(), {
    onCompleted: () => {
      if (userId) {
        setModal(true);
      } else {
        handleClose();
      }
    }
  });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const text = e.target.value;
    setText(text);
    setConfirmValue(text?.length > 0 && text?.length <= 150);
  };

  const handleClose = () => {
    setModal(false);
    window.history.go(-1);
  };

  const handleConfirm = () => {
    setConfirmValue(false);
    void reportPostMutation({
      variables: {
        id: postId ?? commentId ?? userId,
        input: {
          description: text
        }
      }
    });
  };

  return (
    <Report>
      <Top id="header">
        <Lnb>
          <Menu onClick={handleClose}>
            <IconClose size={"l"} />
          </Menu>
        </Lnb>
        <Title>{t("report")}</Title>
        <Rnb>
          <Confirm active={confirmValue} onClick={confirmValue ? handleConfirm : undefined}>
            {t("confirm")}
          </Confirm>
        </Rnb>
      </Top>

      <Explain>{t("Processing policy")}</Explain>
      <EditorWrap value={text} onChange={handleChange} placeholder={t("Reason")}></EditorWrap>
      <Modal show={modal} view={"alert"} title={t("success")} text={t("ReportCompleted")} confirmText={t("ok")} confirm={() => handleClose()}>
        <IconEmptyCheck />
      </Modal>
    </Report>
  );
};

export default withLayout(ReportComponent);

const Report = styled.div`
  top: 0;
  left: 0;
  padding: 10px 0 0 0;
  width: 100%;
  height: 100%;
`;

const Top = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${p => p.theme.COLORS.BG_BODY};
`;

const Lnb = styled.div`
  padding-left: 10px;
`;

const Rnb = styled.div`
  padding-right: 13px;

  & a {
    vertical-align: top;
  }
`;

const Title = styled.h2`
  ${p => p.theme.TEXTS.TOP_TITLE};
  color: ${p => p.theme.COLORS.TEXT_POINT};
`;

const Menu = styled.button<IWriteConfirm>`
  padding: 0;
`;

const Confirm = styled.button<IWriteConfirm>`
  display: flex;
  align-items: center;
  padding: 0 7px;
  height: 40px;
  color: ${p => (p.active ? p.theme.COLORS.TEXT_BRAND : p.theme.COLORS.TEXT_DISABLED)};
`;

const Explain = styled.div`
  padding: 10px 20px;
  width: 100%;
  border-bottom: 4px solid ${p => p.theme.COLORS.BORDER_REGULAR};
`;

const EditorWrap = styled.textarea`
  padding: 20px;
  width: 100%;
  height: calc(100vh - 86px);
  border: none;
`;
