export function decodeEntity(str: string) {
  if (!str) return str;
  return String(str)
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&quot;/g, '"');
}

export function encodeEntity(str: string) {
  if (!str) return str;
  return String(str)
    .replace(/&/g, "&amp;")
    .replace(/>/g, "&gt;")
    .replace(/</g, "&lt;")
    .replace(/"/g, "&quot;");
}

export function isPdf(type?: string) {
  return type === "application/pdf";
}

export function isGif(url?: string) {
  const extension = getFileExtension(url);
  return extension === "gif";
}

export function getFileExtension(url?: string) {
  if (url) {
    const extension = url.split(".").pop();
    if (extension) {
      return extension.toLowerCase();
    }
  }
  return url;
}
