export default {
  all: "Tất cả",
  posts: "&nbsp;bài đăng",
  newest: "Mới nhất",
  mostLiked: "Được yêu thích nhất",
  emptyPostText: "Chưa có bài đăng nào.",
  emptyPostText2: "Hãy là người đầu tiên đăng bài.",
  createPost: "Tạo bài đăng",
  privatePost: "Bài đăng riêng tư",
  seeMore: "Xem thêm",
  writeNewPost: "Viết bài đăng mới.",
  touchTogether: "Touch Together",
  scrape: "Đã lưu trữ",
  scrapeOff: "Đã hủy lưu trữ"
};
