import React from "react";
import styled from "styles/theme";

const EllipsisMoreComponent: React.FC<IEllpsisMore> = ({ ellpsisText, moreText, beforeNode, afterNode }) => {
  return (
    <EllipsisMore className="more">
      {beforeNode && beforeNode}
      {ellpsisText}
      {moreText && moreText}
      {afterNode && afterNode}
    </EllipsisMore>
  );
};

EllipsisMoreComponent.defaultProps = {};

export default EllipsisMoreComponent;

const EllipsisMore = styled.span`
  color: ${p => p.theme.COLORS.BG_SECONDARY};
  font-size: 0.85em;
`;
