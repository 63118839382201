export default {
  all: "全体",
  posts: "件の掲示物",
  newest: "最新順",
  mostLiked: "いいね！順",
  emptyPostText: "投稿が存在しません。",
  emptyPostText2: "投稿を作成してください。",
  createPost: "投稿",
  privatePost: "シークレット投稿です。",
  seeMore: "続きを見る",
  writeNewPost: "投稿を作成してください。",
  touchTogether: "Touch Together",
  scrape: "アーカイブ完了",
  scrapeOff: "アーカイブ解除"
};
