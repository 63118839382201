export default {
  all: "All",
  search: "Search",
  searchPlaceholder: "Search",
  searchInfoText: "Search a title, content or name.",
  popularPosts: "Popular posts",
  titleContent: "Title + Content",
  title: "Title",
  author: "Author",
  tag: "Tag",
  resultCount: `<strong id="result-count"></strong> results`,
  noResultsFound: "No results found."
};
