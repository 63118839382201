import React from "react";
import styled from "styles/theme";

const SpinnerComponent: React.FC = () => {
  return <Spinner />;
};

SpinnerComponent.defaultProps = {};

export default SpinnerComponent;

const Spinner = styled.div`
  margin: 20px auto;
  font-size: 5px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: load5 1.1s infinite ease;
  transform: translateZ(0);

  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0 -2.6em 0 0 ${p => p.theme.COLORS.BG_BRAND}, 1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2), 2.5em 0 0 0 rgba(0, 170, 255, 0.2),
        1.75em 1.75em 0 0 rgba(0, 170, 255, 0.2), 0 2.5em 0 0 rgba(0, 170, 255, 0.2), -1.8em 1.8em 0 0 rgba(0, 170, 255, 0.2), -2.6em 0 0 0 rgba(0, 170, 255, 0.5),
        -1.8em -1.8em 0 0 rgba(0, 170, 255, 0.7);
    }

    12.5% {
      box-shadow: 0 -2.6em 0 0 rgba(0, 170, 255, 0.7), 1.8em -1.8em 0 0 ${p => p.theme.COLORS.BG_BRAND}, 2.5em 0 0 0 rgba(0, 170, 255, 0.2),
        1.75em 1.75em 0 0 rgba(0, 170, 255, 0.2), 0 2.5em 0 0 rgba(0, 170, 255, 0.2), -1.8em 1.8em 0 0 rgba(0, 170, 255, 0.2), -2.6em 0 0 0 rgba(0, 170, 255, 0.2),
        -1.8em -1.8em 0 0 rgba(0, 170, 255, 0.5);
    }

    25% {
      box-shadow: 0 -2.6em 0 0 rgba(0, 170, 255, 0.5), 1.8em -1.8em 0 0 rgba(0, 170, 255, 0.7), 2.5em 0 0 0 ${p => p.theme.COLORS.BG_BRAND},
        1.75em 1.75em 0 0 rgba(0, 170, 255, 0.2), 0 2.5em 0 0 rgba(0, 170, 255, 0.2), -1.8em 1.8em 0 0 rgba(0, 170, 255, 0.2), -2.6em 0 0 0 rgba(0, 170, 255, 0.2),
        -1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2);
    }

    37.5% {
      box-shadow: 0 -2.6em 0 0 rgba(0, 170, 255, 0.2), 1.8em -1.8em 0 0 rgba(0, 170, 255, 0.5), 2.5em 0 0 0 rgba(0, 170, 255, 0.7),
        1.75em 1.75em 0 0 ${p => p.theme.COLORS.BG_BRAND}, 0 2.5em 0 0 rgba(0, 170, 255, 0.2), -1.8em 1.8em 0 0 rgba(0, 170, 255, 0.2), -2.6em 0 0 0 rgba(0, 170, 255, 0.2),
        -1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2);
    }

    50% {
      box-shadow: 0 -2.6em 0 0 rgba(0, 170, 255, 0.2), 1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2), 2.5em 0 0 0 rgba(0, 170, 255, 0.5), 1.75em 1.75em 0 0 rgba(0, 170, 255, 0.7),
        0 2.5em 0 0 ${p => p.theme.COLORS.BG_BRAND}, -1.8em 1.8em 0 0 rgba(0, 170, 255, 0.2), -2.6em 0 0 0 rgba(0, 170, 255, 0.2), -1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2);
    }

    62.5% {
      box-shadow: 0 -2.6em 0 0 rgba(0, 170, 255, 0.2), 1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2), 2.5em 0 0 0 rgba(0, 170, 255, 0.2), 1.75em 1.75em 0 0 rgba(0, 170, 255, 0.5),
        0 2.5em 0 0 rgba(0, 170, 255, 0.7), -1.8em 1.8em 0 0 ${p => p.theme.COLORS.BG_BRAND}, -2.6em 0 0 0 rgba(0, 170, 255, 0.2), -1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2);
    }

    75% {
      box-shadow: 0 -2.6em 0 0 rgba(0, 170, 255, 0.2), 1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2), 2.5em 0 0 0 rgba(0, 170, 255, 0.2), 1.75em 1.75em 0 0 rgba(0, 170, 255, 0.2),
        0 2.5em 0 0 rgba(0, 170, 255, 0.5), -1.8em 1.8em 0 0 rgba(0, 170, 255, 0.7), -2.6em 0 0 0 ${p => p.theme.COLORS.BG_BRAND}, -1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2);
    }

    87.5% {
      box-shadow: 0 -2.6em 0 0 rgba(0, 170, 255, 0.2), 1.8em -1.8em 0 0 rgba(0, 170, 255, 0.2), 2.5em 0 0 0 rgba(0, 170, 255, 0.2), 1.75em 1.75em 0 0 rgba(0, 170, 255, 0.2),
        0 2.5em 0 0 rgba(0, 170, 255, 0.2), -1.8em 1.8em 0 0 rgba(0, 170, 255, 0.5), -2.6em 0 0 0 rgba(0, 170, 255, 0.7), -1.8em -1.8em 0 0 ${p => p.theme.COLORS.BG_BRAND};
    }
  }
`;
