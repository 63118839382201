import React from "react";
import { css } from "@emotion/core";
import styled from "styles/theme";

const LoadingComponent: React.FC<ILoadingItem> = ({ comment }) => {
  return (
    <Loading className={comment ? "comment" : ""}>
      {!comment && <PickArea />}
      <Author>
        <Photo />
        <AuthorInfoWrap>
          <AuthorInfo />
          <AuthorInfo />
        </AuthorInfoWrap>
      </Author>
      {!comment && (
        <Content>
          <TextArea>
            <TextBar />
            <TextBar />
            <TextBar />
          </TextArea>
          <Thum />
        </Content>
      )}
    </Loading>
  );
};

LoadingComponent.defaultProps = {};

export default LoadingComponent;

const LOADING_ANIMATION = css`
  background: linear-gradient(270deg, #fafafa, #efefef);
  background-size: 400% 400%;
  animation: Gradient 1.5s ease infinite;

  @keyframes Gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
`;

const Loading = styled.div`
  padding: 20px;

  &.comment {
    border-top: 0 none;
  }

  & + div {
    border-top: 4px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  }
`;

const PickArea = styled.div`
  width: 70px;
  height: 6px;
  ${LOADING_ANIMATION};
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const Photo = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 100px;
  ${LOADING_ANIMATION};
`;

const AuthorInfoWrap = styled.div`
  margin-left: 10px;
`;

const AuthorInfo = styled.div`
  width: 70px;
  height: 6px;
  ${LOADING_ANIMATION};

  + div {
    margin-top: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% - 85px);
`;

const TextBar = styled.div`
  width: 100%;
  height: 10px;
  ${LOADING_ANIMATION};

  &:first-of-type + div {
    margin-top: 10px;
    width: calc(100% - 100px);
  }

  &:last-of-type {
    margin-top: 24px;
  }
`;

const Thum = styled.div`
  flex-basis: 70px;
  margin-left: 15px;
  width: 70px;
  height: 65px;
  border-radius: 6px;
  ${LOADING_ANIMATION};
`;
