import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconArrow02 = ({ width, height, color, direction }: IconArrow) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      {direction === "top" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.89215 3.63068L1.99994 6.52289L2.63063 7.15358L5.52284 4.26136L8.41504 7.15356L9.04572 6.52287L5.52284 2.99999L5.52282 3L4.89215 3.63068Z"
          fill={color ? color : ThemeUse.ICONS.ARROW02.ONE}
        />
      )}
      {direction === "right" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.19235 5.29293L4.94971 2.05029L4.2426 2.7574L7.48524 6.00004L4.2426 9.24268L4.94971 9.94979L8.19235 6.70715L8.89945 6.00004L8.19235 5.29293Z"
          fill={color ? color : ThemeUse.ICONS.ARROW02.ONE}
        />
      )}
      {direction === "bottom" && (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.39954 4.4071L5.44979 8.35685L4.74268 7.64974L8.69243 3.7L9.39954 4.4071Z"
            fill={color ? color : ThemeUse.ICONS.ARROW02.ONE}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.15686 7.64975L5.44975 8.35686L1.50001 4.40711L2.20711 3.7L6.15686 7.64975Z"
            fill={color ? color : ThemeUse.ICONS.ARROW02.ONE}
          />
        </>
      )}
      {direction === "left" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.70713 5.2929L6.94977 2.05026L7.65687 2.75737L4.41423 6.00001L7.65687 9.24265L6.94977 9.94976L3.70713 6.70712L3.00002 6.00001L3.70713 5.2929Z"
          fill={color ? color : ThemeUse.ICONS.ARROW02.ONE}
        />
      )}
    </svg>
  );
};

IconArrow02.defaultProps = {
  width: 11,
  height: 11,
  direction: "top"
};

export default IconArrow02;
