import { gql } from "apollo-boost";

export const USER_RESPONSE_FRAGMENT = gql`
  fragment UserResponse on User {
    id
    email
    name
    picture
    representativeGroupName
  }
`;

export const CATEGORY_RESPONSE_FRAGMENT = gql`
  fragment CategoryResponse on Category {
    id
    title
    allowComments
    canWriteComments
    allowAttachmentFileDownload
  }
`;

export const FILE_RESPONSE_FRAGMENT = gql`
  fragment FileResponse on File {
    url
    type
    size
    name
  }
`;

export const ME_POST_RESPONSE_FRAGMENT = gql`
  fragment MePostResponse on UserPostActivity {
    posted
    liked
    scraped
    reported
  }
`;

export const COMMENT_RESPONSE_FRAGMENT = gql`
  ${FILE_RESPONSE_FRAGMENT}

  fragment CommentResponse on Comment {
    id
    post {
      id
    }
    author {
      id
      name
      picture
      representativeGroupName
    }
    content
    createdAt
    picked
    blockedBy
    likeCount
    files {
      ...FileResponse
    }
    me {
      commented
      liked
      reported
    }
  }
`;

export const POST_RESPONSE_FRAGMENT = gql`
  ${CATEGORY_RESPONSE_FRAGMENT}
  ${FILE_RESPONSE_FRAGMENT}
  ${ME_POST_RESPONSE_FRAGMENT}

  fragment PostResponse on Post {
    id
    category {
      ...CategoryResponse
    }
    author {
      id
      name
      picture
      representativeGroupName
    }
    title
    content
    files {
      ...FileResponse
    }
    createdAt
    updatedAt
    picked
    secreted
    restricted
    hitCount
    likeCount
    blockedBy
    scrapeCount
    me {
      ...MePostResponse
    }
  }
`;

export const SEARCH_POSTS = gql`
  ${POST_RESPONSE_FRAGMENT}
  ${COMMENT_RESPONSE_FRAGMENT}

  query searchPosts($categoryId: ID, $query: String!, $pageInfo: PagingInfo, $options: SearchPostsOptions) {
    searchPosts(categoryId: $categoryId, query: $query, pageInfo: $pageInfo, options: $options) {
      nextToken
      totalCount
      items {
        highlight {
          title
          content
        }
        source {
          ...PostResponse
          comments {
            totalCount
            items {
              ...CommentResponse
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_GROUP = gql`
  ${CATEGORY_RESPONSE_FRAGMENT}

  query getCategoryGroup($id: ID) {
    getCategoryGroup(id: $id) {
      categories {
        items {
          ...CategoryResponse
          canWritePosts
          allowSecrets
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query getCategory($id: ID) {
    getCategory(id: $id) {
      id
      title
      allowSecrets
      canWritePosts
      canWriteComments
      parent {
        id
      }
    }
  }
`;

export const GET_CATEGORY_POST = gql`
  ${POST_RESPONSE_FRAGMENT}
  ${COMMENT_RESPONSE_FRAGMENT}

  query getCategoryPost($id: ID, $limit: Int, $nextToken: String, $options: CategoryPostsOptions) {
    getCategory(id: $id) {
      ...CategoryResponse
      canWritePosts
      allowSecrets
      parent {
        id
      }
      posts(limit: $limit, nextToken: $nextToken, options: $options) {
        nextToken
        totalCount
        items {
          ...PostResponse
          comments(limit: 2) {
            totalCount
            items {
              ...CommentResponse
            }
          }
        }
      }
    }
  }
`;

export const GET_CATEGORY_POST_CHECK = gql`
  query getCategoryPostCheck($id: ID, $limit: Int, $options: CategoryPostsOptions) {
    getCategory(id: $id) {
      posts(limit: $limit, options: $options) {
        items {
          id
        }
      }
    }
  }
`;

export const GET_POST = gql`
  ${POST_RESPONSE_FRAGMENT}

  query getPost($id: ID!) {
    getPost(id: $id) {
      ...PostResponse
      comments {
        totalCount
      }
    }
  }
`;

export const LIST_COMMENTS = gql`
  ${COMMENT_RESPONSE_FRAGMENT}

  query listComments($postId: ID!, $pageInfo: PagingInfo, $options: PostCommentsOptions) {
    listComments(postId: $postId, pageInfo: $pageInfo, options: $options) {
      nextToken
      totalCount
      items {
        ...CommentResponse
      }
    }
  }
`;

export const GET_COMMENT = gql`
  ${FILE_RESPONSE_FRAGMENT}

  query getComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      createdAt
      files {
        ...FileResponse
      }
    }
  }
`;

export const SEARCH_TAGS = gql`
  query searchTags($query: String!) {
    searchTags(query: $query) {
      text
      count
    }
  }
`;

export const VIEW_POST = gql`
  mutation viewPost($id: ID!) {
    viewPost(id: $id) {
      hitCount
    }
  }
`;

export const CREATE_POST = gql`
  mutation createPost($categoryId: ID!, $input: CreatePostInput!) {
    createPost(categoryId: $categoryId, input: $input) {
      id
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost($id: ID!, $input: UpdatePostInput!) {
    updatePost(id: $id, input: $input) {
      id
    }
  }
`;

export const REMOVE_POST = gql`
  mutation removePost($id: ID!) {
    removePost(id: $id) {
      id
    }
  }
`;

export const TOGGLE_LIKE_POST = gql`
  mutation toggleLikePost($id: ID!) {
    toggleLikePost(id: $id) {
      id
      likeCount
      me {
        liked
      }
    }
  }
`;

export const TOGGLE_SCRAPE_POST = gql`
  mutation toggleScrapePost($id: ID!) {
    toggleScrapePost(id: $id) {
      id
      scrapeCount
      me {
        scraped
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  ${COMMENT_RESPONSE_FRAGMENT}

  mutation createComment($postId: ID!, $input: CreateCommentInput!) {
    createComment(postId: $postId, input: $input) {
      ...CommentResponse
    }
  }
`;

export const UPDATE_COMMENT = gql`
  ${COMMENT_RESPONSE_FRAGMENT}

  mutation updateComment($id: ID!, $input: UpdateCommentInput!) {
    updateComment(id: $id, input: $input) {
      ...CommentResponse
    }
  }
`;

export const REMOVE_COMMENT = gql`
  mutation removeComment($id: ID!) {
    removeComment(id: $id) {
      id
    }
  }
`;

export const TOGGLE_LIKE_COMMENT = gql`
  mutation toggleLikeComment($id: ID!) {
    toggleLikeComment(id: $id) {
      id
      likeCount
      me {
        liked
      }
    }
  }
`;

export const REPORT_POST = gql`
  mutation reportPost($id: ID!, $input: ReportPostInput!) {
    reportPost(id: $id, input: $input) {
      id
      me {
        reported
      }
    }
  }
`;

export const CANCEL_REPORT_POST = gql`
  mutation cancelReportOnPost($id: ID!) {
    cancelReportOnPost(id: $id) {
      id
      me {
        reported
      }
    }
  }
`;

export const REPORT_COMMENT = gql`
  mutation reportComment($id: ID!, $input: ReportCommentInput!) {
    reportComment(id: $id, input: $input) {
      id
      me {
        reported
      }
    }
  }
`;

export const CANCEL_REPORT_COMMENT = gql`
  mutation cancelReportOnComment($id: ID!) {
    cancelReportOnComment(id: $id) {
      id
      me {
        reported
      }
    }
  }
`;

export const GET_MODIFY_WRITE = gql`
  ${FILE_RESPONSE_FRAGMENT}

  query getModifyWrite($id: ID!) {
    getPost(id: $id) {
      id
      category {
        id
        title
        allowSecrets
      }
      title
      content
      files {
        ...FileResponse
      }
      secreted
    }
  }
`;

export const GET_USER_WRITTEN = gql`
  ${USER_RESPONSE_FRAGMENT}
  ${POST_RESPONSE_FRAGMENT}

  query getUser($id: ID!, $limit: Int, $nextToken: String) {
    getUser(id: $id) {
      ...UserResponse
      writtenPosts(limit: $limit, nextToken: $nextToken) {
        nextToken
        totalCount
        items {
          ...PostResponse
          comments {
            totalCount
          }
        }
      }
    }
  }
`;

export const GET_USER_SCRAPED = gql`
  ${USER_RESPONSE_FRAGMENT}
  ${POST_RESPONSE_FRAGMENT}

  query getUser($id: ID!, $limit: Int, $nextToken: String) {
    getUser(id: $id) {
      ...UserResponse
      scrapedPosts(limit: $limit, nextToken: $nextToken) {
        nextToken
        totalCount
        items {
          ...PostResponse
          comments {
            totalCount
          }
        }
      }
    }
  }
`;

export const GET_USER_LIKED = gql`
  ${USER_RESPONSE_FRAGMENT}
  ${POST_RESPONSE_FRAGMENT}

  query getUser($id: ID!, $limit: Int, $nextToken: String) {
    getUser(id: $id) {
      ...UserResponse
      likedPosts(limit: $limit, nextToken: $nextToken) {
        nextToken
        totalCount
        items {
          ...PostResponse
          comments {
            totalCount
          }
        }
      }
    }
  }
`;
