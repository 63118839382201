import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconView = ({ width, height, color, type }: IconView) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      {type === "feed" ? (
        <>
          <rect x="12" y="15.5" width="16" height="4" rx="1" fill={color ? color : ThemeUse.ICONS.VIEW.ONE} />
          <rect x="12" y="21.5" width="16" height="4" rx="1" fill={color ? color : ThemeUse.ICONS.VIEW.ONE} />
        </>
      ) : (
        <>
          <rect x="12" y="15.5" width="2" height="2" rx="1" fill={color ? color : ThemeUse.ICONS.VIEW.ONE} />
          <rect x="15" y="15.5" width="13" height="2" rx="1" fill={color ? color : ThemeUse.ICONS.VIEW.ONE} />
          <rect x="12" y="19.5" width="2" height="2" rx="1" fill={color ? color : ThemeUse.ICONS.VIEW.ONE} />
          <rect x="15" y="19.5" width="13" height="2" rx="1" fill={color ? color : ThemeUse.ICONS.VIEW.ONE} />
          <rect x="12" y="23.5" width="2" height="2" rx="1" fill={color ? color : ThemeUse.ICONS.VIEW.ONE} />
          <rect x="15" y="23.5" width="13" height="2" rx="1" fill={color ? color : ThemeUse.ICONS.VIEW.ONE} />
        </>
      )}
    </svg>
  );
};

IconView.defaultProps = {
  width: 40,
  height: 40,
  type: "feed"
};

export default IconView;
