const common = {
  decodeHTML: (html: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  },
  scrollGet: (type: string, categoryId?: string) => {
    return window.localStorage.getItem(`${type}/${categoryId || ""}`);
  },
  scrollSave: (type: string, categoryId?: string) => {
    window.localStorage.setItem(`${type}/${categoryId || ""}`, window.scrollY.toString());
  },
  scrollRemove: (type: string, categoryId?: string | null) => {
    window.localStorage.removeItem(`${type}/${categoryId || ""}`);
  },
  agent: () => {
    const OS = ["Touchclass iOS", "Windows", "iPhone OS", "iPad OS", "Macintosh", "Linux"];
    const Browser = ["Edge", "Firefox", "Chrome", "Safari"];

    const { userAgent } = window.navigator;

    const userOS = OS.filter(item => userAgent.indexOf(item) > -1)[0];
    const userBrowser = Browser.filter(item => userAgent.indexOf(item) > -1)[0];

    alert(userOS);
    return [userOS, userBrowser];
  }
};

export default common;
