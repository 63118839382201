import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconLike = ({ width, height, color, active }: IconLike) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0994 6.46393L10.5 7.00038L10.9006 6.46393C11.5617 5.57875 12.6367 5 13.8553 5C15.8812 5 17.5 6.59454 17.5 8.5329C17.5 9.50401 17.0962 10.3851 16.4374 11.0262C16.4374 11.0262 16.4374 11.0262 16.4373 11.0262L10.5 16.8024L4.56266 11.0262C3.90382 10.3851 3.5 9.50402 3.5 8.5329C3.5 6.59454 5.11882 5 7.14474 5C8.3633 5 9.43834 5.57875 10.0994 6.46393Z"
        stroke={color ? color : ThemeUse.COLORS.EL_LIKE}
        fill={active ? (color ? color : ThemeUse.COLORS.EL_LIKE) : "none"}
      />
    </svg>
  );
};

IconLike.defaultProps = {
  width: 20,
  height: 20,
  active: false
};

export default IconLike;
