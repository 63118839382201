import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconEmptyZero = ({ width, height, video, overlay }: IconThum) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      {video ? (
        <>
          <rect y="0.5" width={width} height={height} rx="4" fill={overlay ? overlay : ThemeUse.COLORS.BG_OVERLAY3} />
          <path d="M18 9L10.7691 13.0674L10 13.5V4.5L18 9Z" fill={ThemeUse.COLORS.WHITE} />
        </>
      ) : (
        <>
          <rect y="0.5" width={width} height={height} rx="4" fill={overlay ? overlay : ThemeUse.COLORS.BG_OVERLAY3} />
          <path
            d="M7.24609 9.5H6.30078V4.93359L4.90625 5.41016V4.60938L7.125 3.79297H7.24609V9.5ZM11.4766 6.37109H12.9609V7.23828H11.4766V8.92969H10.5547V7.23828H9.0625V6.37109H10.5547V4.78906H11.4766V6.37109Z"
            fill={ThemeUse.COLORS.WHITE}
          />
        </>
      )}
    </svg>
  );
};

IconEmptyZero.defaultProps = {
  width: 26,
  height: 18
};

export default IconEmptyZero;
