import React from "react";
// import { Link } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";

import Photo from "components/User/Photo";

import { getCookieState } from "apollo/LocalState";

const TopWriteComponent: React.FC<ITopWrite> = ({ src, name, id, setWrite, history }) => {
  const { t } = useTranslation("Board");

  const { cpId } = getCookieState;

  const handleWrite = () => {
    setWrite({
      postId: undefined,
      writeMode: true
    });
  };

  const moveToProfile = () => {
    history.push(`/${cpId ? `${cpId}/` : ""}board/profile/${id}?type=writtenPosts`);
  };

  return (
    // <TopWrite to={`/board/${categoryId ? `${categoryId}/` : ""}write`}>
    <TopWrite>
      <Photo size="sm" src={src} alt={name} onClick={moveToProfile} />
      <Explain onClick={handleWrite}>{t("writeNewPost")}</Explain>
    </TopWrite>
  );
};

TopWriteComponent.defaultProps = {};

export default TopWriteComponent;

// const TopWrite = styled(Link)`
const TopWrite = styled.button`
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 54px;
  border-bottom: 4px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  text-decoration: none !important;
  text-align: left;
`;

const Explain = styled.span`
  ${p => p.theme.TEXTS.TXT_PLACEHOLDER_NEW};
  margin-left: 11px;
  flex-basis: 100%;
  padding: 5px 16px 6px;
  height: 34px;
  font-weight: 500;
  border: 1px solid ${p => p.theme.COLORS.BORDER_LIGHT01};
  border-radius: 30px;
`;
