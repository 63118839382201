import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";
const IconMenu = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 25C21.5899 25 24.5 22.0899 24.5 18.5C24.5 14.9101 21.5899 12 18 12C14.4101 12 11.5 14.9101 11.5 18.5C11.5 22.0899 14.4101 25 18 25ZM23.1017 24.6624C21.717 25.8101 19.9391 26.5 18 26.5C13.5817 26.5 10 22.9183 10 18.5C10 14.0817 13.5817 10.5 18 10.5C22.4183 10.5 26 14.0817 26 18.5C26 20.4391 25.3101 22.217 24.1624 23.6018L29 28.4394C29.2929 28.7323 29.2929 29.2072 29 29.5001C28.7071 29.7929 28.2322 29.7929 27.9393 29.5001L23.1017 24.6624Z"
        fill={color ? color : ThemeUse.COLORS.BG_BASE}
      />
    </svg>
  );
};

IconMenu.defaultProps = {
  width: 40,
  height: 40
};

export default IconMenu;
