import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import styled from "styles/theme";

import Header from "components/Common/Header";
import Write from "components/Board/Write";

import { ROOT } from "api/Client";
import { ME } from "api/User";
import LiveStatusBar from "components/Common/LiveStatusBar";
import { appNative } from "utils";

export default (Component: React.FC<RouteComponentProps>) => (props: RouteComponentProps) => {
  const [writeMode, setWriteMode] = useState<boolean>(false);
  const [writeInfo, setWriteInfo] = useState<IWrite>({ postId: undefined });
  const [live, setLive] = useState<boolean>(false);
  const [categoryData, setCategoryData] = useState<ICategoryData>();
  const { loading } = useQuery(ROOT);
  const { data: userData, loading: userLoading } = useQuery(ME);

  // if (error?.message && cookie.get("id_token") && cookie.get("refresh_token")) {
  //   console.log(error?.message);
  //   error.message.indexOf("401") > 0 && auth.refreshToken(cookie.get("refresh_token"));
  // }

  const me = userData ? userData.me : {};

  if (writeMode) {
    document.body.classList.add("scroll-lock");
  } else {
    document.body.classList.remove("scroll-lock");
  }

  const handleWrite = ({ postId, categoryId, writeMode }: IWriteMode) => {
    setWriteInfo({
      postId,
      categoryId
    });

    if (writeMode || writeMode === false) {
      setWriteMode(writeMode);
    }
  };

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname.includes("/board") && !writeMode) {
      appNative.checkPage("post");
    } else {
      appNative.checkPage("etc");
    }
  }, [writeMode]);

  useEffect(() => {
    // TODO 반응이 느려서 좀 더 빠르게 체크 가능한 곳에서 체크를 해야할거 같음 or Loading 상태에는 화면 바로 보여주지 않게 container에서 분기처리
    // if (!loading && data.me && data.me.id === null) history.push("/social");
  }, [loading, userLoading]);

  return (
    <Layout>
      {!writeMode && <LiveStatusBar setLive={setLive} />}
      <HeaderWrap writeMode={writeMode}>
        <Header {...props} live={live} categoryData={categoryData} setWrite={handleWrite} />
      </HeaderWrap>
      <Container>
        <Component
          {...props}
          live={live}
          categoryData={categoryData}
          setCategoryData={setCategoryData}
          setWrite={handleWrite}
          setWriteInfo={setWriteInfo}
          setWriteMode={setWriteMode}
          user={me || {}}
        />
      </Container>
      {writeMode && <Write {...props} userId={me.id} categoryData={categoryData} {...writeInfo} setWrite={handleWrite} />}
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin: 0 auto;
  max-width: 650px;
  min-height: 100vh;
  background-color: ${p => p.theme.COLORS.BG_BODY};
`;

const HeaderWrap = styled.div<{ writeMode: boolean }>`
  position: relative;
  order: 0;
  width: 100%;
  height: 42px;
  z-index: ${p => (p.writeMode ? 10 : 12)};
`;

const Container = styled.div`
  order: 1;
  width: 100%;
`;
