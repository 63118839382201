import { ApolloClient, ApolloLink } from "apollo-boost";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import cookie from "js-cookie";
import auth from "./auth";
import { defaults, resolvers, getCookieState } from "./LocalState";
import { getGroupNameStatus } from "api/rest/touchclass";

auth.getTitle();
getGroupNameStatus();

const { siteId, cpId } = getCookieState;

const httpLink = createHttpLink({
  uri: `https://api.${siteId}.touchtogether.com/${cpId ? `${cpId}/` : ""}graphql`
});

const authLink = setContext(async (_, { headers }) => {
  const token = (await auth.getIdToken()) as string;

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    // console.log(graphQLErrors, "graphQLErrors");
    for (const err of graphQLErrors) {
      // console.log(err, "err");
      switch (err.message.toLocaleUpperCase()) {
        case "FORBIDDEN":
        case "UNAUTHORIZED":
          cookie.remove("access_token");

          if (!window.location.href.includes("login")) {
            window.location.replace(window.location.origin + `${cpId ? `/${cpId}` : ""}/login`);
          }

          break;
        // error code is set to UNAUTHENTICATED
        // when AuthenticationError thrown in resolver
        // modify the operation context with a new token

        // eslint-disable-next-line no-case-declarations
        // const token = auth.getIdToken();
        // eslint-disable-next-line no-case-declarations
        // const oldHeaders = operation.getContext().headers;

        // operation.setContext({
        //   headers: {
        //     ...oldHeaders,
        //     authorization: token
        //   }
        // });
        // retry the request, returning the new observable
        // return forward(operation);
      }
    }
  }
  if (networkError) {
    // console.log(`[Network error]: ${networkError}`);
    // if you would also like to retry automatically on
    // network errors, we recommend that you use
    // apollo-link-retry
  }
});

const httpAuthLink = authLink.concat(httpLink);

const link = ApolloLink.from([errorLink, httpAuthLink]);

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    const { search, href } = window.location;

    if (search.includes("keyword")) {
      object = {
        ...object,
        __typename: "SearchList"
      };
    }

    if (href.includes("/profile/")) {
      const type = search.includes("type") ? search.split("=").pop() : "writtenPosts";

      // eslint-disable-next-line
      if (type && (object as any)[type]) {
        object = {
          ...object,
          __typename: type
        };
      }
    }

    return defaultDataIdFromObject(object);
  }
});

cache.writeData({
  data: defaults
});

export default new ApolloClient({
  link,
  cache,
  resolvers
});
