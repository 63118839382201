import React, { useState, useEffect, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import { changeLang } from "locales";

import cookie from "js-cookie";
import queryString from "query-string";

import { Button, Modal } from "styles";

import Category from "./Category";

import { appNative } from "utils";

import { IconMenu, IconArrow02, IconPost, IconSearch, IconBack } from "assets/icon/svg";

import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

import { getCookieState } from "apollo/LocalState";

const LOGOUT = gql`
  mutation userLogout {
    userLogout @client
  }
`;

const HeaderComponent: React.FC<IHeader> = ({ categoryData, setWrite, live, ...props }) => {
  const { siteId, cpId, view } = getCookieState;
  const DEV_STATUS = siteId === "dev" || siteId === "test";

  const { t } = useTranslation("Board");

  const { userAgent } = window.navigator;

  const { search } = props.location;
  const { categoryId, postId } = props.match.params;

  const queryPath = queryString.parse(search);

  if (userAgent.includes("Touchclass Android")) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.onTogether?.viewTypeChange(queryPath.viewBy);
  }

  const [logout] = useMutation(LOGOUT);

  const handleLogout = () => {
    logout();
  };

  const [menu, setMenu] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const [ctgModal, setCtgModal] = useState<CategoryModalType>("close");

  const handleLeftMenu = (event: globalThis.MouseEvent) => {
    event.stopPropagation();
    const dom = menuRef.current;
    if (dom) {
      const rect = dom.getBoundingClientRect();

      if (!(rect.top - 84 < event.clientY && rect.bottom > event.clientY && rect.left < event.clientX && rect.right > event.clientX)) setMenu(false);
    }
  };

  const handleMenu = () => {
    if (userAgent.includes("Touchclass")) {
      appNative.close(queryPath.viewBy as string);
    } else {
      setMenu(!menu);
      // handleLogout();
    }
  };

  const handleChangeLang = (lang: string) => {
    changeLang(lang);
    window.location.reload();
  };

  const handleWrite = () => {
    if (!categoryId) {
      setCtgModal("write");
      return;
    }
    setWrite({
      postId,
      writeMode: true
    });
  };

  const handleCtgForWrite = (categoryId: string) => {
    setCtgModal("close");
    setWrite({
      postId,
      categoryId,
      writeMode: true
    });
  };

  const handleCtgModal = (value: CategoryModalType) => {
    setCtgModal(value);
    if (value) {
      document.querySelector("#live-status-bar")?.classList.add("deep");
    } else {
      document.querySelector("#live-status-bar")?.classList.remove("deep");
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleLeftMenu);
    return () => window.removeEventListener("click", handleLeftMenu);
  }, []);

  const langChangeNav = [
    {
      name: "한국어",
      lang: "ko-KR"
    },
    {
      name: "영어",
      lang: "en-US"
    },
    {
      name: "일본어",
      lang: "ja-JP"
    },
    {
      name: "인도네시아",
      lang: "id-ID"
    },
    {
      name: "베트남어",
      lang: "vi-VN"
    }
  ];

  return (
    <Header id="header" live={live}>
      <HeaderWrap>
        <Lnb>
          {(userAgent.indexOf("Touchclass") > -1 || DEV_STATUS) && (
            <Menu onClick={handleMenu}>
              {userAgent.indexOf("Touchclass") > -1 && view !== "tab" ? <IconBack size={"l"} /> : userAgent.indexOf("Touchclass") > -1 && view === "tab" ? "" : ""}
            </Menu>
          )}
          {menu && (
            <MoreOption ref={menuRef}>
              <MenuScroll>
                {langChangeNav.map((item, index) => (
                  <button key={index} type="button" onClick={() => handleChangeLang(item.lang)}>
                    {item.name}
                  </button>
                ))}
              </MenuScroll>
              <Button type="button" round onClick={handleLogout}>
                로그아웃
              </Button>
            </MoreOption>
          )}
        </Lnb>
        <Title>
          <button type="button" onClick={() => handleCtgModal("read")}>
            <span>{categoryId ? categoryData?.title : t("all")}</span>
            <IconArrow02 direction={"bottom"} />
          </button>
        </Title>
        <Rnb>
          <Link to={`/${cpId ? `${cpId}/` : ""}board${categoryId ? `/${categoryId}` : ""}/search`} onClick={() => cookie.remove("searchType")}>
            <IconSearch />
          </Link>
          {categoryData?.canWritePosts && (
            <button type="button" onClick={handleWrite}>
              <IconPost />
            </button>
          )}
        </Rnb>
      </HeaderWrap>
      <Modal show={ctgModal !== "close"} view={"content"} dimClose={() => handleCtgModal("close")}>
        <Category
          categoryGroupId={categoryData?.parent.id}
          closeCallback={() => handleCtgModal("close")}
          handleCtg={ctgModal === "write" ? handleCtgForWrite : undefined}
          writeMode={ctgModal === "write"}
        />
      </Modal>
    </Header>
  );
};

export default withRouter(HeaderComponent);

const Header = styled.header<ILive>`
  position: fixed;
  top: ${p => (p.live ? 42 : 0)}px;
  left: 0;
  width: 100%;
  height: 42px;
  background-color: ${p => p.theme.COLORS.BORDER_REGULAR};
`;

const HeaderWrap = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding: 8px 13px 10px 10px;
  max-width: 650px;
  height: 42px;
  border-bottom: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  background-color: ${p => p.theme.COLORS.BG_BODY};
`;

const Lnb = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 10;
`;

const Rnb = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 13px;

  & a {
    vertical-align: top;
  }

  & button {
    padding: 0;
    height: 40px;
  }
`;

const Title = styled.h2<IErrorPass>`
  text-align: center;

  & button {
    ${p => p.theme.TEXTS.TOP_TITLE};
    padding: 0;
    margin: 0 auto;
    width: 70%;
    color: ${p => p.theme.COLORS.TEXT_BRAND};
    vertical-align: top;

    & span {
      display: inline-block;
      max-width: calc(100% - 20px);
      height: 24px;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: top;
      overflow: hidden;
    }

    & svg {
      margin-left: 5px;
    }
  }

  & select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const Menu = styled.button`
  position: relative;
  padding: 0;
  z-index: 10;
`;

const MoreOption = styled.div<IErrorPass>`
  position: fixed;
  top: 42px;
  left: 0;
  padding: 10px 0;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  background: ${p => p.theme.COLORS.BG_REGULAR};
  z-index: 10;

  & button,
  & a {
    ${p => p.theme.TEXTS.CONTROL_TXT}
    display: flex;
    justify-content: center;
    padding: 1px 7px 2px;
    width: 100%;
    min-height: 32px;
    font-weight: 400;
    word-break: keep-all;
    text-align: center;
  }
`;

const MenuScroll = styled.div`
  margin-bottom: 10px;
  height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
`;
