import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";
const IconMenu = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="17" y="30.5001" width="14" height="1" fill={ThemeUse.COLORS.BG_BASE} />
      <path
        d="M25.6516 11.1014C26.5303 10.2227 27.9549 10.2227 28.8336 11.1014C29.7123 11.9801 29.7123 13.4047 28.8336 14.2834L13.5702 29.5469C12.7731 30.3439 11.6921 30.7917 10.565 30.7917L9.14338 30.7917L9.14338 29.3701C9.14338 28.2429 9.59115 27.1619 10.3882 26.3649L25.6516 11.1014Z"
        stroke={color ? color : ThemeUse.COLORS.BG_BASE}
        strokeWidth="1.5"
        fill="none"
      />
    </svg>
  );
};

IconMenu.defaultProps = {
  width: 40,
  height: 40
};

export default IconMenu;
