const regex = {
  htmlTag: /(<([^>]+)>)/gi,
  br: /(<br ?\/?>)|&nbsp;/gi,
  video: /<(\/video|video)([^>]*)>/gi,
  image: /<(\/img|img)([^>]*)>/gi,
  link: /(https?:\/\/)[^\s]+[.][^\s]+|(www.)[^\s]+[.][^\s]+/g,
  hashTag: /[#][^(?!.|,|;|:|~|'|"|”|’|!|!|+|<|>|@|#|$|%|^|&|*|\\{|\\}|\\[|\]|\\/|\\|\-|\\=|\\`)\s]+/g,
  fileName: /[\\{\\}\\[\]\\/?,;:|\\)*~`!^\-_+<>@\\#$%&\\\\=\\(\\'\\"]/gi,
  keywordCheck: /[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-+<>@\\#$%&\\\\=\\(\\'\\"]/gi,
  specialCharacters: /[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\\\=\\(\\'\\"]/gi,
  space: /ㅤ/gi,
  uFEFF: /\uFEFF/g,
  mention: /<span class="mention"(.*?)>(.*?)<\/span>/gi,
  highlight: /<em>(.*?)<\/em>/gi
};

export default regex;
