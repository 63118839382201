import React from "react";
import { useTranslation } from "react-i18next";

export type ReportType = "comment" | "post" | "user";
interface Props {
  me: boolean;
  type: ReportType;
  reported: boolean;
  blockedBy?: string;
  onWrite?: () => void;
  onRemove?: () => void;
  onReport?: () => void;
  onCancelReport?: () => void;
  onBlock?: () => void;
  onCancelBock?: () => void;
}

const SettingMenuButtons = ({ onWrite, onRemove }: Pick<Props, "onWrite" | "onRemove">) => {
  const { t } = useTranslation("Detail");
  return (
    <>
      <button type="button" onClick={onWrite}>
        {t("edit")}
      </button>
      <button
        type="button"
        onClick={() => {
          onRemove && onRemove();
        }}
      >
        {t("delete")}
      </button>
    </>
  );
};

const ReportMenuButtons = ({ type, onBlock, onReport, blockedBy, reported, onCancelBock }: Omit<Props, "me" | "onWrite" | "onRemove">) => {
  const { t } = useTranslation("Report");

  const blockFn = () => {
    switch (blockedBy) {
      case "user":
        onCancelBock && onCancelBock();
        break;

      case "admin":
        break;
      default:
        onBlock && onBlock();
    }
  };
  const blockTitle = () => {
    switch (blockedBy) {
      case "user":
        return "unblock";
      case "admin":
        return "unblock";
      default:
        return "block author";
    }
  };

  const reportTitle = () => {
    switch (type) {
      case "comment":
        return "Report a comment";
      case "post":
        return "Report a post";
    }
    return "report";
  };

  return (
    <>
      {!reported && !blockedBy && (
        <button
          type="button"
          onClick={() => {
            onReport && onReport();
          }}
        >
          {t(reportTitle())}
        </button>
      )}
      {onBlock && (
        <button type="button" onClick={blockFn}>
          {t(blockTitle())}
        </button>
      )}

      {/*<button*/}
      {/*  type="button"*/}
      {/*  onClick={() => {*/}
      {/*    reported ? onCancelReport() : onReport();*/}
      {/*    //onReport();*/}
      {/*  }}*/}
      {/*>*/}
      {/*  {"테스트 신고해제"}*/}
      {/*</button>*/}
    </>
  );
};

export const MenuButtons = (props: Props) => {
  return props.me ? <SettingMenuButtons {...props} /> : <ReportMenuButtons {...props} />;
};
