import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconNew = ({ width, height }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5" r="5" fill={ThemeUse.COLORS.BG_POINT} />
      <path d="M3.61806 7.5H3V3.5H3.60764L6.32639 6.61296H6.38194V3.5H7V7.5H6.39236L3.67361 4.39813H3.61806V7.5Z" fill={ThemeUse.COLORS.WHITE} />
      <defs>
        <linearGradient id="paint0_linear" x1="5" y1="3.5" x2="5" y2="7.5" gradientUnits="userSpaceOnUse">
          <stop stopColor={ThemeUse.COLORS.WHITE} />
          <stop offset="1" stopColor={ThemeUse.COLORS.WHITE} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

IconNew.defaultProps = {
  width: 10,
  height: 10
};

export default IconNew;
