import React, { useEffect, useRef, useState } from "react";
import withLayout from "layouts/EmptyLayout";
import { IconBack, IconClose, IconMore } from "assets/icon/svg";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import { Tab, Title, Modal } from "styles";
import Photo from "components/User/Photo";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER_WRITTEN, GET_USER_SCRAPED, GET_USER_LIKED } from "api/Board";
import { LoadingItem, Item, Empty } from "components/Board";
import queryString from "query-string";
import ImageSlider from "components/Common/ImageSlider";

import { getCookieState } from "apollo/LocalState";
import { decodeEntity } from "../../utils/string";
import { MenuButtons } from "../../components/Common/MenuButtons";

let loadConfirm = true;
let nextToken: string | undefined | null;
let moreLoad: boolean;

const ProfileView: React.FC<RouteComponentProps> = ({ user, match, location, history, live }) => {
  const { t } = useTranslation("Profile");
  const { hash } = window.location;
  const [more, setMore] = useState<boolean>(false);
  const [modal, setModal] = useState<boolean>(false);
  const moreRef = useRef<HTMLDivElement>(null);
  const postWrapRef = useRef<HTMLDivElement>(null);
  const { cpId } = getCookieState;
  const { params } = match;
  const { search } = location;
  const queryPath = queryString.parse(search);
  const sortByType = queryPath.type as IPostType;

  const [type, setType] = useState<IPostType>(sortByType || "writtenPosts");
  const [dim, setDim] = useState<boolean>(true);

  const me = params?.userId === user.id;

  const tabData: ITabDta[] = me
    ? [
        { title: t("writtenPosts"), type: "writtenPosts" },
        { title: t("scrapedPosts"), type: "scrapedPosts" }
      ]
    : [];

  const returnToken = () => {
    switch (type) {
      case "writtenPosts":
        return data?.getUser.writtenPosts?.nextToken;
      case "scrapedPosts":
        return data?.getUser.scrapedPosts?.nextToken;
      case "likedPosts":
        return data?.getUser.scrapedPosts.nextToken;
      default:
        return "";
    }
  };

  const GET_QUERY = type === "writtenPosts" ? GET_USER_WRITTEN : type === "scrapedPosts" ? GET_USER_SCRAPED : GET_USER_LIKED;

  const { data, loading, fetchMore } = useQuery(GET_QUERY, {
    variables: { id: params?.userId, limit: 20, nextToken: null },
    fetchPolicy: "cache-first",
    onCompleted: data => {
      const resultCount = document.getElementById("profile-result-count");
      if (resultCount && data.getUser.writtenPosts) {
        resultCount.innerText = data.getUser.writtenPosts.totalCount;
      }
    }
  });

  moreLoad = loading;
  nextToken = returnToken();
  loadConfirm = !!returnToken();

  const handleProfileImage = () => {
    if (data?.getUser.picture) {
      setModal(true);
      document.querySelector("#live-status-bar")?.classList.add("deep");
      history.push(`#picture`);
    }
  };

  const handleClose = () => {
    history.push(`/${cpId ? `${cpId}/` : ""}board`);
  };

  const handleTab = (type: IPostType) => {
    setType(type);
    history.replace(`/${cpId ? `${cpId}/` : ""}board/profile/${params.userId}?type=${type}`);
    window.scrollTo(0, 0);
  };

  const moreEvent = (event: MouseEvent) => {
    event.stopPropagation();
    const dom = moreRef.current;
    if (dom) {
      const rect = dom.getBoundingClientRect();
      if (!(rect.top - 84 < event.clientY && rect.bottom > event.clientY && rect.left < event.clientX && rect.right > event.clientX)) {
        setMore(false);
      }
    }
  };

  const handleMoveToProfile = () => {
    setType("writtenPosts");
  };

  const toggleModalHeader = () => {
    setDim(!dim);
  };

  const closeModal = () => {
    setModal(false);
    document.querySelector("#live-status-bar")?.classList.remove("deep");
  };

  const loadingCount = ["", "", "", "", "", "", "", "", ""];

  useEffect(() => {
    const handlePostAdd = () => {
      const postListWrap = postWrapRef.current;

      if (!moreLoad && postListWrap && nextToken) {
        const triggerElement = postListWrap.children[postListWrap.children.length - 10];
        if (triggerElement) {
          const triggerRect = triggerElement.getBoundingClientRect();
          if (triggerRect.y < 10 && loadConfirm) {
            loadConfirm = false;
            fetchMore({
              variables: {
                id: params?.userId,
                limit: 20,
                nextToken: nextToken
              },
              updateQuery: (prev: IGetUserData, { fetchMoreResult }) => {
                if (!fetchMoreResult || prev.getUser[type].totalCount === prev.getUser[type].items.length) return prev;
                nextToken = fetchMoreResult.getUser[type].nextToken;
                loadConfirm = true;

                return {
                  getUser: {
                    ...prev.getUser,
                    [type]: {
                      ...fetchMoreResult.getUser[type],
                      nextToken: fetchMoreResult.getUser[type].nextToken,
                      totalCount: prev.getUser[type].totalCount,
                      items: [...prev.getUser[type].items, ...fetchMoreResult.getUser[type].items]
                    }
                  }
                };
              }
            });
          }
        }
      }
    };
    window.addEventListener("click", moreEvent);
    window.addEventListener("scroll", handlePostAdd);
    window.addEventListener("popstate", closeModal);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("click", moreEvent);
      window.removeEventListener("scroll", handlePostAdd);
      window.removeEventListener("popstate", closeModal);
    };
  }, [fetchMore, type, sortByType, params]);

  return (
    <Profile live={live}>
      <Top live={live}>
        <TopWrap>
          <Lnb>
            <Menu onClick={handleClose}>
              <IconBack size={"l"} />
            </Menu>
          </Lnb>
          <PageTitle>{t("profile")}</PageTitle>
          {!me && (
            <More onClick={() => setMore(!more)}>
              <IconMore size={"l"} />
            </More>
          )}
          {more && (
            <MoreOption ref={moreRef} style={{ minWidth: "100px" }}>
              <MenuButtons
                me={false}
                type={"user"}
                reported={false}
                onReport={() => {
                  history.push(`/${cpId ? `${cpId}/` : ""}board/report/user/${user.id}`);
                }}
              />
            </MoreOption>
          )}
        </TopWrap>
      </Top>
      <ProfileWrap>
        <Photo onClick={handleProfileImage} size="lg" src={data?.getUser.picture} />
        <ProfileInfo>
          <Title shape="H3">{data?.getUser.name}</Title>
          {data?.getUser.representativeGroupName && (
            <LabelWrap>
              <Label>{decodeEntity(data?.getUser.representativeGroupName)}</Label>
            </LabelWrap>
          )}
        </ProfileInfo>
      </ProfileWrap>
      <Tab justify>
        {tabData.map((item, index) => (
          <button className={type === item.type ? "active" : ""} onClick={() => handleTab(item.type)} key={index}>
            {item.title}
          </button>
        ))}
      </Tab>
      <ListWrap>
        <ResultCount className={type === "writtenPosts" ? "show" : ""} dangerouslySetInnerHTML={{ __html: t("resultCount") }} />
        {loading && (
          <PostListWrap>
            {loadingCount.map((item, index) => (
              <LoadingItem key={index}>{item}</LoadingItem>
            ))}
          </PostListWrap>
        )}
        {data?.getUser[type]?.items.length > 0 && (
          <PostListWrap ref={postWrapRef}>
            {data.getUser[type].items.map((item: IPost, index: number) => (
              <Item key={index} {...item} handleMoveToProfile={handleMoveToProfile} isProfile />
            ))}
            {loadConfirm && <LoadingItem />}
          </PostListWrap>
        )}
        {type === "writtenPosts" && data?.getUser.writtenPosts?.items.length === 0 && <Empty text={t("noWrittenPosts")} />}
        {type === "scrapedPosts" && data?.getUser.scrapedPosts?.items.length === 0 && <Empty text={t("noScrapedPosts")} />}
      </ListWrap>
      {data?.getUser.picture && hash === `#picture` && (
        <Modal deep show={modal} dimClose={toggleModalHeader}>
          {dim && (
            <CloseButton onClick={closeModal}>
              <IconClose size={"l"} color={"#FFF"} />
            </CloseButton>
          )}
          <ImageSlider files={[{ url: data?.getUser.picture, name: data?.getUser.name }]} onClick={toggleModalHeader} />
        </Modal>
      )}
    </Profile>
  );
};
export default withLayout(ProfileView);

const Profile = styled.div<ILive>`
  position: relative;
  top: ${p => (p.live ? 84 : 42)}px;
  width: 100%;
  background: ${p => p.theme.COLORS.BG_BODY};
  z-index: 11;
`;

const Top = styled.header<ILive>`
  position: fixed;
  top: ${p => (p.live ? 42 : 0)}px;
  left: 0;
  width: 100%;
  height: 42px;
  z-index: 3;
`;

const TopWrap = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 8px 10px 10px 10px;
  width: 100%;
  max-width: 650px;
  height: 42px;
  background-color: ${p => p.theme.COLORS.BG_BODY};
  z-index: 3;
`;

const Lnb = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
`;

const More = styled.button`
  position: absolute;
  top: 0;
  right: 13px;
  align-items: center;
  padding: 0;
  height: 40px;
`;

const MoreOption = styled.div`
  position: absolute;
  top: 40px;
  right: 5px;
  padding: 10px 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  background: ${p => p.theme.COLORS.BG_REGULAR};
  z-index: 2;

  & button,
  & a {
    ${p => p.theme.TEXTS.CONTROL_TXT}
    display: flex;
    justify-content: center;
    padding: 1px 7px 2px;
    width: 100%;
    min-height: 32px;
    font-weight: 400;
    word-break: keep-all;
    text-align: center;
  }
`;

const Menu = styled.button`
  padding: 0;
`;

const PageTitle = styled.h2`
  ${p => p.theme.TEXTS.TOP_TITLE};
  color: ${p => p.theme.COLORS.TEXT_REGULAR};
  text-align: center;
`;

const ProfileWrap = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 20px 18px 28px;

  & + div {
    position: sticky;
    top: 42px;
    background-color: ${p => p.theme.COLORS.BG_BODY};
    z-index: 3;
  }
`;

const ProfileInfo = styled.div`
  display: inline-block;
  padding-left: 18px;
`;

const LabelWrap = styled.div`
  display: inline-block;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px 4px;
  border: 1px solid #5a5b5c;
  background: ${p => p.theme.COLORS.BG_REGULAR};
`;

const Label = styled.span`
  display: flex;
  align-items: center;
  line-height: 1.2;
  color: #5a5b5c;
  font-size: ${p => p.theme.SIZE.n12};
  text-align: center;
`;

const ListWrap = styled.div``;

const ResultCount = styled.div`
  display: none;
  padding: 13px 20px;
  line-height: 1.2;
  color: #999;
  font-size: ${p => p.theme.SIZE.n11};
  border-bottom: 1px solid #efefef;

  strong {
    line-height: ${p => p.theme.SIZE.n14};
    color: #333;
    font-size: ${p => p.theme.SIZE.n12};
    font-weight: 500;
  }

  &.show {
    display: block;
  }
`;

const PostListWrap = styled.div`
  padding-bottom: 120px;
  overflow-x: hidden;
`;

const CloseButton = styled.button`
  position: fixed;
  top: 0;
  left: 10px;
  margin: 0;
  padding: 0;
  z-index: 10;

  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: ${p => p.theme.COLORS.BG_OVERLAY1};
    z-index: 2;
  }

  & svg {
    position: relative;
    z-index: 5;
  }
`;
