import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconArrow01 = ({ width, height, color, direction }: IconArrow) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      {direction === "top" && <path d="M 15.5 12 L 9.03917 6 L 2.5 12" fill="none" stroke={color ? color : ThemeUse.ICONS.ARROW01.ONE} />}
      {direction === "right" && <path d="M6.07189 3L12 9.46083L6 16" fill="none" stroke={color ? color : ThemeUse.ICONS.ARROW01.ONE} />}
      {direction === "bottom" && <path d="M2.5 6.57189L8.96083 12.5L15.5 6.5" fill="none" stroke={color ? color : ThemeUse.ICONS.ARROW01.ONE} />}
      {direction === "left" && <path d="M11.9281 3L6 9.46083L12 16" fill="none" stroke={color ? color : ThemeUse.ICONS.ARROW01.ONE} />}
    </svg>
  );
};

IconArrow01.defaultProps = {
  width: 18,
  height: 18,
  direction: "top"
};

export default IconArrow01;
