const tooltipUitl = {
  insideFix: () => {
    const qlTooltipElm = document.getElementsByClassName("ql-tooltip")[0] as HTMLDivElement;
    const qlTooltipClass = qlTooltipElm.getAttribute("class");
    const qlTooltipHiddenCheck = qlTooltipClass?.indexOf("ql-hidden") as number;

    if (qlTooltipHiddenCheck < 0) {
      setTimeout(() => {
        const tooltipLeft = Number(qlTooltipElm.style.left.split("px")[0]);
        if (tooltipLeft < 1) qlTooltipElm.style.left = "20px";
      }, 1);
    }
  }
};

// const qlEditor = document.getElementsByClassName("ql-editor")[0] as HTMLDivElement;
// const qlMentionList = document.getElementsByClassName("ql-mention-list-container")[0] as HTMLDivElement;

// if (qlMentionList) {
//   console.log(qlMentionList, "qlMentionList");
//   setTimeout(() => {
//     const editorWidth = qlEditor.clientWidth;
//     const mentionListWidth = qlMentionList.clientWidth;
//     const mentionListLeft = Number(qlMentionList.style.left.split("px")[0]);
//     if (editorWidth < mentionListWidth + mentionListLeft) {
//       qlMentionList.style.left = "auto";
//       qlMentionList.style.right = "10px";
//     }
//   }, 1);
// }

export default tooltipUitl;
