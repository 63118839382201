import { Modal } from "../../styles";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { BLOCK_USER, CANCEL_BLOCK_USER } from "../../api/User";
import { CANCEL_REPORT_POST, GET_CATEGORY_POST } from "../../api/Board";
import { useTranslation } from "react-i18next";

interface Props {
  id?: string;
  userName?: string;
  userId?: string;
  isComment?: boolean;
  cancelReportModal: boolean;
  blockModal: boolean;
  cancelBlockModal: boolean;
  onCancelReportModal: (is: boolean) => void;
  onBlockModal: (is: boolean) => void;
  onCancelBlockModal: (is: boolean) => void;
}

export const ReportModal = ({ id, userId, userName, cancelReportModal, blockModal, cancelBlockModal, onBlockModal, onCancelReportModal, onCancelBlockModal }: Props) => {
  const { t } = useTranslation("Report");

  const [cancelReportPostMutation] = useMutation(CANCEL_REPORT_POST, {
    onCompleted: () => {
      onCancelReportModal(false);
    }
  });

  const refetchQueries = [{ query: GET_CATEGORY_POST }, "getCategory"];

  const [blockUserMutation] = useMutation(BLOCK_USER, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      onBlockModal(false);
    }
  });

  const [cancelBlockUserMutation] = useMutation(CANCEL_BLOCK_USER, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      onCancelBlockModal(false);
    }
  });

  const handleCancelReport = () => {
    cancelReportPostMutation({
      variables: {
        id
      }
    });
  };

  const handleCancelBlock = () => {
    void cancelBlockUserMutation({
      variables: {
        userId: userId
      }
    });
  };

  const handleBlock = () => {
    void blockUserMutation({
      variables: {
        userId: userId
      }
    });
  };

  return (
    <>
      <Modal
        show={cancelReportModal}
        view={"alert"}
        title={""}
        text={t("Are you sure you want to unreport?")}
        confirmText={t("ok")}
        confirm={handleCancelReport}
        cancelText={t("cancel")}
        cancel={() => onCancelReportModal(!cancelReportModal)}
        dimClose={() => onCancelReportModal(!cancelReportModal)}
      />
      <Modal
        show={blockModal}
        view={"alert"}
        title={""}
        text={t("block description")}
        confirm={handleBlock}
        confirmText={t("to block")}
        cancelText={t("cancel")}
        cancel={() => onBlockModal(!blockModal)}
        dimClose={() => onBlockModal(!blockModal)}
      />
      <Modal
        show={cancelBlockModal}
        view={"alert"}
        title={userName}
        text={t("Would you like to unblock?")}
        confirmText={t("ok")}
        confirm={handleCancelBlock}
        cancelText={t("cancel")}
        cancel={() => onCancelBlockModal(!cancelBlockModal)}
        dimClose={() => onCancelBlockModal(!cancelBlockModal)}
      />
    </>
  );
};
