import IconEmptySearch from "./IconEmptySearch";
import IconEmptyZero from "./IconEmptyZero";
import IconEmptyCheck from "./IconEmptyCheck";
import IconEmptyTrash from "./IconEmptyTrash";
import IconEmptyPencil from "./IconEmptyPencil";
import IconEmptyComment from "./IconEmptyComment";
import IconEmptyAlert from "./IconEmptyAlert";
import IconThum from "./IconThum";
import IconFile from "./IconFile";
import IconArrow01 from "./IconArrow01";
import IconArrow02 from "./IconArrow02";
import IconClose from "./IconClose";
import IconLock from "./IconLock";
import IconEye from "./IconEye";
import IconClip from "./IconClip";
import IconNew from "./IconNew";
import IconPick from "./IconPick";
import IconWriteEl from "./IconWriteEl";
import IconReport from "./IconReport";
import IconLike from "./IconLike";
import IconScrape from "./IconScrape";
import IconComment from "./IconComment";
import IconCircle from "./IconCircle";
import IconMenu from "./IconMenu";
import IconPost from "./IconPost";
import IconSearch from "./IconSearch";
import IconBack from "./IconBack";
import IconMore from "./IconMore";
import IconView from "./IconView";
import IconSend from "./IconSend";
import IconVideo from "./IconVideo";
import IconLoading from "./IconLoading";
import IconDownload from "./IconDownload";
import IconPrev from "assets/icon/svg/IconPrev";
import IconNext from "assets/icon/svg/IconNext";
import IconEmptyCancel from "assets/icon/svg/IconEmptyCancel";
import IconEllipticalArrow from "assets/icon/svg/IconEllipticalArrow";

const IconSvg = {
  EmptySearch: IconEmptySearch,
  EmptyZero: IconEmptyZero,
  EmptyCheck: IconEmptyCheck,
  EmptyTrash: IconEmptyTrash,
  EmptyPencil: IconEmptyPencil,
  EmptyComment: IconEmptyComment,
  EmptyAlert: IconEmptyAlert,
  Thum: IconThum,
  File: IconFile,
  Arrow01: IconArrow01,
  Arrow02: IconArrow02,
  Close: IconClose,
  Lock: IconLock,
  Eye: IconEye,
  Clip: IconClip,
  New: IconNew,
  Pick: IconPick,
  WriteEl: IconWriteEl,
  Report: IconReport,
  Like: IconLike,
  Scrape: IconScrape,
  Comment: IconComment,
  Circle: IconCircle,
  Menu: IconMenu,
  Post: IconPost,
  Search: IconSearch,
  More: IconMore,
  View: IconView,
  Send: IconSend,
  Video: IconVideo,
  Loading: IconLoading,
  Download: IconDownload,
  Prev: IconPrev,
  Next: IconNext,
  Cancel: IconEmptyCancel,
  EllipticalArrow: IconEllipticalArrow
};

export {
  IconEmptySearch,
  IconEmptyZero,
  IconEmptyCheck,
  IconEmptyTrash,
  IconEmptyPencil,
  IconEmptyComment,
  IconEmptyAlert,
  IconThum,
  IconFile,
  IconArrow01,
  IconArrow02,
  IconClose,
  IconLock,
  IconClip,
  IconEye,
  IconNew,
  IconPick,
  IconWriteEl,
  IconReport,
  IconLike,
  IconScrape,
  IconComment,
  IconCircle,
  IconMenu,
  IconPost,
  IconSearch,
  IconBack,
  IconMore,
  IconView,
  IconSend,
  IconVideo,
  IconLoading,
  IconDownload,
  IconPrev,
  IconNext,
  IconEmptyCancel,
  IconEllipticalArrow
};

export default IconSvg;
