import React from "react";
import styled from "styles/theme";

export default (Component: React.FC<RouteBaseComponentProps>) => (props: RouteBaseComponentProps) => {
  return (
    <Layout>
      <Component {...props} />
    </Layout>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  margin: 0 auto;
  max-width: 650px;
  min-height: 100vh;
  background-color: ${p => p.theme.COLORS.BG_BODY};
`;
