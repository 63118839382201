import React from "react";

const IconInfo = ({ width, height }: IconBasic) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_6567_45783)">
        <path
          d="M7.33331 11.3333H8.66665V7.33331H7.33331V11.3333ZM7.99998 1.33331C4.31998 1.33331 1.33331 4.31998 1.33331 7.99998C1.33331 11.68 4.31998 14.6666 7.99998 14.6666C11.68 14.6666 14.6666 11.68 14.6666 7.99998C14.6666 4.31998 11.68 1.33331 7.99998 1.33331ZM7.99998 13.3333C5.05998 13.3333 2.66665 10.94 2.66665 7.99998C2.66665 5.05998 5.05998 2.66665 7.99998 2.66665C10.94 2.66665 13.3333 5.05998 13.3333 7.99998C13.3333 10.94 10.94 13.3333 7.99998 13.3333ZM7.33331 5.99998H8.66665V4.66665H7.33331V5.99998Z"
          fill="#C2C2C2"
        />
      </g>
      <defs>
        <clipPath id="clip0_6567_45783">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconInfo.defaultProps = {
  width: 16,
  height: 16,
  active: false
};

export default IconInfo;
