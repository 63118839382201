import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconSend = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.3265 23.5258C33.0123 23.9068 33.0123 24.8931 32.3265 25.2742L16.0052 34.3416C15.2576 34.7569 14.3674 34.1031 14.5401 33.2655L16.1844 25.291L25.448 24.4L16.1844 23.2742L14.5529 15.5457C14.3758 14.7068 15.2675 14.0486 16.017 14.465L32.3265 23.5258Z"
        fill={color ? color : ThemeUse.COLORS.BG_BRAND}
      />
    </svg>
  );
};

IconSend.defaultProps = {
  width: 48,
  height: 48
};

export default IconSend;
