export default {
  createPost: "Tạo bài đăng",
  editPost: "Chỉnh sửa bài đăng",
  confirm: "Bài đăng",
  enterTitle: "Nhập tiêu đề.",
  warningTitle: "Bạn có thể nhập ít hơn 100 ký tự tiếng Anh.",
  enterContent: "Thêm văn bản hoặc chèn phương tiện.",
  privateMake: "Đặt bài đăng ở chế độ riêng tư",
  success: "Thành công!",
  successPostText: "Bài đăng của bạn đã được tải lên.",
  successPostText2: "Bài đăng của bạn đã được chỉnh sửa.",
  ok: "OK",
  uploading: "Đang tải lên...",
  cancel: "Hủy bỏ",
  done: "Xong",
  edit: "Chỉnh sửa",
  delete: "Xóa",
  deleteText: "Bạn có chắc chắn muốn <br />xóa bài đăng này?",
  yes: "Có",
  dataCellular: "Dữ liệu di động",
  dataCellularText: "Bạn có thể phải trả thêm phí khi<br/> sử dụng dữ liệu qua mạng di động. <br/> Bạn có muốn tiếp tục?",
  lock: "Đăng ở chế độ riêng tư",
  unlock: "Công khai",
  selectCategory: `Hãy lựa chọn phân loại.`,
  wantToCancelWrite: "Bạn có muốn thoát không?",
  cancelWrite: "Thoát",
  changePublicPost: "Đã thay đổi thành 'bài đăng công khai' theo cài đặt chủ đề.",
  changePrivatePost: "Nó đã được đổi thành 'bài bí mật' theo cài đặt chủ đề."
};
