export default {
  comments: "komentar",
  commentCount: `<strong id="comment-count"></strong>&nbsp;komentar`,
  commentPost: "Posting",
  leaveComment: "Tulis komentar.",
  permissionComment: "Anda tidak memiliki izin untuk meninggalkan komentar.",
  sizeOptionWord: "A",
  edit: "Edit",
  delete: "Hapus",
  deleteText: "Yakin ingin <br />menghapus postingan ini?",
  yes: "Ya",
  cancel: "Batal",
  image: "Gambar",
  video: "Video",
  postedComment: "Komentar Anda telah diposting.",
  report: "Lapor",
  submit: "Kirim",
  Submitted: "Dikirim",
  reportAdminText: "Admin akan meninjau laporan Anda dan menanganinya sesuai peraturan perusahaan.",
  reportReason: "Tuliskan alasan Anda untuk melaporkan dalam kurang dari 150 karakter.",
  reportPostText: "Postingan ini telah dilaporkan.",
  ok: "OK",
  editComment: "Edit komentar",
  save: "Simpan",
  firstCommentText: "Jadilah yang pertama berkomentar.",
  newest: "Terbaru",
  mostLiked: "Paling banyak disukai",
  scrape: "Diarsipkan",
  scrapeOff: "Tidak diarsipkan",
  errorPost: "Postingan tidak ada atau sudah dihapus. <br />Buka Lihat semua.",
  readPermission: "You don't have permission to view this post. <br />Go to list of all posts.",
  notProvideFileViewer: "This topic is Do not allow downloads.",
  filePreview: "File Preview",
  NotSupportedFilePreview: "Not supported for preview"
};
