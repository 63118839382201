import React, { useEffect, useRef, useState } from "react";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import styled from "@emotion/styled";
import IconPrev from "assets/icon/svg/IconPrev";
import IconNext from "assets/icon/svg/IconNext";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

interface ImageProps {
  url?: string;
  name?: string;
  isMobile?: boolean;
}
const ImageComponent = (props: ImageProps) => {
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);
  useEffect(() => {
    updatePosition();
  });

  const updatePosition = () => {
    if (transformComponentRef.current) {
      const { centerView } = transformComponentRef.current;
      centerView(1, 0, "easeOut");
    }
  };

  return (
    <TransformWrapper initialScale={1} ref={transformComponentRef}>
      <TransformComponent wrapperStyle={{ width: "100vw", height: "100vh" }}>
        <IMG
          isMobile={props.isMobile}
          src={props.url}
          alt={props.name}
          onLoad={() => {
            updatePosition();
          }}
        />
      </TransformComponent>
    </TransformWrapper>
  );
};

const ImageSlider: React.FC<IImageSlider> = ({ onClick, imageIndex, setImageIndex, element = "glide", files }) => {
  const [slider] = useState<Glide | null>(new Glide(`.${element}`, { type: "slider", rewind: false }));
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  const [isMobile, setIsMobile] = useState<boolean>();

  const prev = () => {
    slider?.go("<");
  };
  const next = () => {
    slider?.go(">");
  };

  useEffect(() => {
    if (slider && files.length > 1) {
      slider.update({ startAt: imageIndex });
      if (isFirstRender) {
        slider.mount();
        slider.on(["swipe.end", "move.after"], () => {
          setImageIndex(slider.index);
        });
        setIsFirstRender(false);
      }
    }

    const width = document.body.clientWidth;
    if (navigator.userAgent.includes("Mobi") || navigator.userAgent.includes("Touchclass") || width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    document.body.classList.add("scroll-lock");

    return () => {
      document.body.classList.remove("scroll-lock");
    };
  }, [slider, setImageIndex, imageIndex, isFirstRender, isMobile, files.length]);

  return (
    <>
      {files.length > 1 && (
        <Wrap className={element}>
          <GlideTrack data-glide-el="track" className="glide__track">
            <UL className="glide__slides" onClick={onClick}>
              {files.map(item => (
                <LI key={item.url} className="glide__slide">
                  {/*<IMG isMobile={isMobile} src={item.url} alt={item.name} />*/}
                  <ImageComponent isMobile={isMobile} url={item.url} name={item.name} />
                </LI>
              ))}
            </UL>
          </GlideTrack>
          {!isMobile && files.length > 1 && (
            <>
              {slider && slider.index !== 0 && (
                <Button className="slider-prev" onClick={prev}>
                  <IconPrev />
                </Button>
              )}
              {imageIndex !== files.length - 1 && (
                <Button className="slider-next" onClick={next}>
                  <IconNext />
                </Button>
              )}
            </>
          )}
        </Wrap>
      )}
      {files.length === 1 && <ImageComponent isMobile={isMobile} url={files[0].url} name={files[0].name} />}
    </>
  );
};

export default ImageSlider;

const Wrap = styled.div``;

const GlideTrack = styled.div`
  width: 100vw;
`;

const UL = styled.ul`
  align-items: center;
`;

const LI = styled.li``;

const IMG = styled.img<ISliderImageStyle>`
  display: block;
  margin: 0 auto;
  max-width: ${p => (p.isMobile ? "100vw" : "70vw")};
  max-height: 80vh;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  padding: 0;
  color: transparent;
  transform: translateY(-50%);

  &.slider-prev {
    left: 20px;
  }

  &.slider-next {
    right: 20px;
  }
`;
