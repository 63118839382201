/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import styled from "styles/theme";

const ButtonComponent: React.FC<IButtonProps> = ({ type, sizes, variant, text, outline, round, to, target, children, ...props }) => {
  return (
    <>
      {to ? (
        to.indexOf("http") >= 0 ? (
          <AnchorOut href={to} target={target} sizes={sizes} variant={variant} round={round} outline={outline} text={text}>
            {children}
            <span className="active-span" />
          </AnchorOut>
        ) : (
          <Anchor to={to} target={target} sizes={sizes} variant={variant} round={round} outline={outline} text={text}>
            {children}
            <span className="active-span" />
          </Anchor>
        )
      ) : (
        <Button type={type} sizes={sizes} variant={variant} round={round} outline={outline} text={text} {...props}>
          {children}
          <span className="active-span" />
        </Button>
      )}
    </>
  );
};

ButtonComponent.defaultProps = {
  type: "button",
  sizes: "MEDIUM",
  variant: "PRIMARY",
  text: false,
  outline: false,
  round: false
};

export default ButtonComponent;

const initStyle = css`
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 13px 10px;
  min-width: 208px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: all 0.08s ease-in;

  &:focus {
    text-decoration: none;

    > .active-span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;

      ::after {
        content: "";
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 25px / 50px;
        background-color: #fff;
        opacity: 0.54;
        animation: BTN_ACTIVE 0.5s;
      }
    }

    @keyframes BTN_ACTIVE {
      from {
        width: 0;
      }

      to {
        width: 100%;
      }
    }
  }

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }
`;

const ROUND_STYLE = css`
  border-radius: 25px;
`;

const Button = styled.button<IButtonStyle>`
  ${p => p.theme.BUTTONS[p.variant ? p.variant : "PRIMARY"]}
  ${p => p.round && ROUND_STYLE};
  ${initStyle};
`;

const Anchor = styled(Link)<IButtonStyle>`
  ${p => p.theme.BUTTONS[p.variant ? p.variant : "PRIMARY"]}
  ${p => p.round && ROUND_STYLE};
  ${initStyle};
`;

const AnchorOut = styled.a<IButtonStyle>`
  ${p => p.theme.BUTTONS[p.variant ? p.variant : "PRIMARY"]}
  ${p => p.round && ROUND_STYLE};
  ${initStyle};
`;
