interface IBounceRefetch {
  [key: string]: any;
}

let bounceTarget: HTMLDivElement | undefined;
let scrollY = 0;
let scrollStart = 0;
let scrollMove = 0;
let moveResult = 0;
let callBack: Function | undefined;

const bounceRefetch: IBounceRefetch = {
  refetch: undefined,
  start: function(event: TouchEvent, target: HTMLDivElement, refetch: Function) {
    scrollY = window.scrollY;
    bounceTarget = target;
    callBack = refetch;

    if (scrollY < 1) {
      scrollStart = event.targetTouches[0].clientY;
      scrollMove = event.targetTouches[0].screenY;
    }
  },
  move: function(event: TouchEvent) {
    if (scrollY < 1 && scrollStart < event.targetTouches[0].clientY) {
      if (bounceTarget) {
        document.body.classList.add("scroll-lock");
        const firstItem = bounceTarget.querySelector("div");
        moveResult = scrollMove * -1 + event.targetTouches[0].screenY;
        moveResult = (1 + moveResult / 10) * 0.8;

        firstItem?.setAttribute("style", `margin-top: ${moveResult > 200 ? 200 : moveResult < 0 ? 0 : moveResult}%;`);
      }
    }
  },
  end: function(event: TouchEvent) {
    const writeOn = document.getElementById("write-on");

    if (scrollY <= 0) {
      event.changedTouches[0].clientY - scrollStart > 150 && !writeOn && moveResult > 15 && callBack && callBack();
    }

    if (bounceTarget) {
      const firstItem = bounceTarget.querySelector("div");
      firstItem?.removeAttribute("style");
    }
    bounceTarget = undefined;
    scrollStart = 0;
    scrollMove = 0;
    moveResult = 0;
    callBack = undefined;
    !writeOn && document.body.classList.remove("scroll-lock");
  }
};

// export { BounceRefetch };
export default bounceRefetch;
