import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconPrev = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <path d="M53 69L27 40.1194L52.9778 11" stroke={color ? color : ThemeUse.COLORS.WHITE} strokeWidth="3" />
        <path d="M53 69L27 40.1194L52.9778 11" stroke={color ? color : ThemeUse.COLORS.WHITE} strokeWidth="3" />
      </g>
      <defs>
        <filter id="filter0_d" x="16.9858" y="6.00146" width="45.129" height="76.0022" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
        <linearGradient id="paint0_linear" x1="53" y1="40" x2="27" y2="40" gradientUnits="userSpaceOnUse">
          <stop stopColor={ThemeUse.COLORS.WHITE} />
          <stop offset="1" stopColor={ThemeUse.COLORS.WHITE} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

IconPrev.defaultProps = {
  width: 80,
  height: 80
};

export default IconPrev;
