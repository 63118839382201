import React, { useState, useEffect, useRef } from "react";
import styled, { theme as themeCur } from "styles/theme";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import cookie from "js-cookie";
import withLayout from "layouts/EmptyLayout";
import { Tab, Modal } from "styles";
import Category from "components/Common/Category";
import { Empty, Item, LoadingItem } from "components/Board";
import { IconBack, IconClose, IconSearch, IconArrow01 } from "assets/icon/svg";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { SEARCH_POSTS, SEARCH_TAGS, GET_CATEGORY_POST, GET_CATEGORY } from "api/Board";
import { css } from "@emotion/core";

let loadConfirm = true;
let nextToken: string | undefined | null;
let moreLoad: boolean;

const SearchView: React.FC<RouteComponentProps> = ({ match, location, history, live }) => {
  const { t } = useTranslation("Search");
  const { search, pathname } = location;
  const { params } = match;
  const { keyword, type } = queryString.parse(search);
  const [searchType, setSearchType] = useState<string>((type as string) || "TITLE_CONTENT");
  const [categoryId, setCategoryId] = useState<string | undefined>(params.categoryId);
  const [ctgModal, setCtgModal] = useState<boolean>(false);
  const [tags, setTags] = useState<any[]>([]);

  if (categoryId !== params.categoryId) {
    setCategoryId(params.categoryId);
  }

  const postWrapRef = useRef<HTMLDivElement>(null);

  const [searchTags] = useLazyQuery(SEARCH_TAGS, {
    fetchPolicy: "network-only",
    onCompleted: data => {
      if (data) {
        const getTags = data.searchTags.reduce((acc: any[], cur: any) => {
          const result = acc;
          result.push({
            id: `${cur.count}`,
            value: `${cur.text}`
          });
          return result;
        }, []);

        setTags(getTags);
      }
    }
  });

  const { data: popularData, loading: popularLoding } = useQuery(GET_CATEGORY_POST, {
    variables: { id: categoryId, limit: 5, nextToken: null, options: { sortBy: "POPULARITY" } },
    fetchPolicy: "cache-first"
  });

  const { data: searchData, loading: searchLoding, refetch, fetchMore } = useQuery(SEARCH_POSTS, {
    variables: { query: keyword, categoryId, pageInfo: { limit: 20, nextToken: null }, options: { searchType } },
    fetchPolicy: "cache-first",
    onCompleted: data => {
      if (data.searchPosts) {
        const resultCount = document.getElementById("result-count");
        if (resultCount) {
          resultCount.innerText = data.searchPosts.totalCount;
        }
      }
    }
  });

  const { data: categoryData } = useQuery(GET_CATEGORY, {
    variables: { id: categoryId }
  });

  if (keyword) {
    moreLoad = searchLoding;
    nextToken = searchData?.searchPosts.nextToken;
    loadConfirm = searchData?.searchPosts.nextToken ? true : false;
  }

  const tabData = [
    {
      title: t("titleContent"),
      searchType: "TITLE_CONTENT"
    },
    {
      title: t("title"),
      searchType: "TITLE"
    },
    {
      title: t("author"),
      searchType: "AUTHOR"
    },
    {
      title: t("tag"),
      searchType: "TAG"
    }
  ];

  const loadingCount = ["", "", "", "", "", "", "", "", ""];

  if (keyword && searchData?.searchPosts.items.length === 0) {
    document.body.classList.add("scroll-lock");
  } else {
    document.body.classList.remove("scroll-lock");
  }

  const handleClose = () => {
    // if (userAgent.includes("Touchclass")) {
    //   const appData = {
    //     type: "search",
    //     method: "close"
    //   };

    //   appNative.closeData(appData);
    // } 해시태그 업데이트시에 삭제

    window.history.go(-1);
    cookie.remove("searchType");
  };

  const handleEnter = () => {
    const { value } = document.getElementById("search-input") as HTMLInputElement;

    if (value.length > 0) {
      const tagCheck = value.indexOf("#") === 0 ? true : false;

      const searchValue = tagCheck ? value.substr(1) : value;

      setSearchType(tagCheck ? "TAG" : searchType);
      setTags([]);

      refetch({ query: searchValue, categoryId, pageInfo: { limit: 20, nextToken: null }, options: { searchType: tagCheck ? "TAG" : searchType } });

      history.replace(`${pathname}?keyword=${searchValue}&type=${tagCheck ? "TAG" : searchType}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const { key } = e;

    if (key === "Enter") {
      handleEnter();
    }

    const tagsEl = document.getElementById("tag-list");
    if (tagsEl) {
      const tagsCount = tagsEl.childNodes.length - 1;
      const activeEl = tagsEl.querySelector(".active");
      const activeElIndex: number = activeEl ? Array.from(activeEl!.parentNode!.children).indexOf(activeEl) : -1;

      const tagActiveMove = (move: number) => {
        const afterActiveEl = tagsEl.childNodes[activeElIndex + move] as HTMLButtonElement;
        activeEl?.classList.remove("active");
        afterActiveEl.classList!.add("active");

        tagsEl.scrollTop = afterActiveEl.offsetTop + afterActiveEl.clientHeight - tagsEl.clientHeight;

        const searchInputEl = document.getElementById("search-input") as HTMLInputElement;
        searchInputEl.value = `#${afterActiveEl.value}`;
      };

      switch (key) {
        case "ArrowUp": {
          const moveUp = activeElIndex === -1 ? tagsCount + 1 : activeElIndex - 1 < 0 ? tagsCount : -1;
          tagActiveMove(moveUp);
          break;
        }
        case "ArrowDown": {
          const moveDown = activeElIndex + 1 > tagsCount ? -activeElIndex : 1;
          tagActiveMove(moveDown);
          break;
        }
      }
    }
  };

  const handleSelected = (e: React.MouseEvent) => {
    const { currentTarget } = e;

    const { value } = currentTarget as HTMLOptionElement;

    setTags([]);
    setSearchType("TAG");

    refetch({ query: value, categoryId, pageInfo: { limit: 20, nextToken: null }, options: { searchType: "TAG" } });

    history.replace(`${pathname}?keyword=${value}&type=TAG`);
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = e;

    if (currentTarget.value.length > 0) {
      currentTarget.classList.add("active");
    } else {
      currentTarget.classList.remove("active");
      setTags([]);
    }

    if (currentTarget.value.indexOf("#") === 0) {
      const tagsEl = document.getElementById("tag-list");
      tagsEl?.querySelector(".active")?.classList.remove("active");

      searchTags({
        variables: {
          query: currentTarget.value.substr(1)
        }
      });
    }
  };

  const handleTagOut = (e: any) => {
    const { target } = e;
    if (target.id === "root") {
      setTags([]);
    }
  };

  const handleReset = (e: React.MouseEvent) => {
    const { currentTarget } = e;

    const searchInputEl = currentTarget.previousElementSibling as HTMLInputElement;

    searchInputEl.value = "";

    searchInputEl.focus();
    searchInputEl.classList.remove("active");
  };

  const handleSearchType = (type: string) => {
    setSearchType(type);

    history.replace(`${pathname}?keyword=${keyword}&type=${type}`);

    if (keyword) {
      refetch({ query: keyword, categoryId, pageInfo: { limit: 20, nextToken: null }, options: { searchType: type } });
    }
  };

  const handleCtgModal = (value: boolean) => {
    setCtgModal(value);
    if (value) {
      document.querySelector("#live-status-bar")?.classList.add("deep");
    } else {
      document.querySelector("#live-status-bar")?.classList.remove("deep");
    }
  };

  useEffect(() => {
    const searchInputEl = document.getElementById("search-input") as HTMLInputElement;

    if (keyword) {
      searchInputEl.value = `${keyword}`;
      searchInputEl.classList.add("active");
    }

    const handlePostAdd = () => {
      const postListWrap = postWrapRef.current;

      if (!moreLoad && postListWrap && nextToken) {
        const triggerElement = postListWrap.children[postListWrap.children.length - 10];
        if (triggerElement) {
          const triggetRect = triggerElement.getBoundingClientRect();

          if (triggetRect.y < 10 && loadConfirm) {
            loadConfirm = false;

            fetchMore({
              variables: {
                categoryId,
                pageInfo: {
                  limit: 20,
                  nextToken: nextToken
                },
                options: { searchType }
              },
              updateQuery: (prev: ISearchData, { fetchMoreResult }) => {
                if (!fetchMoreResult || prev.searchPosts.totalCount === prev.searchPosts.items.length) return prev;

                nextToken = fetchMoreResult.searchPosts.nextToken;

                return {
                  searchPosts: {
                    ...prev.searchPosts,
                    nextToken: fetchMoreResult.searchPosts.nextToken,
                    totalCount: prev.searchPosts.totalCount,
                    items: [...prev.searchPosts.items, ...fetchMoreResult.searchPosts.items]
                  }
                };
              }
            });
          }
        }
      }
    };

    keyword && window.addEventListener("scroll", handlePostAdd);

    window.addEventListener("click", handleTagOut);

    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("scroll", handlePostAdd);
      window.removeEventListener("click", handleTagOut);
    };
  }, [categoryId, searchType, fetchMore, keyword]);

  return (
    <Search className={keyword ? "result" : ""}>
      <Top id="header" live={live}>
        <TopWrap>
          <Lnb>
            <Menu onClick={handleClose}>
              <IconBack size={"l"} />
            </Menu>
          </Lnb>
          <Title>{t("search")}</Title>
        </TopWrap>
        <SearchTabWrap>
          <SearchInputWrap>
            <input type="search" id={"search-input"} placeholder={t("searchPlaceholder")} onChange={handleSearchInput} onKeyDown={handleKeyDown} autoComplete={"off"} />
            <button type="button" onClick={handleReset}>
              <IconClose size={"m"} />
            </button>
            <IconSearch color={themeCur.light.COLORS.BG_SECONDARY} />
            {tags.length > 0 && (
              <TagSelect id={"tag-list"}>
                {tags.map((item, index) => (
                  <button value={item.value} key={index} onClick={handleSelected}>
                    {item.value}({item.id})
                  </button>
                ))}
              </TagSelect>
            )}
          </SearchInputWrap>
          {keyword && (
            <Tab justify>
              {tabData.map((item, index) => (
                <button type="button" className={item.searchType === searchType ? "active" : ""} onClick={() => handleSearchType(item.searchType)} key={index}>
                  {item.title}
                </button>
              ))}
            </Tab>
          )}
        </SearchTabWrap>
      </Top>
      <ResultWrap live={live}>
        {keyword && (
          <>
            <SelectWrap>
              <button type="button" onClick={() => handleCtgModal(true)}>
                {categoryData?.getCategory.title || t("all")}
                <IconArrow01 direction={"bottom"} />
              </button>
            </SelectWrap>
            <ResultCount dangerouslySetInnerHTML={{ __html: t("resultCount") }} />
            {!searchLoding && searchData?.searchPosts.items.length > 0 && (
              <PostListWrap ref={postWrapRef}>
                {searchData.searchPosts.items.map((item: ISearch, index: number) => (
                  <Item
                    key={index}
                    feed={searchType === "TITLE_CONTENT" ? true : searchType === "TAG" ? true : false}
                    categoryActive={true}
                    hightlight={item.highlight}
                    {...item.source}
                  />
                ))}
                {searchLoding && <LoadingItem />}
              </PostListWrap>
            )}
            {searchData?.searchPosts.items.length === 0 && <Empty text={t("noResultsFound")} />}
          </>
        )}
        {!keyword && (
          <>
            {!popularLoding && popularData?.getCategory?.posts.items.length > 0 && (
              <>
                <ResultCount>
                  <strong className={"m0"}>{t("popularPosts")}</strong>
                </ResultCount>
                <PostListWrap>
                  {popularData?.getCategory?.posts.items.map((item: IPost, index: number) => (
                    <Item key={index} feed={false} categoryActive={true} sortBy={"POPULARITY"} {...item} />
                  ))}
                  {popularLoding && <LoadingItem />}
                </PostListWrap>
              </>
            )}
            {popularData?.getCategory?.posts.items.length === 0 && !popularLoding && <Empty useIcon={"emptySearch"} text={t("searchInfoText")} />}
          </>
        )}
        {searchLoding && (
          <PostListWrap>
            {loadingCount.map((item, index) => (
              <LoadingItem key={index}>{item}</LoadingItem>
            ))}
          </PostListWrap>
        )}
      </ResultWrap>
      <Modal show={ctgModal} view={"content"} dimClose={() => handleCtgModal(false)}>
        <Category categoryGroupId={categoryData?.getCategory.parent.id} closeCallback={() => handleCtgModal(false)} keyword={keyword as string} />
      </Modal>
    </Search>
  );
};

export default withLayout(SearchView);

const LIVE_ON = css`
  padding-top: 42px;
`;

const Search = styled.div`
  position: relative;
  top: 92px;
  width: 100%;
  background: ${p => p.theme.COLORS.BG_BODY};
  z-index: 11;

  &.result {
    top: 142px;
  }

  &.result #header {
    height: 142px;
  }
`;

const Top = styled.header<ILive>`
  position: fixed;
  top: ${p => (p.live ? 42 : 0)}px;
  left: 0;
  width: 100%;
  height: 92px;
  z-index: 3;
`;

const TopWrap = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 8px 10px 10px 10px;
  width: 100%;
  max-width: 650px;
  height: 42px;
  background-color: ${p => p.theme.COLORS.BG_BODY};
  z-index: 3;
`;

const Lnb = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
`;

const Menu = styled.button`
  padding: 0;
`;

const Title = styled.h2`
  ${p => p.theme.TEXTS.TOP_TITLE};
  color: ${p => p.theme.COLORS.TEXT_REGULAR};
  text-align: center;
`;

const SearchTabWrap = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  background-color: ${p => p.theme.COLORS.BG_BODY};
`;

const SearchInputWrap = styled.div`
  position: relative;
  margin: 0 20px;
  padding: 0 40px 0 10px;
  height: 42px;
  background: ${p => p.theme.COLORS.BG_LIGHT04};
  z-index: 1;

  & input {
    padding: 5px 0;
    width: 100%;
    line-height: 30px;
    color: ${p => p.theme.COLORS.TEXT_REGULAR};
    border: 0 none;
    background: transparent;

    &.active + button {
      display: flex;
    }

    &.active + button + svg {
      display: none;
    }
  }

  & > button {
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 40px;
    height: 42px;
  }

  & > svg {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const TagSelect = styled.div`
  position: absolute;
  top: 42px;
  left: 0;
  padding: 3px 0;
  width: 100%;
  max-height: 300px;
  background: linear-gradient(180deg, ${p => p.theme.COLORS.BG_BODY} 0%, rgba(255, 255, 255, 0) 100%), ${p => p.theme.COLORS.BG_BODY};
  border: 1px solid ${p => p.theme.COLORS.BG_LIGHT03};
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.13);
  z-index: 100;
  overflow-y: auto;

  & select {
    width: 100%;
  }

  & button {
    display: block;
    position: static;
    padding: 7px 10px;
    width: 100%;
    min-height: 40px;
    font-size: 16px;
    line-height: 24px;
    text-align: left;

    &.active,
    &:hover {
      color: ${p => p.theme.COLORS.TEXT_POINT};
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ResultWrap = styled.div<ILive>`
  ${p => p.live && LIVE_ON}
`;

const PostListWrap = styled.div`
  padding-bottom: 120px;
  overflow-x: hidden;
`;

const SelectWrap = styled.div`
  padding: 15px 20px;

  & button {
    position: relative;
    padding: 5px 10px;
    width: 100%;
    min-height: 32px;
    color: ${p => p.theme.COLORS.TEXT_PRIMARY};
    font-size: ${p => p.theme.SIZE.n14};
    border: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
    background-color: ${p => p.theme.COLORS.BG_LIGHT05};
    text-align: left;

    & svg {
      position: absolute;
      top: 7px;
      right: 10px;
    }
  }
`;

const ResultCount = styled.div`
  padding: 8px 20px;
  color: ${p => p.theme.COLORS.TEXT_SECONDARY};
  font-size: ${p => p.theme.SIZE.n12};

  & dfn {
    display: inline-block;
    margin: 0 5px 0 0;
    color: ${p => p.theme.COLORS.TEXT_PRIMARY};
  }

  & strong {
    display: inline-block;
    color: ${p => p.theme.COLORS.TEXT_BRAND};
    font-weight: 500;

    &.m0 {
      margin: 0;
    }
  }
`;
