import React, { useState, useEffect, useRef } from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import { Spinner } from "styles";
import withLayout from "layouts/DefaultLayout";
import { Empty, TopWrite, Item, LoadingItem } from "components/Board";
import TopOption from "components/Board/TopOption";
import { common, bounceRefetch } from "utils";
import { useQuery } from "@apollo/react-hooks";
import { GET_CATEGORY_POST } from "api/Board";
import { css } from "@emotion/core";

let loadConfirm = true;
let nextToken: string | undefined | null;
let moreLoad: boolean;

const BoardView: React.FC<RouteComponentProps> = ({ user, match, location, history, categoryData, setCategoryData, setWrite, live }) => {
  const { t } = useTranslation("Board");

  const { search, pathname } = location;
  const { params } = match;
  const queryPath = queryString.parse(search);
  const sortBy = queryPath.sortBy as SortType;
  const feed = queryPath.viewBy === "list" ? false : true;

  const [categoryId, setCategoryId] = useState<string | undefined>(params.categoryId);

  if (categoryId !== params.categoryId) {
    setCategoryId(params.categoryId);
  }

  const postWrapRef = useRef<HTMLDivElement>(null);

  const limit = 10;

  const { data: postData, loading: postLoding, refetch, fetchMore } = useQuery(GET_CATEGORY_POST, {
    variables: { id: categoryId, limit, nextToken: null, options: { sortBy } },
    fetchPolicy: "cache-first"
  });

  moreLoad = postLoding;
  nextToken = postData?.getCategory.posts.nextToken;

  loadConfirm = postData?.getCategory.posts.nextToken ? true : false;

  const handleScrollTop = () => {
    common.scrollRemove("board", categoryId);
    refetch();

    window.scrollTo(0, 0);
  };

  const handleBounce = (event: TouchEvent) => {
    if (postWrapRef) {
      bounceRefetch.start(event, postWrapRef.current, refetch);
    }
  };

  const loadingCount = ["", "", "", "", "", "", "", "", ""];

  if (postData?.getCategory.id !== categoryData?.id) {
    setCategoryData({
      id: postData.getCategory.id,
      title: postData.getCategory.title,
      canWritePosts: postData.getCategory.canWritePosts,
      canWriteComments: postData.getCategory.canWriteComments,
      allowComments: postData.getCategory.allowComments,
      allowSecrets: postData.getCategory.allowSecrets,
      parent: postData.getCategory.parent
    });
  }

  useEffect(() => {
    const handlePostAdd = () => {
      const postListWrap = postWrapRef.current;

      if (!moreLoad && postListWrap && nextToken) {
        const triggerElement = postListWrap.children[postListWrap.children.length - limit / 2];
        if (triggerElement) {
          const triggetRect = triggerElement.getBoundingClientRect();

          if (triggetRect.y < limit / 2 && loadConfirm) {
            loadConfirm = false;

            fetchMore({
              variables: {
                id: categoryId,
                limit,
                nextToken: nextToken,
                options: { sortBy }
              },
              updateQuery: (prev: ICategoryData, { fetchMoreResult }) => {
                if (!fetchMoreResult || prev.getCategory.posts.totalCount === prev.getCategory.posts.items.length) return prev;

                nextToken = fetchMoreResult.getCategory.posts.nextToken;

                return {
                  getCategory: {
                    ...prev.getCategory,
                    posts: {
                      ...fetchMoreResult.getCategory.posts,
                      nextToken: fetchMoreResult.getCategory.posts.nextToken,
                      totalCount: prev.getCategory.posts.totalCount,
                      items: [...prev.getCategory.posts.items, ...fetchMoreResult.getCategory.posts.items]
                    }
                  }
                };
              }
            });
          }
        }
      }
    };

    window.addEventListener("scroll", handlePostAdd);

    window.addEventListener("touchstart", handleBounce);
    window.addEventListener("touchmove", bounceRefetch.move);
    window.addEventListener("touchend", bounceRefetch.end);

    return () => {
      window.scrollTo(0, 0);

      window.removeEventListener("scroll", handlePostAdd);

      window.removeEventListener("touchstart", handleBounce);
      window.removeEventListener("touchmove", bounceRefetch.move);
      window.removeEventListener("touchend", bounceRefetch.end);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, sortBy, fetchMore]);

  return (
    <Wrap>
      <TopOption count={postData?.getCategory.posts.totalCount} sortBy={sortBy} feed={feed} pathname={pathname} moveTop={() => handleScrollTop()} live={live} />
      {postLoding && (
        <PostListWrap>
          <TopWrite categoryId={categoryId} name={user.name} id={user.id} src={user.picture} setWrite={setWrite} history={history} />
          {loadingCount.map((item, index) => (
            <LoadingItem key={index}>{item}</LoadingItem>
          ))}
        </PostListWrap>
      )}
      {!postLoding && postData?.getCategory.posts.items.length > 0 && (
        <FixedLoading canWritePost={postData.getCategory.canWritePosts}>
          <Spinner />
        </FixedLoading>
      )}
      {postData?.getCategory.posts.items.length > 0 && (
        <PostListWrap ref={postWrapRef} live={live}>
          {postData.getCategory.canWritePosts && <TopWrite categoryId={categoryId} name={user.name} id={user.id} src={user.picture} setWrite={setWrite} history={history} />}
          {postData.getCategory.posts.items.map((item: IPost, index: number) => (
            <Item key={index} feed={feed} categoryActive={categoryId ? true : false} setWrite={setWrite} sortBy={sortBy} scrollSave={common.scrollSave} {...item} />
          ))}
          {loadConfirm && <LoadingItem />}
        </PostListWrap>
      )}
      {postData?.getCategory.posts.items.length === 0 && (
        <Empty
          text={`${t("emptyPostText")}${postData.getCategory.canWritePosts ? `<br />${t("emptyPostText2")}` : ""}`}
          canWritePosts={postData.getCategory.canWritePosts}
          setWrite={setWrite}
        />
      )}
    </Wrap>
  );
};

export default withLayout(BoardView);

const LIVE_ON = css`
  padding-top: 42px;
`;

const Wrap = styled.div``;

const PostListWrap = styled.div<ILive>`
  padding-bottom: 120px;
  overflow-x: hidden;
  ${p => p.live && LIVE_ON}
`;

const FixedLoading = styled.div<ILoadingFixedStyle>`
  position: absolute;
  top: ${p => (p.canWritePost ? (p.live ? 214 : 172) : 118)}px;
  left: 0;
  width: 100%;
  border: 0 none !important;
  z-index: 0;
`;
