import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconEmptyPencil = ({ width, height, brand }: IconEmptyPencil) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 55 115" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.3525 49.9633L54.034 41.9296C55.322 40.5823 55.322 38.4015 54.034 37.0581L46.3393 29.0105C45.0546 27.6632 42.9695 27.6632 41.6816 29.0105L34 37.0443L46.3525 49.9633ZM32.7846 38.4587L2.77225 69.8474C0.99299 71.7083 0 74.1836 0 76.7582V85.5229H7.94132C10.6712 85.5229 13.2825 84.4069 15.1691 82.4338L45 51.2348L32.7846 38.4587ZM44.1634 31.1376L39 36.5379L46.8366 44.7339L52 39.3337L44.1634 31.1376ZM32.3907 43.6881L40 51.6468L13.5633 79.2961C11.6767 81.2693 9.06547 82.3853 6.33554 82.3853H3V78.438C3 75.8634 3.99299 73.388 5.77224 71.5272L32.3907 43.6881Z"
        fill={brand ? brand : ThemeUse.ICONS.EMPTY.ONE}
      />
      <rect x="18" y="82.3853" width="27" height="3.13761" fill={brand ? brand : ThemeUse.ICONS.EMPTY.ONE} />
    </svg>
  );
};

IconEmptyPencil.defaultProps = {
  width: 55,
  height: 115
};

export default IconEmptyPencil;
