export default {
  comments: "コメント",
  commentCount: `コメント&nbsp;<strong id="comment-count"></strong>`,
  commentPost: "登録",
  leaveComment: "コメントを作成してください。",
  permissionComment: "コメントを作成する権限がありません。",
  sizeOptionWord: "が",
  edit: "修正",
  delete: "削除",
  deleteText: "削除しますか？",
  yes: "確認",
  cancel: "取消",
  image: "イメージ",
  video: "動画",
  postedComment: "コメントが登録されました。",
  report: "報告する",
  submit: "完了",
  Submitted: "完了",
  reportAdminText: "管理者が確認して規定に従って処理します。",
  reportReason: "報告する問題を入力してください。(150文字以内)",
  reportPostText: "報告を完了しました。",
  ok: "確認",
  editComment: "コメント編集",
  save: "完了",
  firstCommentText: "コメントを作成してください。",
  newest: "最新順",
  mostLiked: "いいね！順",
  scrape: "アーカイブ完了",
  scrapeOff: "アーカイブ解除",
  errorPost: "削除された投稿です。<br />全体リストへ移動します。",
  readPermission: "権限がありません。 <br />全体リストへ移動します。",
  notProvideFileViewer: "このトピックはダウンロードができません。",
  filePreview: "プレビュー",
  NotSupportedFilePreview: "プレビューができません。"
};
