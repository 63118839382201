import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import axios from "axios";
import withLayout from "layouts/DefaultLayout";
import { Button } from "styles";
import { appNative } from "utils";
import { IconEmptyCheck } from "assets/icon/svg";
import auth from "apollo/auth";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { getCookieState } from "apollo/LocalState";

const LOGIN = gql`
  mutation userLogin($token: String!) {
    userLogin(token: $token) @client
  }
`;

const { userAgent } = window.navigator;

const { siteId, cpId } = getCookieState;

const LoginView: React.FC<RouteComponentProps> = () => {
  const DEV_STATUS = siteId === "dev" || siteId === "test";
  const { t } = useTranslation("Login");
  const [userID, setUserID] = useState<string>("");
  const [userPW, setUserPW] = useState<string>("");
  const [touchclass, setTouchclass] = useState<ITouchclass | null>();

  const [loginMutation] = useMutation(LOGIN);

  const params = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    grant_type: "password",
    username: userID,
    password: userPW
  };

  const handleLogin = async () => {
    const token = await auth.login(params);

    loginMutation({ variables: { token } });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, setState: React.Dispatch<React.SetStateAction<string>>) => {
    setState(e.currentTarget.value);
  };

  // const appErrorMsg = { error: "message", errorCod: "9999" };

  // if (userAgent.includes("Touchclass iOS")) {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   (window as any).webkit?.messageHandlers.onTogether.postMessage({ action: "close", value: { error: "message", errorCod: 9999 } });
  // } else if (userAgent.includes("Touchclass Android")) {
  //   // eslint-disable-next-line @typescript-eslint/no-explicit-any
  //   (window as any).onTogether?.close(JSON.stringify(appErrorMsg));
  // }

  useEffect(() => {
    axios
      .get(`https://api.${siteId}.touchtogether.com/${cpId ? `${cpId}/` : ""}company`)
      .then(res => {
        setTouchclass({ ...res.data });

        const siteName = document.getElementById("site-name");

        if (siteName) {
          siteName.innerHTML = res.data.title;
        }
      })
      .catch(err => {
        // console.log(err);
      });

    if (DEV_STATUS) {
      const appData = {
        type: "expire",
        method: "expire"
      };

      appNative.closeData(appData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrap>
      {DEV_STATUS && (
        <>
          <input type="input" value={userID} placeholder={"아이디입력"} onChange={e => handleChange(e, setUserID)} />
          <input type="password" value={userPW} placeholder={"비밀번호입력"} onChange={e => handleChange(e, setUserPW)} />
          <Button type="button" onClick={handleLogin}>
            로그인
          </Button>
        </>
      )}
      <Warning>
        <IconEmptyCheck />
        <p dangerouslySetInnerHTML={{ __html: t("logOutText") }} />
        {userAgent.indexOf("Touchclass") > -1 ? (
          <Button round onClick={() => appNative.close()}>
            {t("close")}
          </Button>
        ) : (
          <Button to={touchclass?.url} target={"_blank"} round>
            {t("logIn")}
          </Button>
        )}
      </Warning>
    </Wrap>
  );
};

export default withLayout(LoginView);

const Wrap = styled.div`
  padding: 10px;

  input {
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    border: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  }

  button {
    width: 100%;
  }
`;

const Warning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  text-align: center;

  & p {
    margin: 20px 0;
  }

  & button {
    width: auto;
  }
`;
