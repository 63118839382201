export default {
  createPost: "Buat postingan",
  editPost: "Edit postingan",
  confirm: "Posting",
  enterTitle: "Tulis judul.",
  warningTitle: "Anda dapat menulis kurang dari 100 alfabet karakter.",
  enterContent: "Tambahkan teks atau sisipkan media.",
  privateMake: "Privatkan postingan",
  success: "Berhasil!",
  successPostText: "Postingan Anda telah diunggah.",
  successPostText2: "Postingan Anda telah diedit.",
  ok: "OK",
  uploading: "Mengunggah...",
  cancel: "Batal",
  done: "Selesai",
  edit: "Edit",
  delete: "Hapus",
  deleteText: "Yakin ingin <br />menghapus postingan ini?",
  yes: "Ya",
  dataCellular: "Data seluler",
  dataCellularText: "Menggunakan data seluler<br/> dapat menyebabkan baya tambahan. <br/> Lanjutkan?",
  lock: "Posting sebagai privat",
  unlock: "Publik",
  selectCategory: `Pilih`,
  wantToCancelWrite: "Yakin ingin keluar?",
  cancelWrite: "Keluar",
  changePublicPost: "Diubah menjadi 'postingan publik' sesuai dengan pengaturan topik.",
  changePrivatePost: "Telah diubah menjadi ‘postingan rahasia’ sesuai dengan pengaturan topik."
};
