import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconCircle = ({ width, height, color, type }: IconCircle) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      {type === "plus" && (
        <>
          <path
            d="M20.75 10.5C20.75 16.1609 16.1609 20.75 10.5 20.75C4.83908 20.75 0.25 16.1609 0.25 10.5C0.25 4.83908 4.83908 0.25 10.5 0.25C16.1609 0.25 20.75 4.83908 20.75 10.5Z"
            stroke={color ? color : ThemeUse.COLORS.BG_PRIMARY}
            strokeWidth="0.5"
            fill="none"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M11 6H10V10H6V11H10V15H11V11H15V10H11V6Z" fill={color ? color : ThemeUse.COLORS.BG_PRIMARY} />
        </>
      )}
      {type === "minus" && (
        <>
          <path
            d="M20.75 10.5C20.75 16.1609 16.1609 20.75 10.5 20.75C4.83908 20.75 0.25 16.1609 0.25 10.5C0.25 4.83908 4.83908 0.25 10.5 0.25C16.1609 0.25 20.75 4.83908 20.75 10.5Z"
            stroke={color ? color : ThemeUse.COLORS.BG_PRIMARY}
            strokeWidth="0.5"
            fill="none"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M15 10V11L6.00005 11V10L15 10Z" fill={color ? color : ThemeUse.COLORS.BG_PRIMARY} />
        </>
      )}
    </svg>
  );
};

IconCircle.defaultProps = {
  width: 21,
  height: 21,
  type: "plus"
};

export default IconCircle;
