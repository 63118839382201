export default {
  createPost: "Create post",
  editPost: "Edit post",
  confirm: "Post",
  enterTitle: "Enter a title.",
  warningTitle: "You can enter fewer than 100 English characters.",
  enterContent: "Add text or insert media.",
  privateMake: "Make post private",
  success: "Success!",
  successPostText: "Your post has been uploaded.",
  successPostText2: "Your post has been edited.",
  ok: "OK",
  uploading: "Uploading...",
  cancel: "Cancel",
  done: "Done",
  edit: "Edit",
  delete: "Delete",
  deleteText: "Are you sure you want to <br />delete this post?",
  yes: "Yes",
  dataCellular: "Cellular data",
  dataCellularText: "Using data over a cellular network<br/> may incur additional fees. <br/> Do you want to proceed?",
  lock: "Post private",
  unlock: "Public",
  selectCategory: `Select a classification.`,
  wantToCancelWrite: "Do you want to quit?",
  cancelWrite: "Quit",
  changePublicPost: "Changed to 'public post' according to topic settings.",
  changePrivatePost: "It has been changed to ‘secret post’ according to the topic settings."
};
