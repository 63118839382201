export default {
  comments: "bình luận",
  commentCount: `<strong id="comment-count"></strong>&nbsp;bình luận`,
  commentPost: "Bài đăng",
  leaveComment: "Để lại bình luận.",
  permissionComment: "Bạn không được phép để lại bình luận.",
  sizeOptionWord: "A",
  edit: "Chỉnh sửa",
  delete: "Xóa",
  deleteText: "Bạn có chắc chắn muốn <br />xóa bài đăng này?",
  yes: "Có",
  cancel: "Hủy bỏ",
  image: "Hình ảnh",
  video: "Video",
  postedComment: "Đã đăng bình luận của bạn.",
  report: "Báo cáo",
  submit: "Gửi",
  Submitted: "Đã gửi",
  reportAdminText: "Quản trị viên sẽ xem xét báo cáo của bạn và xử lý theo quy định của công ty.",
  reportReason: "Giải thích lý do về việc bạn báo cáo dưới 150 ký tự.",
  reportPostText: "Bài đăng này đã được báo cáo.",
  ok: "OK",
  editComment: "Chỉnh sửa bình luận",
  save: "Lưu",
  firstCommentText: "Hãy là người đầu tiên để lại bình luận.",
  newest: "Mới nhất",
  mostLiked: "Được yêu thích nhất",
  scrape: "Đã lưu trữ",
  scrapeOff: "Đã hủy lưu trữ",
  errorPost: "Bài đăng không tồn tại hoặc đã bị xóa. <br />Đi tới Xem tất cả.",
  readPermission: "You don't have permission to view this post. <br />Go to list of all posts.",
  notProvideFileViewer: "This topic is Do not allow downloads.",
  filePreview: "File Preview",
  NotSupportedFilePreview: "Not supported for preview"
};
