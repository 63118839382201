import React from "react";
import { Link } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import { IconView } from "assets/icon/svg";

const TopOptionComponent: React.FC<ITopOption> = ({ count, sortBy, feed, pathname, moveTop, live }) => {
  const { t } = useTranslation("Board");

  return (
    <Wrap>
      <FixedWrap live={live}>
        <CountWrap>
          <Count id={"post-count"}>{count}</Count>
          <span dangerouslySetInnerHTML={{ __html: t("posts") }} />
        </CountWrap>
        <Right>
          <Sort>
            <Link to={`${pathname}?sortBy=CREATED_AT${feed ? "" : "&viewBy=list"}`} className={sortBy === "CREATED_AT" ? "active" : "CREATED_AT"} onClick={moveTop}>
              {t("newest")}
            </Link>
            <Link to={`${pathname}?sortBy=LIKES${feed ? "" : "&viewBy=list"}`} className={sortBy === "LIKES" ? "active" : "LIKES"} onClick={moveTop}>
              {t("mostLiked")}
            </Link>
          </Sort>
          <Link to={`${pathname}?sortBy=${sortBy}${!feed ? "" : "&viewBy=list"}`} onClick={moveTop}>
            <IconView type={!feed ? "feed" : "list"} />
          </Link>
        </Right>
      </FixedWrap>
    </Wrap>
  );
};

TopOptionComponent.defaultProps = {
  sortBy: "CREATED_AT",
  feed: true
};

export default TopOptionComponent;

const Wrap = styled.div`
  display: flex;
  width: 100%;
  height: 46px;
`;

const FixedWrap = styled.div<ILive>`
  display: flex;
  position: fixed;
  top: ${p => (p.live ? 84 : 42)}px;
  padding: 0 8px 0 20px;
  width: 100%;
  max-width: 650px;
  border-bottom: 4px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  background: ${p => p.theme.COLORS.BG_REGULAR};
  z-index: 3;
`;

const CountWrap = styled.div`
  display: flex;
  align-items: center;
  ${p => p.theme.TEXTS.INFO_TXT}
  margin-right: auto;
`;

const Count = styled.strong`
  ${p => p.theme.TEXTS.INFO_TXT_ALL_NUM}
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  ${p => p.theme.TEXTS.INFO_TXT}

  button {
    padding: 0;
  }
`;

const Sort = styled.div`
  a {
    ${p => p.theme.TEXTS.INFO_SORT}
    position: relative;
    padding: 0 7px;
    text-decoration: none;

    & + a {
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -3px;
        width: 1px;
        height: 8px;
        background-color: ${p => p.theme.COLORS.TEXT_PLACEHOLDER};
      }
    }
  }
`;
