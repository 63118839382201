export default {
  createPost: "글쓰기",
  editPost: "글 수정",
  confirm: "완료",
  enterTitle: "제목을 입력하세요.",
  warningTitle: "한글 50자 이내, 영문 100자 이내로 입력 가능합니다.",
  enterContent: "내용을 입력하세요.",
  privateMake: "비밀글 설정",
  success: "완료",
  successPostText: "글 올리기가 완료되었습니다.",
  successPostText2: "글 수정이 완료되었습니다.",
  ok: "확인",
  uploading: "업로드 진행중…",
  cancel: "취소",
  done: "완료",
  edit: "수정",
  delete: "삭제",
  deleteText: "삭제 하시겠습니까?",
  yes: "확인",
  dataCellular: "데이터 알림",
  dataCellularText: "사용 중인 요금제에 따라 데이터<br/> 요금이 발생할 수 있습니다. <br/>계속 진행하시겠습니까?",
  lock: "비밀글 설정",
  unlock: "비밀글 해제",
  selectCategory: `작성할 분류를 선택하세요.`,
  wantToCancelWrite: "글 작성을 취소하시겠습니까?",
  cancelWrite: "작성 취소",
  changePublicPost: "토픽 설정에 따라 '공개글'로 변경되었습니다.",
  changePrivatePost: "토픽 설정에 따라 '비밀글'로 변경되었습니다."
};
