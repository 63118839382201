import React from "react";
import { Global } from "@emotion/core";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "apollo";
import { Reset } from "styles";
import App from "components/App";

const Root: React.FC = () => {
  return (
    <React.Fragment>
      <Global styles={Reset} />
      <ApolloProvider client={client as any}>
        <App />
      </ApolloProvider>
    </React.Fragment>
  );
};

export default Root;
