const autoLinkTag = ({ delta, quill }: IAutoLinkTag) => {
  const { retain } = delta.ops[0];

  const currentInput = delta.ops[1]?.insert;

  const keyCode = typeof currentInput === "string" && currentInput.charCodeAt(0);

  if (keyCode === 32 || keyCode === 10) {
    let status = false;
    const textFull = quill.getText(0, retain);
    const text = textFull.replace(/\s*$/, "");
    const content = quill.getContents(0, retain);
    const regexLink = /(https?:\/\/)[^\s]+[.][^\s]+|(www.)[^\s]+[.][^\s]+/g;
    // const regexLink = /(https?:\/\/)[^\s]+[.][^\s]+|(www.)[^\s]+[.][^\s]+|[#][^\s]+/g;
    // const regexSC = /[#~!@$%^&*().+|<>?:{}-]/;
    // eslint-disable-next-line
    // const regexSC = /[\{\}\[\]\/?.,;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"]/;
    const regexGap = /\s*$/g;
    const spaceMatch = textFull.match(regexGap);

    const matchLink = text.match(regexLink);

    const dummyFocusEl = document.getElementById("dummy-focus");

    if (keyCode === 10 && dummyFocusEl) {
      dummyFocusEl.focus();
    }

    if (matchLink === null || spaceMatch!.length > 1) {
      // const dummyFocusEl = document.getElementById("dummy-focus");

      // if (keyCode === 10 && dummyFocusEl) {
      //   dummyFocusEl.focus();

      //   // const recoverCursor = quill.getSelection();
      //   // setTimeout(() => quill && quill.setSelection(recoverCursor!), 1);
      // }
      return true;
    }

    const currentLink = matchLink[matchLink.length - 1];

    const distanceLink = text.length - currentLink.length;

    const distanceCursorLink = Number(text.indexOf(currentLink, distanceLink));

    const statusLink = distanceCursorLink === 0 || (distanceCursorLink > 0 && true);

    status = statusLink ? true : false;

    const firstInsert = content.ops[0].insert;

    // const firstCheck = firstInsert.image || firstInsert.video || firstInsert.mention;
    const firstCheck = firstInsert.image || firstInsert.video;

    // const mediaLength = content.ops.filter(({ insert }: IOpMedia) => insert.image || insert.video || insert.mention).length;
    const mediaLength = content.ops.filter(({ insert }: IOpMedia) => insert.image || insert.video).length;

    if (status) {
      const insertStart = text.length - currentLink.length;

      const linkCheck = text.indexOf(currentLink, insertStart);

      if (linkCheck !== -1) {
        const ops: IOp[] = [{ delete: currentLink.length }];

        // if (currentLink.indexOf("#") === 0 && currentLink.length > 0) {
        //   if (currentLink.substr(1).match(regexSC)) {
        //     return;
        //   }

        //   ops.push({
        //     insert: {
        //       mention: {
        //         denotationChar: "#",
        //         value: currentLink.substr(1)
        //       }
        //     }
        //   });

        //   keyCode === 10 && ops.push({ insert: " " });
        // } else {
        //   ops.push({
        //     insert: currentLink,
        //     attributes: { link: currentLink.indexOf("www") === 0 ? `http://${currentLink}` : currentLink }
        //   });
        // }

        ops.push({
          insert: currentLink,
          attributes: { link: currentLink.indexOf("www") === 0 ? `http://${currentLink}` : currentLink }
        });

        if (firstCheck || text.length > currentLink.length) {
          ops.unshift({ retain: insertStart + mediaLength });
        }

        quill.updateContents({ ops });

        // const recoverCursor = quill.getSelection();

        // setTimeout(() => quill && quill.setSelection(recoverCursor!), 1);
      }
    }
  }
};

export default autoLinkTag;
