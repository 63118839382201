export default {
  ok: "確認",
  cancel: "取消",
  confirm: "完了",
  "Report a post": "報告する",
  "block author": "ブロックする",
  report: "報告",
  "Processing policy": "管理者が確認して、規定により処理します。",
  Reason: "報告理由を入力してください（150字以内）",
  "article reported": "あなたが報告した投稿です。",
  "Report a comment": "コメントを報告する",
  "comment reported": "コメントを報告する",
  "block description": "このユーザーの投稿とコメントの通知が中止され、内容の確認もできなくなります。",
  "to block": "ブロックする",
  "post blocked": "あなたがブロックしたユーザーの投稿です。",
  "comment blocked": "あなたがブロックしたユーザーのコメントです。",
  "Would you like to unblock?": "ブロックを解除しますか？",
  "Members I Blocked": "あなたがブロックしたユーザー",
  ReportCompleted: "報告しました。",
  success: "完了",
  unblock: "解除"
};
