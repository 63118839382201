import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";
const IconBack = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8021 21.4121H28.4411C28.6277 21.4255 28.8063 21.3336 28.9037 21.1738C29.0011 21.014 29.0011 20.8131 28.9037 20.6533C28.8063 20.4935 28.6277 20.4016 28.4411 20.415H13.8119L20.3028 14.3333C20.403 14.243 20.4599 14.1143 20.4593 13.9795C20.4587 13.8446 20.4007 13.7164 20.2997 13.627C20.0828 13.4329 19.754 13.4349 19.5394 13.6316L12.1358 20.5679C12.0357 20.657 11.9785 20.7847 11.9785 20.9187C11.9785 21.0527 12.0357 21.1803 12.1358 21.2695L19.5394 28.299C19.7541 28.4956 20.0829 28.4974 20.2997 28.3031C20.4004 28.2136 20.4582 28.0855 20.4588 27.9509C20.4594 27.8162 20.4027 27.6877 20.3028 27.5973L13.8021 21.4121Z"
        fill={color ? color : ThemeUse.COLORS.BG_BASE}
      />
    </svg>
  );
};

IconBack.defaultProps = {
  width: 40,
  height: 40
};

export default IconBack;
