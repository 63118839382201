import Quill from "quill";

class CustomLinkEmbed extends Quill.import("formats/link") {
  static create(value: string) {
    const node = super.create(value);

    const sanitized: string = super.sanitize(value);

    const href = !/^https?:\/\//i.test(sanitized) ? `https://${sanitized}` : sanitized;

    node.setAttribute("href", href);

    // I'd expect this to work, based on https://github.com/quilljs/quill/issues/1139
    // ...but it doesn't...
    node.setAttribute("target", "_blank");
    return node;
  }
}

export default CustomLinkEmbed;
