import React, { useState } from "react";
import styled, { theme as themeCur } from "styles/theme";
import { IconFile, IconThum } from "assets/icon/svg";
import thumLoad from "assets/icon/thum_load.png";
import { isPdf } from "../../utils/string";
import { Modal } from "../../styles";
import { useTranslation } from "react-i18next";
import IconEmptyCancel from "../../assets/icon/svg/IconEmptyCancel";
import appNative from "../../utils/appNative";
import { link, nativeLink } from "../../utils/document/link";
import { PDFViewerModal } from "../Common/PDFViewerModal";

export const getFileUrl = (url?: string, prevent?: boolean) => {
  if (prevent) {
    return url + "?inline";
  } else {
    return url + "?download";
  }
};

const FileComponent: React.FC<IFileWrite> = ({ type, url, name, status, callDelete, preventDownload }) => {
  const [modal, setModal] = useState<boolean>(false);
  const [pdfModal, setPdfModal] = useState<boolean>(false);
  const { t } = useTranslation("Detail");
  const isPreview = isPdf(type);
  const getUrl = () => {
    return getFileUrl(url, preventDownload);
  };

  const showPreview = () => {
    if (appNative.isNative()) {
      nativeLink(getFileUrl(url, true), type ?? "");
    } else {
      setPdfModal(true);
    }
  };

  const handleFileDefender = (e: React.MouseEvent) => {
    if (status?.toLocaleLowerCase().includes("write")) {
      e.preventDefault();
    }
    if (preventDownload) {
      if (isPreview) showPreview();
      return;
    }
    link(getUrl());
  };

  const handleDelete = () => {
    callDelete && callDelete(url);
  };

  if (!status?.includes("comment") && type?.includes("image")) return <></>;

  const isWrite = status?.toLocaleLowerCase().includes("write");

  return (
    <>
      <FileWrap className={status?.includes("comment") ? "fileThum" : "fileList"}>
        {status === "commentWrite" && (
          <ThumWrap>
            <img src={`${url}?size=thumbnail`} alt={name} />
            {type?.includes("video") && <IconThum video={true} />}
          </ThumWrap>
        )}
        {status !== "commentWrite" && (
          <FileTitleComponent name={name ?? ""} onClick={showPreview} isPreview={isPreview}>
            <IconFile type={"doc"} brand={isPreview ? undefined : "#3D3D3D"} />
            <div>
              <span>{name}</span>
              {isPreview ? <Preview>{t("filePreview")}</Preview> : <NotPreview> {t("NotSupportedFilePreview")}</NotPreview>}
            </div>
          </FileTitleComponent>
        )}

        {!preventDownload && (
          <button type={"button"} onClick={isWrite ? handleDelete : handleFileDefender}>
            <IconFile type={isWrite ? "delete" : "down"} brand={status?.includes("comment") ? themeCur.light.COLORS.BG_LIGHT01 : undefined} />
          </button>
        )}
      </FileWrap>
      <Modal
        show={modal}
        title={""}
        view={"alert"}
        text={t("notProvideFileViewer")}
        confirm={() => {
          setModal(false);
        }}
      >
        <IconEmptyCancel />
      </Modal>
      <PDFViewerModal
        url={getUrl()}
        open={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
      />
    </>
  );
};

interface FileTitleComponentProps {
  isPreview: boolean;
  onClick: (e: React.MouseEvent) => void;
  name: string;
}
const FileTitleComponent: React.FC<FileTitleComponentProps> = ({ children, name, isPreview, onClick }) => {
  return (
    <FileTitle>
      {isPreview ? (
        <File download={name} className="file" onClick={onClick}>
          {children}
        </File>
      ) : (
        <FileNotDownload className="file">{children}</FileNotDownload>
      )}
    </FileTitle>
  );
};

FileComponent.defaultProps = {};

export default FileComponent;

const FileWrap = styled.div`
  & > button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 9px 9px;
    width: 40px;
    height: 40px;
    z-index: 1;
  }

  &.fileThum {
    display: inline-block;
    vertical-align: top;

    & > button {
      padding: 0;
      top: 0;
      right: 0;
    }
  }

  &.fileList {
    ${p => p.theme.TEXTS.TXT_FILEBOX};
    position: relative;

    & + .fileList {
      margin-top: 10px;
    }

    height: 100%;
  }

  .file {
    display: flex;
    align-items: center;
    position: relative;
    padding: 9px 40px;
    width: 100%;
    min-height: 42px;
    text-align: left;

    &.down {
      z-index: 2;
    }

    & > svg {
      position: absolute;
      top: 9px;
      left: 9px;
    }

    & > span {
      width: 100%;
      height: 15px;
      overflow: hidden;
    }
  }
`;

const File = styled.a`
  color: ${p => p.theme.COLORS.BG_OVERLAY1};
`;

const FileNotDownload = styled.div`
  color: ${p => p.theme.COLORS.BG_OVERLAY1};
`;

const FileTitle = styled.div`
  display: flex;
`;

const Preview = styled.div`
  color: ${p => p.theme.COLORS.TEXT_BRAND};
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;
`;

const NotPreview = styled.div`
  color: ${p => p.theme.COLORS.BG_LIGHT02};
  font-size: 10px;
  font-weight: 400;
  margin-top: 3px;
`;

const ThumWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 70px;
  min-height: 65px;
  border-radius: 6px;
  background: url(${thumLoad}) center center no-repeat;
  background-size: 100%;
  overflow: hidden;

  & img {
    height: 200%;
    vertical-align: top;
  }

  & svg {
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
`;
