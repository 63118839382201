import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconClip = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6066 7.55052L10.3033 12.8538C8.76784 14.3893 6.26027 14.3667 4.69692 12.8033C3.13357 11.24 3.11098 8.7324 4.64642 7.19697L10.4548 1.38859C11.571 0.272373 13.3956 0.288811 14.5321 1.42532C15.6686 2.56184 15.6851 4.38645 14.5689 5.50267L9.26557 10.806C8.56856 11.503 7.42691 11.4927 6.71723 10.783C6.00755 10.0733 5.99727 8.93167 6.69427 8.23467L11.4925 3.43645L12.0067 3.9507L7.20853 8.74892C6.79012 9.16733 6.79629 9.85192 7.22231 10.2779C7.64832 10.7039 8.3329 10.7101 8.75131 10.2917L13.4745 5.56847L13.5495 5.49348L14.0546 4.98841C14.8914 4.15157 14.8791 2.78243 14.0271 1.9304C13.175 1.07834 11.8059 1.06601 10.9691 1.90285L5.69368 7.17822L5.16068 7.71123C3.90541 8.96649 3.92392 11.0202 5.202 12.2982C6.48009 13.5763 8.53375 13.5948 9.78901 12.3396L15.0923 7.03626L15.6066 7.55052Z"
        fill={color ? color : ThemeUse.ICONS.PROFILE.ONE}
        stroke={color ? color : ThemeUse.ICONS.PROFILE.ONE}
        strokeWidth="0.5"
      />
    </svg>
  );
};

IconClip.defaultProps = {
  width: 20,
  height: 15
};

export default IconClip;
