export default {
  all: "All",
  posts: "&nbsp;posts",
  newest: "Newest",
  mostLiked: "Most liked",
  emptyPostText: "No posts yet.",
  emptyPostText2: "Be the first to make a post.",
  createPost: "Create post",
  privatePost: "Private post",
  seeMore: "See more",
  writeNewPost: "Write a new post.",
  touchTogether: "Touch Together",
  scrape: "Archived",
  scrapeOff: "Unarchived"
};
