export default {
  all: "Tất cả",
  search: "Tìm kiếm",
  searchPlaceholder: "Tìm kiếm",
  searchInfoText: "Tìm kiếm tiêu đề, nội dung hoặc tên.",
  popularPosts: "Bài đăng phổ biến",
  titleContent: "Tiêu đề + Nội dung",
  title: "Tiêu đề",
  author: "Tác giả",
  resultCount: `<strong id="result-count"></strong> kết qủa`,
  noResultsFound: "Không tìm thấy kết quả nào."
};
