export default {
  all: "全体",
  search: "検索",
  searchPlaceholder: "検索語を入力してください。",
  searchInfoText: "タイトル、内容、名前を検索してください。",
  popularPosts: "人気",
  titleContent: "タイトル + 内容",
  title: "タイトル",
  author: "作成者",
  tag: "TAG",
  resultCount: `<dfn>検索結果</dfn><strong id="result-count"></strong> 件の投稿`,
  noResultsFound: "検索結果が存在しません。"
};
