import React, { useEffect } from "react";
import { IconEllipticalArrow } from "assets/icon/svg";
import { useTranslation } from "react-i18next";
import styled from "styles/theme";
import useSWR from "swr";

import { getCookieState } from "apollo/LocalState";

const LiveStatusBar: React.FC<ILiveStatusBar> = ({ setLive }) => {
  const { t } = useTranslation("Common");

  const { siteId, cpId, token } = getCookieState;

  const isMobile = navigator.userAgent.includes("Mobi") || navigator.userAgent.includes("Touchclass") || document.body.clientWidth < 768;

  const url = `https://${cpId ? cpId : siteId}.touchclass.com/api/internal/lives`;
  const fetcher = async () => {
    const result = await fetch(url, {
      headers: {
        Authorization: `${token}`
      }
    });
    const { isBroadcasting } = await result.json();
    return isBroadcasting;
  };

  const { data } = useSWR(url, fetcher, { refreshInterval: 1000 * 60 * 5 });

  const show = data && !isMobile;

  useEffect(() => {
    if (show) {
      document.querySelector("#root > div")?.classList.add("live-on");
      setLive(true);
    } else {
      document.querySelector("#root > div")?.classList.remove("live-on");
      setLive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, window.location]);

  return (
    <>
      {show && (
        <Wrap id={"live-status-bar"} href={`https://${cpId ? cpId : siteId}.touchclass.com/live/list`} target={"_blank"} rel={"noreferrer"}>
          <span>{t("liveBroadcast")}</span>
          <IconEllipticalArrow />
        </Wrap>
      )}
    </>
  );
};

export default LiveStatusBar;

const Wrap = styled.a`
  position: fixed;
  top: 0;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 650px;
  width: 100%;
  height: 42px;
  font-size: ${p => p.theme.SIZE.n14};
  line-height: 21px;
  color: #fff;
  background: linear-gradient(270deg, #00a1ff 0%, #015f9e 43.23%, #011f40 100%);
  cursor: pointer;
  z-index: 10000;
  transform: translateX(-50%);

  &.deep {
    z-index: 11;
  }

  svg {
    margin-left: 12px;
  }

  :hover {
    text-decoration: none;
  }
`;
