import React, { useState, useEffect, MouseEvent } from "react";
import { NavLink, Link } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import axios from "axios";
import { IconClose } from "assets/icon/svg";

import { getCookieState } from "apollo/LocalState";
import IconCheck from "assets/icon/svg/IconCheck";

const CategoryComponent: React.FC<ICategoryModal> = ({ writeMode, categoryValue, handleCtg, categoryGroupId, closeCallback, keyword }) => {
  const { t } = useTranslation(["Board", "Write"]);

  const { href, search } = window.location;

  const queryPath = queryString.parse(search);

  const { cpId } = getCookieState;

  const [categories, setCategories] = useState<ICategoryGroup[] | null>(null);
  const [topTitle, setTopTitle] = useState<string | null>(null);

  const handleToggle = (e: MouseEvent) => {
    e.currentTarget.classList.toggle("low-group");
  };

  useEffect(() => {
    document.body.classList.add("scroll-lock");

    const { siteId, token } = getCookieState;

    axios
      .get(`https://api.${siteId}.touchtogether.com/${cpId ? `${cpId}/` : ""}categorygroups/all/categories?grouping=true`, {
        headers: {
          Authorization: token
        }
      })
      .then(res => {
        if (res.data) {
          const categoryGroups = writeMode
            ? res.data.categoryGroups.reduce((acc: ICategoryGroup[], cur: ICategoryGroup) => {
                if (cur.categories?.some(item => item.canWritePosts)) {
                  acc.push(cur);
                }
                return acc;
              }, [])
            : res.data.categoryGroups;

          setCategories(categoryGroups);
          if (res.data.title) {
            setTopTitle(res.data.title);
            const modalTitle = document.getElementById("modal-title");
            if (modalTitle) {
              modalTitle.innerHTML = res.data.title;
            }
          }
        }
      });

    return () => {
      if (!writeMode) {
        document.body.classList.remove("scroll-lock");
        window.scrollTo(0, 0);
      }
    };
  }, [writeMode]);

  return (
    <Category writeMode={writeMode}>
      <Header>
        {!writeMode ? (topTitle ? topTitle : t("Board:touchTogether")) : ""}
        {writeMode && t("Write:selectCategory")}
      </Header>
      <CategoryWrap>
        {!writeMode && (
          <AllList
            to={`/${cpId ? `${cpId}/` : ""}board${keyword ? `/search?keyword=${keyword}` : ""}${queryPath.sortBy || queryPath.viewBy ? "?" : ""}${
              queryPath.viewBy ? `&viewBy=${queryPath.viewBy}` : ""
            }`}
            exact
            onClick={() => closeCallback(false)}
          >
            {t("all")}
          </AllList>
        )}
        <ItemScrollWrap id={"category-group"} writeMode={writeMode}>
          {categories &&
            categories.map((item, index) => (
              <ItemGroup key={index} className={item.id ? "" : "no-category"}>
                {item.categories && item.title && (
                  <span className={categoryGroupId === item.id ? "active" : ""} onClick={handleToggle}>
                    {item.title}
                  </span>
                )}
                {item.categories &&
                  item.categories.map((item: ICategoryBasic, index: number) => {
                    const isSelected = Boolean(categoryValue?.id === item.id);
                    return (
                      <React.Fragment key={index}>
                        {/* queryPath.sortBy ? `?sortBy=${queryPath.sortBy}` : queryPath.viewBy ? `?viewBy=${queryPath.viewBy}` : "" */}
                        {!writeMode && (
                          <div>
                            <ItemLink
                              to={`/${cpId ? `${cpId}/` : ""}board/${item.id}${keyword ? `/search?keyword=${keyword}` : ""}${queryPath.type ? `&type=${queryPath.type}` : ""}`}
                              className={href.indexOf(item.id) > -1 ? "active" : ""}
                              onClick={() => closeCallback(false)}
                            >
                              {item.title}
                            </ItemLink>
                          </div>
                        )}
                        {writeMode && item.canWritePosts && (
                          <div>
                            <Item className={isSelected ? "active" : ""} onClick={() => handleCtg?.(item.id)}>
                              {isSelected && <IconCheck />}
                              {item.title}
                            </Item>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
              </ItemGroup>
            ))}
        </ItemScrollWrap>
      </CategoryWrap>
      <CloseButton onClick={() => closeCallback(false)}>
        <IconClose size={"l"} />
      </CloseButton>
    </Category>
  );
};

CategoryComponent.defaultProps = {};

export default CategoryComponent;

const Category = styled.div<ICategoryForWriteStyle>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  padding: 20px;
  width: calc(100vw - 40px);
  max-width: calc(650px - 40px);
  height: ${p => (p.writeMode ? "calc(100vh - 240px)" : "calc(100vh - 40px)")};
  border-radius: 10px;
  background: ${p => p.theme.COLORS.BG_BODY};

  & a.active,
  & span.active {
    color: ${p => p.theme.COLORS.TEXT_BRAND} !important;
  }
`;

const Header = styled.div`
  ${p => p.theme.TEXTS.TOP_TITLE}
  width: 100%;
  font-size: ${p => p.theme.SIZE.n18};
  text-align: left;
`;

const CategoryWrap = styled.div`
  margin-top: 30px;
  width: 100%;
  text-align: left;

  & a {
    width: 100%;
  }
`;

const AllList = styled(NavLink)`
  ${p => p.theme.TEXTS.LIST_TITLE}
  height: 42px;
  line-height: 40px;
  font-size: ${p => p.theme.SIZE.n16};
`;

const ItemScrollWrap = styled.div<ICategoryForWriteStyle>`
  height: ${p => (p.writeMode ? "calc(100vh - 369px)" : "calc(100vh - 169px)")};
  overflow: scroll;
`;

const ItemGroup = styled.div`
  & > a:first-of-type,
  & > span:first-of-type {
    ${p => p.theme.TEXTS.LIST_TITLE}
    display: flex;
    align-items: center;
    min-height: 40px;
    font-size: ${p => p.theme.SIZE.n16};
    border-bottom: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  }

  & > span {
    position: relative;
    cursor: pointer;

    &.low-group:before {
      content: "";
      position: absolute;
      top: 16px;
      right: 10px;
      width: 1px;
      height: 9px;
      background: ${p => p.theme.COLORS.BG_LIGHT01};
    }

    &:after {
      content: "";
      position: absolute;
      top: 20px;
      right: 6px;
      width: 9px;
      height: 1px;
      background: ${p => p.theme.COLORS.BG_LIGHT01};
    }
  }

  & div {
    display: block;
  }

  .item_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .low-group ~ div {
    display: none;
  }

  & div > a {
    padding: 8px 20px;
    color: ${p => p.theme.COLORS.TEXT_SECONDARY};
  }

  &.no-category div a {
    padding: 8px 0;
    color: ${p => p.theme.COLORS.TEXT_REGULAR};
    border-bottom: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  }
`;

const ItemLink = styled(Link)`
  ${p => p.theme.TEXTS.LIST_TITLE}
  display: flex;
  align-items: center;
  padding: 8px 0;
  min-height: 40px;
  line-height: 24px;
  font-size: ${p => p.theme.SIZE.n16};
  text-align: left;
`;

const Item = styled.a`
  ${p => p.theme.TEXTS.LIST_TITLE}
  display: flex;
  align-items: center;
  padding: 8px 0;
  gap: 4px;
  min-height: 40px;
  line-height: 24px;
  font-size: ${p => p.theme.SIZE.n16};
  text-align: left;

  & > svg {
    fill: none;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  margin: 0;
  padding: 0;
`;
