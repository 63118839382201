import React from "react";
import styled from "styles/theme";
import EmptyUser from "assets/icon/empty_user.png";

const PhotoComponent: React.FC<IPhoto> = ({ size, src, alt, ...props }) => {
  let applySize;
  let imgSize;

  switch (size) {
    case "md":
      applySize = 50;
      imgSize = 40;
      break;
    case "sm":
      applySize = 40;
      imgSize = 34;
      break;
    case "lg":
      applySize = 100;
      imgSize = 100;
      break;
    case "lm":
    default:
      applySize = 60;
      imgSize = 50;
      break;
  }

  return (
    <Photo className={"opacity"} applySize={applySize} imgSize={imgSize} {...props}>
      <img src={src ? src : EmptyUser} alt={alt} />
    </Photo>
  );
};

PhotoComponent.defaultProps = {
  size: "lm"
};

export default PhotoComponent;

const Photo = styled.span<IPhotoStyle>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${p => `${p.applySize}px`};
  height: ${p => `${p.applySize}px`};
  z-index: 1;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: ${p => `-${p.imgSize && p.imgSize / 2}px`};
    margin-left: ${p => `-${p.imgSize && p.imgSize / 2}px`};
    width: ${p => `${p.imgSize}px`};
    height: ${p => `${p.imgSize}px`};
    border-radius: ${p => `${p.imgSize}px`};
    background: ${p => p.theme.COLORS.BORDER_REGULAR};
  }

  & img {
    position: relative;
    width: ${p => `${p.imgSize}px`};
    height: ${p => `${p.imgSize}px`};
    border-radius: ${p => `${p.imgSize}px`};
    object-fit: cover;
  }
`;
