import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconFile = ({ width, height, type, brand, ...props }: IconFile) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      {type === "audio" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 9.5H7L11 5.5H13V20.5H11L7 16.5H3V9.5ZM22 13C22 16.9741 18.909 20.2263 15 20.4836V19.4811C18.3562 19.2257 21 16.4216 21 13C21 9.57839 18.3562 6.77427 15 6.51895V5.5164C18.909 5.7737 22 9.02588 22 13ZM19 13C19 15.3163 17.25 17.2238 15 17.4725V16.4646C16.6961 16.2219 18 14.7632 18 13C18 11.2368 16.6961 9.77807 15 9.53545V8.52747C17.25 8.77619 19 10.6837 19 13ZM15 14.4146C15.5826 14.2087 16 13.6531 16 13C16 12.3469 15.5826 11.7913 15 11.5854V14.4146ZM7.41421 15.5H4V10.5H7.41421L11.4142 6.50001H12V19.5H11.4142L7.41421 15.5Z"
          fill={brand ? brand : ThemeUse.COLORS.BG_BRAND}
        />
      )}
      {type === "doc" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 3.50001L8 3.5C6.34314 3.5 5 4.84315 5 6.5V19.5C5 21.1569 6.34314 22.5 8 22.5H17C18.6569 22.5 20 21.1569 20 19.5V10.5L13 3.50001ZM15 11.5C13.3431 11.5 12 10.1569 12 8.5V4.50001H8C6.89543 4.50001 6 5.39544 6 6.50001V19.5C6 20.6046 6.89543 21.5 8 21.5H17C18.1046 21.5 19 20.6046 19 19.5V11.5H15ZM13 8.5C13 9.60457 13.8954 10.5 15 10.5H18.5858L13 4.91421V8.5Z"
          fill={brand ? brand : ThemeUse.COLORS.BG_BRAND}
        />
      )}
      {type === "video" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 5C5.61929 5 4.5 6.11929 4.5 7.5V19.5C4.5 20.8807 5.61929 22 7 22H18C19.3807 22 20.5 20.8807 20.5 19.5V7.5C20.5 6.11929 19.3807 5 18 5H7ZM5.5 7.5C5.5 6.67157 6.17157 6 7 6H18C18.8284 6 19.5 6.67157 19.5 7.5V19.5C19.5 20.3284 18.8284 21 18 21H7C6.17157 21 5.5 20.3284 5.5 19.5V7.5ZM16.2774 13.916C16.4164 13.8233 16.5 13.6672 16.5 13.5C16.5 13.3328 16.4164 13.1767 16.2774 13.084L10.2773 9.08397C10.1239 8.98169 9.92665 8.97215 9.76407 9.05916C9.60149 9.14617 9.5 9.3156 9.5 9.5V17.5C9.5 17.6844 9.60149 17.8538 9.76407 17.9408C9.92665 18.0278 10.1239 18.0183 10.2773 17.916L16.2774 13.916ZM10.5 16.5657V10.4343L15.0986 13.5L10.5 16.5657Z"
          fill={brand ? brand : ThemeUse.COLORS.BG_BRAND}
        />
      )}
      {type === "down" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9413 15.7083V5.5H12.059V15.7083L7.42662 11.3333L6.76484 11.8868L12.5001 17.3035L18.2354 11.8869L17.5738 11.3333L12.9413 15.7083ZM5.88235 18H5V20.5H20V18H19.1176V19.6667H5.88235V18Z"
          fill={brand ? brand : ThemeUse.COLORS.BG_BRAND}
        />
      )}
      {type === "delete" && (
        <>
          <circle cx="13.4573" cy="12.5" r="10" fill={brand ? brand : ThemeUse.COLORS.BG_BRAND} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3677 15.9532C17.4872 16.0789 17.4872 16.2803 17.3677 16.406C17.3104 16.4661 17.2322 16.5 17.1506 16.5C17.069 16.5 16.9908 16.4661 16.9335 16.406L13.4571 13.1351L9.98178 16.406C9.92453 16.4661 9.84633 16.5 9.76472 16.5C9.6831 16.5 9.6049 16.4661 9.54766 16.406C9.42715 16.2808 9.42715 16.0784 9.54766 15.9532L13.0238 12.6823L9.54766 9.0467C9.42715 8.92145 9.42715 8.71912 9.54766 8.59387C9.60494 8.53381 9.68312 8.5 9.76472 8.5C9.84631 8.5 9.9245 8.53381 9.98178 8.59387L13.4571 12.2294L16.9335 8.59387C16.9908 8.53381 17.069 8.5 17.1506 8.5C17.2322 8.5 17.3104 8.53381 17.3677 8.59387C17.4872 8.71955 17.4872 8.92102 17.3677 9.0467L13.8912 12.6823L17.3677 15.9532Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.3677 15.9532C17.4872 16.0789 17.4872 16.2803 17.3677 16.406C17.3104 16.4661 17.2322 16.5 17.1506 16.5C17.069 16.5 16.9908 16.4661 16.9335 16.406L13.4571 13.1351L9.98178 16.406C9.92453 16.4661 9.84633 16.5 9.76472 16.5C9.6831 16.5 9.6049 16.4661 9.54766 16.406C9.42715 16.2808 9.42715 16.0784 9.54766 15.9532L13.0238 12.6823L9.54766 9.0467C9.42715 8.92145 9.42715 8.71912 9.54766 8.59387C9.60494 8.53381 9.68312 8.5 9.76472 8.5C9.84631 8.5 9.9245 8.53381 9.98178 8.59387L13.4571 12.2294L16.9335 8.59387C16.9908 8.53381 17.069 8.5 17.1506 8.5C17.2322 8.5 17.3104 8.53381 17.3677 8.59387C17.4872 8.71955 17.4872 8.92102 17.3677 9.0467L13.8912 12.6823L17.3677 15.9532Z"
            fill="url(#paint0_linear)"
          />
          <defs>
            <linearGradient id="paint0_linear" x1="13.4573" y1="8.5" x2="13.4573" y2="16.5" gradientUnits="userSpaceOnUse">
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </>
      )}
    </svg>
  );
};

IconFile.defaultProps = {
  width: 25,
  height: 25,
  type: "audio"
};

export default IconFile;
