import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import { getDefaultLocale } from "../locales";
import { getCookieState } from "../apollo/LocalState";

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

const dayjsLocaleReq = require.context("dayjs/locale/", true, /^(?!.\/index).*.js$/);
const dayjsLocales: string[] = [];

dayjsLocaleReq.keys().forEach((key: string) => {
  const regex = /.\/(.*?).js$/;
  const moduleName = (regex.test(key) && key.match(regex)![1]) as string;
  dayjsLocales.push(moduleName);
});

const applyLocale = dayjsLocales.filter(item => getDefaultLocale().includes(item)).shift() || "en";

if (applyLocale) {
  require(`dayjs/locale/${applyLocale}`);
}

const { timeDisplay } = getCookieState;
const dateDisplay = (isoString: string) => {
  const nowDate = new Date();
  const compareDate = new Date(isoString);
  const dayGap = nowDate.getTime() - compareDate.getTime();

  const calDayGap = Math.abs(dayGap / (24 * 60 * 60 * 1000));

  const js = dayjs(isoString).locale(applyLocale);
  if (timeDisplay === "Absolute") {
    return js.format("YYYY-MM-DD HH:mm:ss");
  } else {
    return calDayGap > 28 ? js.format("ll") : js.fromNow();
  }
};

export default dateDisplay;
