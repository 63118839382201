import React, { useState, useEffect, ChangeEvent } from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import axios from "axios";

import "styles/Quill/light.css";

import { appNative } from "utils";

import { IconWriteEl, IconEmptyAlert, IconClose } from "assets/icon/svg";

import { Modal, Uploading } from "styles";

import { getCookieState } from "apollo/LocalState";

let fileTemp: FileList | null;

export function useToolbar() {
  return {};
}

const ToolbarComponent: React.FC<IToolbar> = ({
  categoryId,
  image,
  video,
  emoji,
  file,
  handleLock,
  children,
  text,
  multiple,
  align,
  fileList,
  fileUrlList,
  useQuillToolbar,
  setFileList,
  setFileUrlList,
  insertCallback,
  quill,
  ...props
}) => {
  const { t } = useTranslation("Write");
  const { userAgent } = window.navigator;

  const [textEffect, setTextEffect] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [uploadStatus, setUploadStatus] = useState<IUploadStatus | null>();
  // const [uploadStop, setUploadStop] = useState<boolean>(false);
  const [uploadDataOverModal, setUploadDataOverModal] = useState<boolean>(false);

  const handleFile = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    const cellularCheck: boolean = appNative.cellularCheck();

    let sizeOver = false;

    if (files && cellularCheck) {
      for (let i = 0; i < files.length; i++) {
        const fileInfo = files.item(i);
        if (fileInfo && fileInfo.size > 100000000) {
          sizeOver = true;
          fileTemp = files;
          setUploadDataOverModal(true);
          break;
        }
      }
    }
    !sizeOver && files && handleFileUpload(files);
  };

  const handleFileApp = () => {
    appNative.handleVideo();

    const appFileCheck = setInterval(() => {
      const videoUrlApp = window.videoUrlApp;
      if (videoUrlApp.length > 0) {
        clearInterval(appFileCheck);

        const videoData = JSON.parse(videoUrlApp);

        insertCallback && insertCallback(videoData.url, "video");
        setFileList([...fileList, videoData]);
        setFileUrlList([...fileUrlList, videoData.url]);

        window.videoUrlApp = "";
      }
    }, 1000);
  };

  const handleSizeOverModalClose = () => {
    setUploadDataOverModal(false);
    handleFileInputReset();
  };

  const handleFileInputReset = () => {
    const fileGroup = document.getElementsByName("file-group");

    if (fileGroup) {
      fileGroup.forEach(item => {
        (item as HTMLInputElement).value = "";
      });
    }
  };

  const handleSizeOverUpload = () => {
    setUploadDataOverModal(false);
    fileTemp && handleFileUpload(fileTemp);
  };

  const handleFileUpload = async (files: FileList) => {
    const { siteId, cpId, token } = getCookieState;

    const Config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    const tempFile: IFile[] = [];
    const tempUrlFile: string[] = [];

    if (files) {
      for (let i = 0; i < files.length; i++) {
        const fileInfo = files.item(i);
        // eslint-disable-next-line
        const fileName = fileInfo?.name.replace(/[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi, "");

        await axios
          .post(`https://api.${siteId}.touchtogether.com/${cpId ? `${cpId}/` : ""}preupload/${fileInfo?.type}/${categoryId}/${fileName}`, null, Config)
          .then(async res => {
            const { data } = res;
            tempFile.push({
              type: fileInfo?.type,
              url: data.downloadUrl,
              name: fileInfo?.name,
              size: fileInfo?.size
            });
            tempUrlFile.push(data.downloadUrl);
            const tempImgInsert = data.downloadUrl;
            const formData = new FormData();
            formData.append("file", files[i]);
            setUploadModal(true);

            if (fileInfo) {
              await axios
                .put(data.uploadUrl, files[i], {
                  headers: {
                    "content-type": files[i].type
                  },
                  onUploadProgress: e => {
                    if (e.loaded === e.total && files.length === i + 1) {
                      setUploadModal(false);
                      setUploadStatus(null);
                    } else {
                      const percent = (Number(e.loaded) / Number(e.total)) * 100;
                      setUploadStatus({
                        length: files.length,
                        current: i + 1,
                        percent: Number(percent.toFixed(0))
                      });
                    }
                  }
                })
                .then(res => {
                  if (res.status === 200 && insertCallback && fileInfo) {
                    const { type } = fileInfo;
                    const typeOrigin = type.split("/")[0];

                    if (typeOrigin === "image" || typeOrigin === "video") {
                      insertCallback(tempImgInsert, typeOrigin);
                    }
                  }
                })
                .catch(err => {
                  // eslint-disable-next-line
                  console.log(err, "put error");
                });
            }
          })
          .catch(err => {
            // eslint-disable-next-line
            console.log(err, "err");
          });
      }
    }
    setFileList && setFileList([...fileList, ...tempFile]);
    setFileUrlList && setFileUrlList([...fileUrlList, ...tempUrlFile]);
    fileTemp = null;

    handleFileInputReset();
  };

  useEffect(() => {}, []);

  if (!useQuillToolbar) {
    return (
      <ToolbarWrap {...props}>
        <ToolBasic className={"tools-basic"} align={align}>
          {emoji && (
            <button type="button">
              <IconWriteEl type="sticker" />
            </button>
          )}
          {image && (
            <label>
              <IconWriteEl type="img" />
              <input type="file" name={"file-group"} accept="image/png, image/gif, image/jpeg, image/bmp, image/x-icon" multiple={multiple} onChange={handleFile} />
            </label>
          )}
          {video && (
            <label>
              <IconWriteEl type="video" />
              {!userAgent.includes("1.0.1") && <input type="file" name={"file-group"} accept="video/*" multiple={multiple} onChange={handleFile} />}
              {userAgent.includes("1.0.1") && <input type="button" onClick={handleFileApp} />}
            </label>
          )}
          {file && (
            <label>
              <IconWriteEl type="file" />
              <input type="file" name={"file-group"} accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.hwp" multiple={multiple} onChange={handleFile} />
            </label>
          )}
          {/* {link && (
            <label className="ql-custom-link">
              <button className="ql-namelink"></button>
              <IconWriteEl type="link" />
            </label>
          )} */}

          {children && children}
          {text && (
            <button type="button" className={"text-effect"} onClick={() => setTextEffect(true)}>
              <IconWriteEl type="text" />
            </button>
          )}
        </ToolBasic>
        {text && (
          <TextEffect className={`tools-text ${textEffect ? "active" : ""}`}>
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
            <button className="ql-strike"></button>
            <button className={"text-close"} onClick={() => setTextEffect(false)}>
              <IconClose size={"m"} />
            </button>
          </TextEffect>
        )}
        <Modal show={uploadDataOverModal} view={"alert"} title={t("dataCellular")} text={t("dataCellularText")} confirm={handleSizeOverUpload} cancel={handleSizeOverModalClose}>
          <IconEmptyAlert />
        </Modal>
        <Modal show={uploadModal} view={"content"}>
          <Uploading
            percent={uploadStatus?.percent}
            title={t("uploading")}
            count={multiple ? uploadStatus?.current : undefined}
            totalCount={multiple ? uploadStatus?.length : undefined}
          />
        </Modal>
      </ToolbarWrap>
    );
  } else {
    return (
      <QlToolbarWrap {...props} id="toolbar-container">
        <button className="ql-custom-image">
          {image && (
            <label>
              <IconWriteEl type="img" />
              <input hidden type="file" name={"file-group"} accept="image/png, image/gif, image/jpeg, image/bmp, image/x-icon" multiple={multiple} onChange={handleFile} />
            </label>
          )}
        </button>
        <button className="ql-custom-video">
          {video && (
            <label>
              <IconWriteEl type="video" size="m" />
              {!userAgent.includes("1.0.1") && <input hidden type="file" name={"file-group"} accept="video/*" multiple={multiple} onChange={handleFile} />}
              {userAgent.includes("1.0.1") && <input hidden type="button" onClick={handleFileApp} />}
            </label>
          )}
        </button>
        <button className="ql-custom-file">
          {file && (
            <label>
              <IconWriteEl type="file" />
              <input hidden type="file" name={"file-group"} accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.hwp" multiple={multiple} onChange={handleFile} />
            </label>
          )}
        </button>
        <button className="ql-bold ql-icon"></button>
        <select className="ql-color ql-icon">
          <option value="#000000" />
          <option value="#FF3445" />
          <option value="#FF540B" />
          <option value="#FF9900" />
          <option value="#FF3D96" />
          <option value="#00C73C" />
          <option value="#00C4A1" />
          <option value="#18B2FA" />
          <option value="#4F77FD" />
          <option value="#7E5BFF" />
          <option value="#909090" />
          <option value="#56616A" />
        </select>
        <button className="ql-link ql-icon"></button>

        {children && (
          <button className="ql-custom-lock" onClick={handleLock}>
            {children}
          </button>
        )}
        <Modal show={uploadDataOverModal} view={"alert"} title={t("dataCellular")} text={t("dataCellularText")} confirm={handleSizeOverUpload} cancel={handleSizeOverModalClose}>
          <IconEmptyAlert />
        </Modal>
        <Modal show={uploadModal} view={"content"}>
          <Uploading
            percent={uploadStatus?.percent}
            title={t("uploading")}
            count={multiple ? uploadStatus?.current : undefined}
            totalCount={multiple ? uploadStatus?.length : undefined}
          />
        </Modal>
      </QlToolbarWrap>
    );
  }
};

ToolbarComponent.defaultProps = {
  align: "LEFT",
  multiple: false
};

export default ToolbarComponent;

const QlToolbarWrap = styled.div`
  height: 52px;

  button {
    width: auto !important;
    height: auto !important;
  }

  button.ql-icon {
    width: 32px !important;
    padding: 5px 4px !important;
  }

  button.ql-active {
    background-color: #efefef !important;
  }

  button.ql-active .ql-stroke {
    stroke: #444 !important;
  }

  .ql-color-picker {
    width: 32px !important;
    height: auto !important;
    padding: 5px 2px !important;
  }

  .ql-color-picker .ql-picker-label {
    padding: 0 !important;
  }

  .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent !important;
  }

  .ql-picker.ql-expanded .ql-picker-options {
    margin-top: 2px !important;
  }

  .ql-color-picker .ql-picker-label svg {
    height: 24px !important;
  }

  .ql-color-picker .ql-picker-options {
    width: 133px !important;
  }

  .ql-color-label.ql-stroke.ql-transparent {
    opacity: 1 !important;
  }
`;

const ToolbarWrap = styled.div`
  position: relative;
  background: ${p => p.theme.COLORS.BG_BODY};
`;

const ToolBasic = styled.div<IToolbar>`
  display: flex;
  align-items: center;
  justify-content: ${p => (p.align === "RIGHT" ? "flex-end" : p.align?.toLocaleLowerCase())};
  background-color: ${p => p.theme.COLORS.BG_REGULAR};

  &.tools-basic {
    & button,
    & label {
      position: relative;
      padding: 0;
      width: 30px;
      height: 30px;

      + button,
      + label {
        margin-left: 6px;
      }

      &.text-effect {
        margin: 0 0 0 auto;
      }
    }

    & label {
      & input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 0;
        height: 0;
      }

      &.ql-custom-link {
        & button {
          display: none;
        }

        & .ql-active + svg path {
          fill: ${p => p.theme.COLORS.BG_BRAND};
        }
      }
    }
  }
`;

const TextEffect = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  width: 100%;

  &.active {
    display: flex;
    align-items: center;
    background-color: ${p => p.theme.COLORS.BG_REGULAR};
  }

  & .text-close {
    margin: 0 0 0 auto;
    padding: 0;
    width: 30px;
    height: 30px;
  }
`;
