import Quill from "quill";

const blotsEmbed = Quill.import("blots/block/embed");

class CustomImageEmbed extends blotsEmbed {
  static create(value: string | boolean) {
    const node = super.create();
    if (typeof value === "string") {
      node.setAttribute("src", `${value}`);
      node.setAttribute("loading", "lazy");
      // node.setAttribute("class", "lazyload");
    }
    return node;
  }
}

CustomImageEmbed.blotName = "image";
CustomImageEmbed.tagName = "img";

export default CustomImageEmbed;
