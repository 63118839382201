import Quill from "quill";

const blotsEmbed = Quill.import("blots/block/embed");

class CustomVideoEmbed extends blotsEmbed {
  static create(value: string) {
    const node = super.create();
    if (/touchtogether.com/.test(value)) {
      node.setAttribute("controls", "");
      node.setAttribute("poster", `${value}?size=display`);
      node.setAttribute("controlsList", `nodownload`);
      const source = document.createElement("source");
      // const type = value.split(".")[value.split(".").length - 1];
      source.setAttribute("src", value);
      // source.setAttribute("type", `video/${type}`);
      node.appendChild(source);
    }
    return node;
  }
}

CustomVideoEmbed.blotName = "video";
CustomVideoEmbed.tagName = "video";

export default CustomVideoEmbed;
