export const link = (url: string) => {
  const source = document.createElement("a");
  source.setAttribute("href", url);
  source.click();
};

export const nativeLink = (url: string, type: string) => {
  //link((url ?? "") + "&type=" + getType(type));
  link(url);
};

const getType = (type: string) => {
  if (type) {
    const split = type.split("/");
    if (split.length > 0) return split[split.length - 1];
  }
  return type;
};
