import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconLock = ({ width, height, color, size, lock, full }: IconLock) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  switch (size) {
    case "xs":
      width = 10;
      height = 10;
      break;
    case "s":
      width = 20;
      height = 20;
      break;
    case "l":
      width = 40;
      height = 40;
      break;
    case "xl":
      width = 50;
      height = 50;
      break;
    default:
      width = 30;
      height = 30;
      break;
  }

  // const LockPath = lock
  //   ? "M21 10.5882C21 7.50194 18.5376 5 15.5 5C12.4624 5 10 7.50194 10 10.5882V13H11.1V10.5882C11.1 8.1192 13.0699 6.11765 15.5 6.11765C17.9301 6.11765 19.9 8.1192 19.9 10.5882V13H21V10.5882Z"
  //   : "M20.9699 10C20.6806 7.1902 18.342 5 15.5 5C12.4624 5 10 7.50194 10 10.5882V13H11.1V10.5882C11.1 8.1192 13.0699 6.11765 15.5 6.11765C17.7338 6.11765 19.5788 7.80897 19.8623 10H20.9699Z";

  return (
    <svg width={width} height={height} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      {lock ? (
        <>
          <rect x="7.5" y="13.5" width="16" height="11" rx="1" stroke={color ? color : ThemeUse.ICONS.LOCK.ONE} fill={full ? (color ? color : ThemeUse.ICONS.LOCK.ONE) : "none"} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21 10.5882C21 7.50194 18.5376 5 15.5 5C12.4624 5 10 7.50194 10 10.5882V13H11.1V10.5882C11.1 8.1192 13.0699 6.11765 15.5 6.11765C17.9301 6.11765 19.9 8.1192 19.9 10.5882V13H21V10.5882Z"
            fill={color ? color : ThemeUse.ICONS.LOCK.ONE}
          />
          <rect x="15" y="18" width="1" height="2" rx="0.5" fill={full ? "white" : color ? color : ThemeUse.ICONS.LOCK.ONE} />
        </>
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 10C11 9.43054 11.221 8.41775 11.8938 7.55815C12.5478 6.72237 13.6519 6 15.5 6C16.6721 6 17.8045 6.30592 18.6316 6.94553C19.4407 7.57123 20 8.54709 20 10H21C21 8.25291 20.3093 6.97877 19.2434 6.15447C18.1955 5.34408 16.8279 5 15.5 5C13.3481 5 11.9522 5.86096 11.1062 6.94185C10.279 7.99892 10 9.23613 10 10V13H8C7.44772 13 7 13.4477 7 14V24C7 24.5523 7.44772 25 8 25H23C23.5523 25 24 24.5523 24 24V14C24 13.4477 23.5523 13 23 13H11V10ZM23 24V14H8L8 24H23ZM15 18.5C15 18.2239 15.2239 18 15.5 18C15.7761 18 16 18.2239 16 18.5V19.5C16 19.7761 15.7761 20 15.5 20C15.2239 20 15 19.7761 15 19.5V18.5Z"
          fill={color ? color : ThemeUse.ICONS.LOCK.ONE}
        />
      )}
    </svg>
  );
};

IconLock.defaultProps = {
  width: 30,
  height: 30
};

export default IconLock;
