import React from "react";
import styled from "styles/theme";

import { IconLoading } from "assets/icon/svg";

const FileUploadingComponent: React.FC<IFileUploading> = ({ percent, title, count, totalCount }) => {
  return (
    <FileUploading>
      <CircleAnimation>
        <IconLoading />
      </CircleAnimation>
      <Percent>{percent ? percent : 0} %</Percent>
      <Title>{title}</Title>
      {totalCount && (
        <Count>
          <strong>{count}</strong> / {totalCount}
        </Count>
      )}
    </FileUploading>
  );
};

FileUploadingComponent.defaultProps = {};

export default FileUploadingComponent;

const FileUploading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 44px 30px 24px;
  min-width: 315px;
  border-radius: 10px;
  background-color: ${p => p.theme.COLORS.MODAL.ONE};
`;

const CircleAnimation = styled.div`
  margin: 0 auto;
  width: 40px;
  height: 40px;
  animation: Rotation 1.5s ease infinite;

  @keyframes Rotation {
    0% {
      transform: rotate(0deg);
    }

    5% {
      transform: rotate(18deg);
    }

    10% {
      transform: rotate(36deg);
    }

    15% {
      transform: rotate(54deg);
    }

    20% {
      transform: rotate(72deg);
    }

    25% {
      transform: rotate(90deg);
    }

    30% {
      transform: rotate(108deg);
    }

    35% {
      transform: rotate(126deg);
    }

    40% {
      transform: rotate(144deg);
    }

    45% {
      transform: rotate(162deg);
    }

    50% {
      transform: rotate(180deg);
    }

    55% {
      transform: rotate(198deg);
    }

    60% {
      transform: rotate(216deg);
    }

    65% {
      transform: rotate(234deg);
    }

    70% {
      transform: rotate(252deg);
    }

    75% {
      transform: rotate(270deg);
    }

    80% {
      transform: rotate(288deg);
    }

    85% {
      transform: rotate(306deg);
    }

    90% {
      transform: rotate(324deg);
    }

    95% {
      transform: rotate(342deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const Percent = styled.strong`
  margin-top: 10px;
  color: ${p => p.theme.COLORS.TEXT_BRAND};
  font-size: 30px;
  line-height: 35px;
`;

const Title = styled.h2`
  ${p => p.theme.TEXTS.SYSTEM_TITLE}
  margin-top: 10px;
`;

const Count = styled.div`
  margin-top: 10px;
  color: ${p => p.theme.COLORS.TEXT_SECONDARY};
  font-size: ${p => p.theme.SIZE.n14};

  strong {
    color: ${p => p.theme.COLORS.TEXT_BRAND};
  }
`;
