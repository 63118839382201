import React, { useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { ROOT } from "api/Client";
import { ThemeProvider } from "emotion-theming";
import "locales";
import { theme as themeMap, themeTypes } from "styles/theme";
import Guide from "views/Guide";
import Login from "views/Login";
import Board from "views/Board";
import Detail from "views/Board/Detail";
import Search from "views/Search";
import Profile from "views/Profile";
import { getCookieState } from "apollo/LocalState";
import Report from "../views/Report";

const { cpId } = getCookieState;

const PrivateRoute: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/guide" component={Guide} />
      <Route exact path={["/board/profile", "/board/profile/:userId", "/:cp/board/profile", "/:cp/board/profile/:userId"]} component={Profile} />
      <Route exact path={["/board/search", "/board/:categoryId/search", "/:cp/board/search", "/:cp/board/:categoryId/search"]} component={Search} />
      <Route exact path={["/board/view/:postId", "/board/:categoryId/view/:postId", "/:cp/board/view/:postId", "/:cp/board/:categoryId/view/:postId"]} component={Detail} />
      <Route
        exact
        path={[
          "/board/report/post/:postId",
          "/:cp/board/report/post/:postId",
          "/board/report/comment/:commentId",
          "/:cp/board/report/comment/:commentId",
          "/board/report/user/:userId",
          "/:cp/board/report/user/:userId"
        ]}
        component={Report}
      />
      <Route exact path={["/board", "/board/:categoryId", "/:cp/board", "/:cp/board/:categoryId"]} component={Board} />
      <Redirect to={`${cpId ? `/${cpId}` : ""}/board`} />
    </Switch>
  );
};
const PublicRoute: React.FC = () => {
  return (
    <Switch>
      <Route exact path={["/login", "/:cp/login"]} component={Login} />
      <Redirect to={`${cpId ? `/${cpId}` : ""}/login`} />
    </Switch>
  );
};

const Root = () => {
  useEffect(() => {
    document.documentElement.lang = navigator.language;
  }, []);

  const { data: clientData, loading: clientLoading, error } = useQuery(ROOT);

  const { theme, isLoggedIn } = clientData;

  document.body.id = theme;

  if (error) return <div>{error.message}</div>;
  if (clientLoading) return <div>Root Loading</div>;

  return (
    <React.Fragment>
      <Router>
        <ThemeProvider theme={themeMap[theme as themeTypes]}>{isLoggedIn ? <PrivateRoute /> : <PublicRoute />}</ThemeProvider>
      </Router>
    </React.Fragment>
  );
};

export default Root;
