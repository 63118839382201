import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";
const IconMenu = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="9" y="12.5" width="22" height="2" fill={color ? color : ThemeUse.COLORS.BG_BASE} />
      <rect x="9" y="19.5" width="22" height="2" fill={color ? color : ThemeUse.COLORS.BG_BASE} />
      <rect x="9" y="26.5" width="22" height="2" fill={color ? color : ThemeUse.COLORS.BG_BASE} />
    </svg>
  );
};

IconMenu.defaultProps = {
  width: 40,
  height: 40
};

export default IconMenu;
