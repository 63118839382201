export default {
  comments: "comments",
  commentCount: `<strong id="comment-count"></strong>&nbsp;comments`,
  commentPost: "Post",
  leaveComment: "Leave a comment.",
  permissionComment: "You do not have permission to leave a comment.",
  sizeOptionWord: "A",
  edit: "Edit",
  delete: "Delete",
  deleteText: "Are you sure you want to <br />delete this post?",
  yes: "Yes",
  cancel: "Cancel",
  image: "Image",
  video: "Video",
  postedComment: "Posted your comment.",
  report: "Report",
  submit: "Submit",
  Submitted: "Submitted",
  reportAdminText: "An admin will review your report and handle it according to company regulations.",
  reportReason: "Explain your reason for reporting in fewer than 150 characters.",
  reportPostText: "This post has been reported.",
  ok: "OK",
  editComment: "Edit comment",
  save: "Save",
  firstCommentText: "Be the first to leave a comment.",
  newest: "Newest",
  mostLiked: "Most liked",
  scrape: "Archived",
  scrapeOff: "Unarchived",
  errorPost: "The post does not exist or has been deleted. <br />Go to View all.",
  readPermission: "You don't have permission to view this post. <br />Go to list of all posts.",
  notProvideFileViewer: "This topic is Do not allow downloads.",
  filePreview: "File Preview",
  NotSupportedFilePreview: "Not supported for preview"
};
