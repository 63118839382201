import { gql } from "apollo-boost";

export const ME = gql`
  query me {
    me {
      id
      email
      name
      picture
      representativeGroupName
    }
  }
`;

export const BLOCK_USER = gql`
  mutation blockUser($userId: ID!) {
    blockUser(userId: $userId)
  }
`;

export const CANCEL_BLOCK_USER = gql`
  mutation unblockUser($userId: ID!) {
    unblockUser(userId: $userId)
  }
`;

export const REPORT_USER = gql`
  mutation reportUser($id: ID!, $input: ReportUserInput!) {
    reportUser(userId: $id, input: $input)
  }
`;
