import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconEye = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7.51974C16.5635 4.54904 13.521 2.5 10 2.5C6.47896 2.5 3.4365 4.54904 2 7.51974C3.4365 10.4904 6.47896 12.5395 10 12.5395C13.521 12.5395 16.5635 10.4904 18 7.51974ZM7 7.5C7 5.84314 8.34314 4.5 10 4.5C11.6569 4.5 13 5.84314 13 7.5C13 9.15686 11.6569 10.5 10 10.5C8.34314 10.5 7 9.15686 7 7.5Z"
        fill={color ? color : ThemeUse.ICONS.PROFILE.ONE}
      />
    </svg>
  );
};

IconEye.defaultProps = {
  width: 20,
  height: 15
};

export default IconEye;
