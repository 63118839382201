import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconEllipticalArrow = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 55 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 6.5L19.5 10M19.5 10H9M19.5 10L16 13.5" stroke={color ? color : ThemeUse.COLORS.WHITE} />
      <rect x="0.5" y="0.5" width="27" height="18" rx="9" fill="none" stroke={color ? color : ThemeUse.COLORS.WHITE} />
    </svg>
  );
};

IconEllipticalArrow.defaultProps = {
  width: 55,
  height: 19
};

export default IconEllipticalArrow;
