export default {
  comments: "댓글",
  commentCount: `댓글&nbsp;<strong id="comment-count"></strong>`,
  commentPost: "등록",
  leaveComment: "댓글을 남겨주세요.",
  permissionComment: "댓글 작성 권한이 없습니다",
  sizeOptionWord: "가",
  edit: "수정",
  delete: "삭제",
  deleteText: "삭제 하시겠습니까?",
  yes: "확인",
  cancel: "취소",
  image: "이미지",
  video: "동영상",
  postedComment: "댓글이 등록되었습니다.",
  report: "신고하기",
  submit: "완료",
  Submitted: "완료",
  reportAdminText: "관리자가 확인 후 내부 규정에 따라 처리됩니다.",
  reportReason: "신고 사유를 입력하세요. (150자 이내)",
  reportPostText: "신고가 완료되었습니다.",
  ok: "확인",
  editComment: "댓글 수정",
  save: "완료",
  firstCommentText: "첫번째 댓글을 남겨주세요",
  newest: "최신순",
  mostLiked: "좋아요순",
  scrape: "스크랩 완료",
  scrapeOff: "스크랩 해제",
  errorPost: "삭제되었거나 없는 글입니다. <br />전체글 목록으로 이동합니다.",
  readPermission: "읽기 권한이 없습니다. <br />전체글 목록으로 이동합니다.",
  notProvideFileViewer: "이 토픽은 \n 다운로드를 허용하지 않습니다.",
  filePreview: "미리보기",
  NotSupportedFilePreview: "미리보기 미지원"
};
