import regex from "../../../utils/regex";
import { encodeEntity } from "../../../utils/string";

export const replaceTag = (type: string | string[] | null, keyword: string | null, highlight?: Ihighlight) => {
  function replaceEntity(text: string) {
    if (!text) return text;
    return encodeEntity(text).replace(regex.uFEFF, "");
  }

  const removeMentionTag = (text: string) => {
    return text.replace(regex.mention, "$2");
  };

  const removeEmTag = (text: string) => {
    return text.replace(regex.highlight, "$2");
  };

  const removeCustomTag = (text: string) => {
    return removeEmTag(removeMentionTag(text));
  };

  const geKeywords = (target?: string) => {
    if (target) {
      const array = [...target.matchAll(regex.highlight)];

      if (array && array.length > 0) {
        return [...new Set(array.map(value => value[1]))].sort(function(a: string, b: string) {
          return a.length - b.length;
        });
      }
    }
    return [];
  };

  const highlightTag = (value: string) => {
    return `<em>${value}</em>`;
  };

  const addHighlight = (target: string, search: string, replace: string) => {
    return target.replace(new RegExp(search, "g"), replace);
  };

  const addHighlights = (target: string, keys: RegExpMatchArray | null, isTitle?: boolean) => {
    let result = target.replace(regex.br, "<p></p>");
    if (isTitle) {
      result = replaceEntity(result);
    }

    if (keyword) {
      if (type === "TAG") {
        return result;
      } else {
        result = removeCustomTag(result);
        if (keys !== null && keys.length > 0) {
          keys.forEach(key => {
            result = addHighlight(result, key, highlightTag(key));
          });
        }
      }
    }
    return result;
  };

  const getTitle = (title: string) => {
    return addHighlights(title, geKeywords(highlight?.title), true);
  };

  const getContent = (content: string) => {
    return addHighlights(content, geKeywords(highlight?.content));
  };

  return {
    getTitle,
    getContent
  };
};
