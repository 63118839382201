import axios from "axios";
import cookie from "js-cookie";
import { getCookieState } from "apollo/LocalState";

const { siteId, cpId } = getCookieState;

export default {
  async getIdToken() {
    const { token } = getCookieState;
    return token;
  },
  async getTitle() {
    axios.get(`https://api.${siteId}.touchtogether.com/${cpId ? `${cpId}/` : ""}company`)
    .then(res => {
      document.title = res.data.title;
      cookie.set("time_display", res.data.timeDisplay);
    })
    .catch(err => {
      // console.log(err);
    });
  },
  async login(params: ILogin) {
    try {
      const res = await axios.post(`https://api.${siteId}.touchtogether.com/${cpId ? `${cpId}/` : ""}oauth/token`, params, {
        headers: {
          "content-type": "text/plain;charset=UTF-8"
        }
      });

      const { data } = res;

      if (data.access_token) {
        cookie.set("access_token", data.access_token);
      }

      return data.access_token;
    } catch (error) {
      console.log(error);
    }
  }
};
