import cookie from "js-cookie";
import axios from "axios";
import { getCookieState } from "apollo/LocalState";

const { siteId, cpId, token } = getCookieState;

const config = {
  headers: {
    Authorization: token
  }
};

export const getGroupNameStatus = async () => {
  const url = `https://${cpId ? cpId : siteId}.touchclass.com/system/config`;

  const result = await axios
    .get(url, config)
    .then(res => {
      const { data } = res;
      return data.config.profile_view_mode === "WITH_GROUP" ? true : false;
    })
    .catch(err => {
      // console.log(err);
    });

  result ? cookie.set("groupName", "yes") : cookie.remove("groupName");
};

export const getLiveStatus = async () => {
  const url = `https://${cpId ? cpId : siteId}.touchclass.com/api/internal/lives`;

  const result = await axios
    .get(url, config)
    .then(res => {
      const { data } = res;
      return data.isBroadcasting;
    })
    .catch(err => {
      // console.log(err);
    });
  return result;
};
