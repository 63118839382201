export default {
  createPost: "投稿",
  editPost: "編集",
  confirm: "完了",
  enterTitle: "タイトルを入力してください。",
  warningTitle: "50文字以内で入力してください。",
  enterContent: "内容を入力してください。",
  privateMake: "シークレット投稿設定",
  success: "完了",
  successPostText: "投稿を完了しました。",
  successPostText2: "編集を完了しました。",
  ok: "確認",
  uploading: "アップロード中…",
  cancel: "取消",
  done: "完了",
  edit: "修正",
  delete: "削除",
  deleteText: "削除しますか？",
  yes: "確認",
  dataCellular: "お知らせ",
  dataCellularText: "モバイルデータを使用しています。<br/> 実行しますか？",
  lock: "シークレット投稿設定",
  unlock: "シークレット投稿解除",
  selectCategory: `カテゴリを選択してください。`,
  wantToCancelWrite: "投稿を取消しますか？",
  cancelWrite: "取消",
  changePublicPost: "トピックの設定に応じて「公開」に変更されました。",
  changePrivatePost: "トピックの設定に応じて「秘密」に変更されました。"
};
