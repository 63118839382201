import React from "react";
import { css } from "@emotion/core";
import styled from "styles/theme";
import { Button } from "styles";
import { useTranslation } from "react-i18next";

const ModalComponent: React.FC<IModal> = ({ show, children, confirm, confirmText, cancel, cancelText, dim, dimClose, title, text, deep, ...props }) => {
  const { t } = useTranslation("Write");
  return (
    <>
      {show && (
        <Modal {...props} className={"modal"}>
          <Wrap {...props}>
            <Content {...props}>
              {children}
              {props.view === "alert" && (
                <>
                  <Title>{title}</Title>
                  {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
                </>
              )}
            </Content>
            {props.view === "alert" && (
              <BtnArea>
                {cancel && (
                  <Button className="cancel" variant="CANCEL" onClick={cancel}>
                    {cancelText ? cancelText : t("cancel")}
                  </Button>
                )}
                {confirm && (
                  <Button className="confirm" onClick={confirm}>
                    {confirmText ? confirmText : t("ok")}
                  </Button>
                )}
              </BtnArea>
            )}
          </Wrap>
          {dim && <Dim onClick={() => dimClose && dimClose(false)} deep={deep} />}
        </Modal>
      )}
    </>
  );
};

ModalComponent.defaultProps = {
  show: false,
  view: "content",
  horizon: "center",
  vertical: "center",
  dim: true,
  deep: false,
  title: "제목을 입력해주세요"
};

export default ModalComponent;

const Modal = styled.div<IModal>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background: ${p => p.theme.COLORS.BLACK};
    opacity: 0.7;
  }
`;

const Dim = styled.div<IModalStyle>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${p => (p.deep ? "rgba(0, 0, 0, 0.9)" : "rgba(0, 0, 0, 0.7)")};
  z-index: 0;
`;

const ALERT_CSS = css`
  margin: 0 20px;
  min-width: 315px;
  border-radius: 10px;
`;

const Wrap = styled.div<IModal>`
  display: flex;
  flex-direction: column;
  position: relative;
  ${p => p.view === "alert" && ALERT_CSS};
  color: ${p => p.theme.COLORS.BG_BASE};
  background-color: ${p => p.view === "alert" && p.theme.COLORS.MODAL.ONE};
  z-index: 1;
`;

const ALERT_CONTENT_CSS = css`
  padding: 20px 30px 24px;
  text-align: center;
`;

const Content = styled.div<IModal>`
  ${p => p.view === "alert" && ALERT_CONTENT_CSS};
  ${p => p.theme.TEXTS.SYSTEM_TXT}
`;

const Title = styled.h2`
  ${p => p.theme.TEXTS.SYSTEM_TITLE}
  word-break: break-all;
`;

const Text = styled.p`
  margin-top: 15px;
  ${p => p.theme.TEXTS.SYSTEM_TXT}
  word-break: break-all;
`;

const BtnArea = styled.div`
  display: flex;
  border-radius: 0 0 10px 10px;

  & button {
    vertical-align: top;
  }

  & > button:last-of-type {
    border-radius: 0 0 10px 0;
  }

  & > button:first-of-type {
    min-width: 0;
    width: 40%;
    border-radius: 0 0 0 10px;

    & + button {
      width: 60%;
    }

    &:last-of-type {
      width: 100%;
      border-radius: 0 0 10px 10px;
    }
  }
`;
