import React, { useEffect } from "react";
import { css } from "@emotion/core";
import styled from "styles/theme";

const ToastComponent: React.FC<IToastComponent> = ({ toast, setToast }) => {
  const { id, className, delay = 3000, backgroundColor, fontColor, position, message } = toast;

  useEffect(() => {
    const show = setTimeout(() => {
      setToast({ ...toast, className: "show" });
    }, 10);
    const hidden = setTimeout(() => {
      setToast({ ...toast, className: "hidden" });
    }, delay - 500);
    const remove = setTimeout(() => {
      setToast(null);
    }, delay);
    return () => {
      clearTimeout(show);
      clearTimeout(hidden);
      clearTimeout(remove);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Toast className={className ? className : ""} position={position} backgroundColor={backgroundColor} fontColor={fontColor}>
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </Toast>
  );
};

export default ToastComponent;

const PositionTop = css`
  top: 30px;
`;

const PositionBottom = css`
  bottom: 30px;
`;

const Toast = styled.div<IToastStyle>`
  ${p => p.theme.TEXTS.SYSTEM_TOAST}
  ${p => (p.position === "top" ? PositionTop : PositionBottom)}
  visibility: visible;
  position: fixed;
  left: 50%;
  padding: 10px;
  border-radius: 4px;
  ${p => p.fontColor && `color: ${p.fontColor}`};
  background-color: ${p => (p.backgroundColor ? p.backgroundColor : p.theme.COLORS.TEXT_PRIMARY)};
  text-align: center;
  z-index: 10000001;
  opacity: 0;
  transform: translate(-50%);
  transition: opacity 0.5s;

  &.show {
    opacity: 0.8;
  }

  &.hidden {
    opacity: 0;
  }
`;
