import { Modal } from "../../styles";
import React, { useEffect, useState } from "react";
import { IconClose } from "../../assets/icon/svg";
import styled from "../../styles/theme";

interface Props {
  url: string;
  open: boolean;
  onClose: () => void;
}

export const PDFViewerModal = ({ url, onClose, open }: Props) => {
  const [dim, setDim] = useState<boolean>(true);
  const toggleModalHeader = () => {
    setDim(!dim);
  };

  const showPDF = () => {
    const option = {
      pdfOpenParams: {
        view: "FitV",
        pagemode: "thumbs",
        name: "title"
      },
      forcePDFJS: true,
      PDFJS_URL: "/pdfjs/web/viewer.html"
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const PDFObject = window.PDFObject || {};
    PDFObject.embed(url, "#pdf-container", option);
  };

  useEffect(() => {
    showPDF();
  }, [open]);

  return (
    <Modal deep show={open} dimClose={toggleModalHeader}>
      {dim && (
        <ModalHeader>
          <CloseButton onClick={onClose}>
            <IconClose size={"l"} color={"#FFF"} />
          </CloseButton>
        </ModalHeader>
      )}
      <FullScreen id={"pdf-container"}></FullScreen>
    </Modal>
  );
};

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 10px;
  width: 100%;
  height: 46px;
  background: ${p => p.theme.COLORS.BG_OVERLAY4};
  z-index: 10;

  & button {
    padding: 0;
    height: 40px;
  }

  & svg {
    position: relative;
    z-index: 5;
  }
`;

const FullScreen = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  height: calc(100vh - 150px);
`;

const CloseButton = styled.button``;
