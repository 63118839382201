import React from "react";
import { theme as themeMap, themeTypes } from "styles/theme";

const IconComment = ({ width, height, color }: IconBasic) => {
  const ThemeUse = themeMap[document.body.id as themeTypes];

  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-2-inside-1" fill={color ? color : ThemeUse.ICONS.COMMENT.ONE}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 14V18L10 14H15C16.1046 14 17 13.1046 17 12V4C17 2.89543 16.1046 2 15 2H3C1.89543 2 1 2.89543 1 4L1 12C1 13.1046 1.89543 14 3 14H6Z"
        />
      </mask>
      <path
        d="M6 18H5V20.4142L6.70711 18.7071L6 18ZM6 14L7 14V13L6 13V14ZM10 14V13H9.58579L9.29289 13.2929L10 14ZM7 18V14L5 14V18H7ZM9.29289 13.2929L5.29289 17.2929L6.70711 18.7071L10.7071 14.7071L9.29289 13.2929ZM15 13H10V15H15V13ZM16 12C16 12.5523 15.5523 13 15 13V15C16.6569 15 18 13.6569 18 12H16ZM16 4V12H18V4H16ZM15 3C15.5523 3 16 3.44772 16 4H18C18 2.34315 16.6569 1 15 1V3ZM3 3L15 3V1H3V3ZM2 4C2 3.44772 2.44772 3 3 3V1C1.34315 1 0 2.34315 0 4H2ZM2 12L2 4H0V12H2ZM3 13C2.44772 13 2 12.5523 2 12H0C0 13.6569 1.34315 15 3 15V13ZM6 13H3V15H6V13Z"
        fill={color ? color : ThemeUse.ICONS.COMMENT.ONE}
        mask="url(#path-2-inside-1)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 9C6.55228 9 7 8.55228 7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9ZM9 9C9.55228 9 10 8.55228 10 8C10 7.44772 9.55228 7 9 7C8.44772 7 8 7.44772 8 8C8 8.55228 8.44772 9 9 9ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z"
        fill={color ? color : ThemeUse.ICONS.COMMENT.ONE}
      />
    </svg>
  );
};

IconComment.defaultProps = {
  width: 20,
  height: 20
};

export default IconComment;
