import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "styles/theme";
import DOMPurify from "dompurify";

interface TypographyProps {
  maxLine: number;
  isSafari: boolean;
}

interface EllipsisProps {
  text: string;
  maxLine: number;
  moreText?: string;
}

const EllipsisWrapper = styled("div")<TypographyProps>`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: ${props => (props.isSafari ? "block" : "-webkit-box")};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${props => props.maxLine};
  max-height: ${props => (props.isSafari ? props.maxLine * 24.5 + "px" : "none")};
`;

const More = styled.span`
  color: ${p => p.theme.COLORS.BG_SECONDARY};
  font-size: 0.85em;
  padding-top: 10px;
`;
const isSafari = () => {
  const agent = navigator.userAgent.toLocaleLowerCase();
  return agent.indexOf("ios") != -1 || (agent.indexOf("safari") != -1 && agent.indexOf("chrome") == -1);
};

const Ellipsis: React.FC<EllipsisProps> = ({ text, maxLine, moreText }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", onResize);
    return function cleanup() {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onResize = () => {
    if (ref) {
      setHidden(ref.current?.scrollHeight != ref.current?.offsetHeight);
    }
  };

  useLayoutEffect(() => {
    onResize();
  });

  return (
    <div>
      <EllipsisWrapper
        ref={ref}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(text)
        }}
        maxLine={maxLine}
        isSafari={isSafari()}
      />
      {hidden && <More>{moreText}</More>}
    </div>
  );
};

Ellipsis.defaultProps = {};

export default Ellipsis;
